import React, { ReactNode } from "react";
import styles from "./BorderButton.module.scss";
import { ArrowRight } from "@material-ui/icons";
import { LinkUtils } from "../../../services/link/LinkUtils";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";

export const BorderButton = (props: {
    children: ReactNode;
    link: string;
    marginLeft?: boolean;
    more: boolean;
}) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = "";

    if (langCode === "en") {
        textList = "More";
    } else {
        textList = "더보기";
    }

    return (
        <div
            style={{marginLeft: props.marginLeft ? 10 : 0 }}
            className={styles.btnMore}
            onClick={() => LinkUtils.openNewWindow(props.link)}>
            <div>{props.children}</div>
            {props.more === true ? (
                <>
                    <div style={{ paddingLeft: 7 }}>
                        {textList} <ArrowRight />
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};
