import React from "react";
import styles from "./InputIndexCarouselView.module.scss";
import { InputIndexCarousel } from "../../../../../graphql/graphql";
import { OnChangeValue } from "../../../../../graphql/types";
import classNames from "classnames";
import { InputMultiLang } from "../../../../../components/input/multiLang/InputMultiLang";
import { FileManager, FileType } from "../../../../../services/fileManager/FileManager";
import { ApolloCatch } from "../../../../../services/apollo/apolloCatch";
import NumberFormat from "react-number-format";

export const InputIndexCarouselView = (props: {
    value: InputIndexCarousel;
    onChangeValue: OnChangeValue<InputIndexCarousel>;
}) => {
    const handler = {
        onUploadFile: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(res => {
                    props.onChangeValue({ ...props.value, imgSrc: res });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>이미지</div>
                <div className={styles.colPath}>
                    <div className="form-control">
                        {props.value.imgSrc || "파일을 업로드 하여 주십시오."}
                    </div>
                </div>
                <div className={styles.colFunc}>
                    <button onClick={handler.onUploadFile} className="btn btn-outline-primary">
                        업로드
                    </button>
                </div>
            </div>

            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>링크</div>
                <div className={styles.colValue}>
                    <input
                        value={props.value.link}
                        onChange={ev =>
                            props.onChangeValue({ ...props.value, link: ev.target.value })
                        }
                        placeholder=""
                        className="form-control"
                    />
                </div>
            </div>

            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>순서</div>
                <div className={styles.colValue}>
                    <NumberFormat
                        className="form-control"
                        value={props.value.order}
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={v =>
                            props.onChangeValue({ ...props.value, order: v.floatValue || 0 })
                        }
                    />
                </div>
            </div>

            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>헤더1</div>
                <div className={styles.colValue}>
                    <InputMultiLang
                        value={props.value.preHeader}
                        onChangeValue={v => props.onChangeValue({ ...props.value, preHeader: v })}
                    />
                </div>
            </div>

            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>헤더2</div>
                <div className={styles.colValue}>
                    <InputMultiLang
                        value={props.value.header}
                        onChangeValue={v => props.onChangeValue({ ...props.value, header: v })}
                    />
                </div>
            </div>

            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>설명</div>
                <div className={styles.colValue}>
                    <InputMultiLang
                        value={props.value.description}
                        onChangeValue={v => props.onChangeValue({ ...props.value, description: v })}
                    />
                </div>
            </div>
        </>
    );
};
