import withReactContent from "sweetalert2-react-content";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { GraphQLError } from "graphql";
import { BizErrors } from "../../graphql/bizErrors";

export class SwalUtils {
    private static swal = withReactContent(Swal);

    static ok(param: { msg: string; icon: SweetAlertIcon; ok?: () => void }) {
        this.swal
            .fire({
                title: param.msg,
                icon: param.icon,
                confirmButtonText: "확인",
            })
            .then(() => {
                if (param.ok) {
                    param.ok();
                }
            });
    }

    static ynWithPromise<T>(param: {
        msg: string;
        icon?: SweetAlertIcon;
        confirmBtnLabel?: string;
        cancelBtnLabel?: string;
        value?: T;
    }): Promise<T | undefined> {
        return this.swal
            .fire({
                title: param.msg,
                icon: param.icon,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: param.confirmBtnLabel || "확인",
                cancelButtonText: param.cancelBtnLabel || "취소",
            })
            .then(res => {
                if (res.isConfirmed) {
                    return param.value;
                } else {
                    throw new GraphQLError(BizErrors.swalCancel);
                }
            });
    }
}
