import React, { ReactNode, useEffect } from "react";
import styles from "./DashboardLayout.module.scss";
import { AddToQueue, Filter, Menu } from "@material-ui/icons";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalVars } from "../../globalVars/globalVars";
import { SwalUtils } from "../../services/swal/SwalUtils";
import { ApolloCatch } from "../../services/apollo/apolloCatch";
import { gql, useMutation } from "@apollo/client";
import { LocalStorage } from "../../services/localstorage/LocalStorage";
import { Mutation, MutationLoginArgs } from "../../graphql/graphql";

export const DashboardLayout = (props: { children: ReactNode; router: string }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [mutLogout] = useMutation<Mutation, MutationLoginArgs>(gqlLogout);

    useEffect(() => {
        if (pathname.startsWith("/dashboard")) {
            GlobalVars.layout.footer(false);
        } else {
            GlobalVars.layout.footer(true);
        }

        return () => {
            GlobalVars.layout.footer(true);
        };
    }, [pathname]);

    const handler = {
        onClickLogout: () => {
            SwalUtils.ynWithPromise({
                msg: "로그아웃 하시겠습니까?",
            })
                .then(() => {
                    return mutLogout();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "로그아웃 되었습니다..",
                        icon: "success",
                    });
                    navigate("/");
                    GlobalVars.user.isLogin(false);
                    LocalStorage.clearSession();
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            <div className={styles.contSideNav}>
                {sideMenuList.map((value, index) => (
                    <div
                        className={classNames(
                            styles.sideNavBtn,
                            value.router === props.router ? styles.activate : "",
                        )}
                        key={index}
                        onClick={() => navigate(value.router)}>
                        {/*<div>{value.icon}</div>*/}
                        <div>{value.label}</div>
                    </div>
                ))}

                <div className={classNames(styles.sideNavBtn)} onClick={handler.onClickLogout}>
                    <div>로그아웃</div>
                </div>
            </div>
            <div className={styles.contChildren}>
                <div className={styles.contents}>{props.children}</div>
            </div>
        </>
    );
};

const sideMenuList: DashboardSideMenu[] = [
    {
        label: "메인페이지",
        router: "/dashboard/main",
        icon: Menu,
    },
    {
        label: "내비",
        router: "/dashboard/nav",
        icon: Menu,
    },
    {
        label: "등록",
        router: "/dashboard/product/create",
        icon: Menu,
    },
    {
        label: "관리",
        router: "/dashboard/product/manage",
        icon: Menu,
    },
    {
        label: "뉴스",
        router: "/dashboard/news",
        icon: Filter,
    },
    {
        label: "팝업관리",
        router: "/dashboard/popup",
        icon: AddToQueue,
    },
];

interface DashboardSideMenu {
    label: string;
    router: string;
    icon: ReactNode;
}


const gqlLogout = gql`
    mutation logout {
        logout
    }
`;
