import React, { ReactNode } from "react";
import styles from "./QnAPage.module.scss";
import { QnAHeader } from "./header/QnAHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../globalVars/globalVars";

interface SubTextListType {
    title: string;
    description: string;
    imagePath: string;
    email: string;
}

interface TextListType {
    title: string;
    sub: SubTextListType[];
}

export const QnAPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let title = "";
    let textList: TextListType[];

    if (langCode === "en") {
        title = "Contact Us";
        textList = [
            {
                title: "ESCARE Solutions",
                sub: [
                    {
                        title: "PowerPack",
                        description: "DLP+SEP+PrintChaser",
                        imagePath: "",
                        email: "powerpack@escare.co.kr",
                    },
                    {
                        title: "ZT-VPN",
                        description: "Telecommuting Solution",
                        imagePath: "",
                        email: "ztvpn@escare.co.kr",
                    },
                ],
            },
            {
                title: "Vendor Solutions",
                sub: [
                    {
                        title: "Fortanix",
                        description: "Managing Data Encryption keys",
                        imagePath: "",
                        email: "fortanix@escare.co.kr",
                    },
                    {
                        title: "Fortinet",
                        description: "Network Security",
                        imagePath: "",
                        email: "network@escare.co.kr",
                    },
                    {
                        title: "SentinelOne",
                        description: "Next Generation EPP + EDR based on AI",
                        imagePath: "",
                        email: "sentinelone@escare.co.kr",
                    },
                    {
                        title: "Symanatec",
                        description: "Endpoint/Network Security",
                        imagePath: "",
                        email: "symantec@escare.co.kr",
                    },
                    {
                        title: "Other Solutions",
                        description: "",
                        imagePath: "",
                        email: "sales@escare.co.kr",
                    },
                ],
            },
            {
                title: "Other Questions",
                sub: [
                    {
                        title: "Company Question",
                        description: "",
                        imagePath: "",
                        email: "company@escare.co.kr",
                    },
                    {
                        title: "Employment Question",
                        description: "",
                        imagePath: "",
                        email: "HR@escare.co.kr",
                    },
                ],
            },
        ];
    } else {
        title = "문의하기";
        textList = [
            {
                title: "자사 솔루션",
                sub: [
                    {
                        title: "파워팩",
                        description: "DLP+SEP+PrintChaser",
                        imagePath: "",
                        email: "powerpack@escare.co.kr",
                    },
                    {
                        title: "ZT-VPN",
                        description: "외주근무자관리 솔루션",
                        imagePath: "",
                        email: "ztvpn@escare.co.kr",
                    },
                ],
            },
            {
                title: "취급 벤더 및 솔루션",
                sub: [
                    {
                        title: "Fortanix",
                        description: "데이터 암호키 관리",
                        imagePath: "",
                        email: "fortanix@escare.co.kr",
                    },
                    {
                        title: "Fortinet",
                        description: "네트워크 보안",
                        imagePath: "",
                        email: "network@escare.co.kr",
                    },
                    {
                        title: "SentinelOne",
                        description: "Ai 기반 차세대 EPP + EDR",
                        imagePath: "",
                        email: "sentinelone@escare.co.kr",
                    },
                    {
                        title: "Symanatec",
                        description: "엔드포인트/네트워크 보안",
                        imagePath: "",
                        email: "symantec@escare.co.kr",
                    },
                    {
                        title: "기타 제품",
                        description: "",
                        imagePath: "",
                        email: "sales@escare.co.kr",
                    },
                ],
            },
            {
                title: "기타 문의",
                sub: [
                    {
                        title: "회사문의",
                        description: "",
                        imagePath: "",
                        email: "company@escare.co.kr",
                    },
                    {
                        title: "채용문의",
                        description: "",
                        imagePath: "",
                        email: "HR@escare.co.kr",
                    },
                ],
            },
        ];
    }

    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{title}</div>
            </div>
            <div className={styles.cont}>
                {textList.map(value => (
                    <>
                        <div className={styles.title}>{value.title}</div>
                        <div className={styles.sectionList}>
                            {value.sub.map(sub => (
                                <>
                                    <div className={styles.sectionItem}>
                                        <div className={styles.subTitle}>{sub.title}</div>
                                        <div className={styles.subDescription}>
                                            {sub.description}
                                        </div>
                                        <div className={styles.subEmail}>
                                            <ButtonMailto mailto={sub.email}>
                                                <FontAwesomeIcon icon={faMailBulk} /> {sub.email}
                                            </ButtonMailto>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </>
    );
};

const ButtonMailto = (props: { mailto: string; children: ReactNode }) => {
    return (
        <Link
            to="#"
            onClick={event => {
                window.location.href = props.mailto;
                event.preventDefault();
            }}>
            {props.children}
        </Link>
    );
};
