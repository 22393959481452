import React from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { NewsType, Query, QueryNewsListArgs } from "../../graphql/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalVars } from "../../globalVars/globalVars";
import { Pipe } from "../../services/pipe/Pipe";
import { NewsHeader } from "./header/NewsHeader";
import { NewsTabBar } from "./tabBar/NewsTabBar";
import { MultiLang } from "../../graphql/types";
import { NewsListView } from "./list/NewsListView";
import { Pager } from "../../components/pagination/Pager";

export const NewsPage = () => {
    const { page, size, findWord, newsType } = useParams();
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const { data } = useQuery<Query, QueryNewsListArgs>(gqlData, {
        variables: {
            page: Number(page || 0),
            size: Number(size || 10),
            options: {
                langCode,
                findWord,
                newsType: Pipe.toNewsType(newsType),
            },
        },
    });
    const navigate = useNavigate();

    const handler = {
        onChangeNewType: (newsType: NewsType) => {
            navigate(`/news/0/10/${newsType}`);
        },
        onChangePage: (p: number) => {
            if (findWord) {
                navigate(`/news/${p}/10/${Pipe.toNewsType(newsType)}/${findWord}`);
            } else {
                navigate(`/news/${p}/10/${Pipe.toNewsType(newsType)}`);
            }
        },
    };

    return (
        <>
            <NewsHeader />

            <NewsTabBar
                data={menu}
                value={Pipe.toNewsType(newsType)}
                onChangeValue={handler.onChangeNewType}
            />

            {data && (
                <>
                    <NewsListView value={data.newsList.list} />

                    <Pager
                        page={data.newsList.page}
                        size={data.newsList.size}
                        total={data.newsList.total}
                        onChange={handler.onChangePage}
                    />

                    <div style={{ height: 50 }} />
                </>
            )}
        </>
    );
};

const gqlData = gql`
    query DATA($page: Int!, $size: Int!, $options: NewsFindOptions!) {
        newsList(page: $page, size: $size, options: $options) {
            page
            size
            total
            list {
                id
                title
                badge
                contents
                createdAt
                imgSrc
                langCode
                newsLabel
            }
        }
    }
`;

export interface NewsTabData {
    label: MultiLang;
    newsType: NewsType;
}

const menu: NewsTabData[] = [
    {
        label: {
            ko: "전체",
            en: "All",
        },
        newsType: NewsType.Any,
    },
    {
        label: {
            ko: "에스케어 뉴스",
            en: "Escare news",
        },
        newsType: NewsType.Escare,
    },
    {
        label: {
            ko: "제품 뉴스",
            en: "Product news",
        },
        newsType: NewsType.Product,
    },
    {
        label: {
            ko: "뉴스레터",
            en: "News letter",
        },
        newsType: NewsType.Letter,
    },
];
