import React, {Fragment, useEffect} from "react";
import styles from "./MobileSideBar.module.scss";
import { TopNavBar } from "../../../Layout";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Typography,
} from "@material-ui/core";
import { Close, ExpandMore, Search } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { I18nValue } from "../../../../system/i18nValue/I18nValue";
import { OnChangeValue } from "../../../../../graphql/types";
import logo from "../../../../../assets/img/logo/escare_logo.png";

export const MobileSideBar = (props: {
    topNavBar: TopNavBar[];
    onChange: OnChangeValue<boolean>;
}) => {
    return (
        <>
            <div style={{ width: "100%" }}>
                <div className={styles.contHeader}>
                    <div className={styles.logo}>
                        <Link to={"/"}>
                            <img src={logo} alt={"로고"} height={35} />
                        </Link>
                    </div>
                    <div className={styles.space} />
                    <div className={styles.boxBtn}>
                        <IconButton>
                            <Search />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                props.onChange(false);
                            }}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
                {props.topNavBar.map((value, index) => (
                    <MobileSideBarAccordion
                        navBar={value}
                        index={index}
                        key={index}
                        onChange={props.onChange}
                        sub={false}
                    />
                ))}
                <div className={styles.sealBox}>
                    <div id="DigiCertClickID_qsP6uv-y" lang="ko"></div>
                </div>
            </div>
        </>
    );
};

const MobileSideBarAccordion = (props: {
    index: number;
    navBar: TopNavBar;
    onChange: OnChangeValue<boolean>;
    sub: boolean;
}) => {
    const navigate = useNavigate();

    return (
        <Fragment key={props.index}>
            {props.navBar.sub && props.navBar.sub.length !== 0 && (
                <Accordion>
                    <AccordionSummary
                        className={props.sub ? "" : styles.accordionSummary}
                        expandIcon={
                            props.navBar.sub ? (
                                <ExpandMore className={props.sub ? "" : styles.expandMore} />
                            ) : (
                                ""
                            )
                        }>
                        <Typography>
                            <I18nValue value={props.navBar.label} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordionDetail}>
                        {props.navBar.sub &&
                            props.navBar.sub.map((subValue, index) => (
                                <MobileSideBarAccordion
                                    key={index}
                                    index={index}
                                    navBar={subValue}
                                    onChange={props.onChange}
                                    sub={true}
                                />
                            ))}
                    </AccordionDetails>
                </Accordion>
            )}
            {(!props.navBar.sub || props.navBar.sub.length === 0) && (
                <>
                    <Accordion key={props.index}>
                        <AccordionDetails
                            className={props.sub ? "" : styles.accordionSummary}
                            onClick={() => {
                                props.onChange(false);
                                navigate(props.navBar.router ? props.navBar.router : "");
                            }}>
                            <Typography className={props.sub ? "" : styles.typography}>
                                <I18nValue value={props.navBar.label} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </Fragment>
    );
};
