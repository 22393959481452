import { GraphQLError } from "graphql";
import { GlobalVars, UserVarUtils } from "../../globalVars/globalVars";
import { LocalStorage } from "../localstorage/LocalStorage";
import { BizErrors } from "../../graphql/bizErrors";
import { SwalUtils } from "../swal/SwalUtils";

export type BizErrorReactor = { [key: string]: (reason: any) => void };
type Catch = (e: any) => void;

export const ApolloCatch = (reactor: BizErrorReactor): Catch => {
    return e => {
        DefaultCatch(e, reactor);
    };
};

// todo 이곳에 파이어베이스 에러를 계속 추가한다.
export const ApolloWithFbCatch = (reactor: BizErrorReactor): Catch => {
    return e => {
        if (e instanceof GraphQLError) {
            DefaultCatch(e, reactor);
            return;
        }

        const { code } = e;

        switch (code) {
            case "auth/wrong-password":
                DefaultCatch(new GraphQLError(BizErrors.failLoginFb), reactor);
                return;
            default:
                DefaultCatch(new GraphQLError(BizErrors.failLoginFb), reactor);
        }
    };
};

const DefaultCatch = (e: any, reactor: BizErrorReactor) => {
    GlobalVars.layout.loadingView(false);

    if (!Boolean(e)) {
        SwalUtils.ok({
            msg: "실패 하였습니다.",
            icon: "error",
        });
        return;
    }

    // eslint-disable-next-line prefer-const
    let { networkError, graphQLErrors } = e;

    if (e instanceof GraphQLError) {
        graphQLErrors = [e];
    }

    if (networkError) {
        SwalUtils.ok({
            msg: "서버와 접속이 불안정 합니다.<br/>다시 시도하여 주십시오.",
            icon: "error",
        });
        return;
    }

    if (!graphQLErrors) {
        return;
    }

    if (!reactor) {
        return;
    }

    let runDefault = true;

    for (const err of graphQLErrors) {
        if (reactor.hasOwnProperty(err.message)) {
            runDefault = false;
            reactor[err.message](e);
            continue;
        }

        if (err.message === BizErrors.needLogin) {
            continue;
        }

        if (err.message === BizErrors.swalCancel) {
            continue;
        }

        if (err.message === BizErrors.swalBackdrop) {
            continue;
        }

        if ((err.message as string).startsWith("jwt")) {
            LocalStorage.clearSession();
            UserVarUtils.logout();
            break;
        }

        if (runDefault && reactor.hasOwnProperty(BizErrors.default)) {
            reactor[BizErrors.default](e);
        } else if (runDefault) {
            SwalUtils.ok({
                msg: e.message,
                icon: "error",
            });
        }
    }
};


