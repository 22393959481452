import React from "react";
import styles from "./Recruit.module.scss";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";

interface IProcessText {
    title: string;
    sub: string[];
}

interface IAnnounceText {
    title: string;
    description: string;
    sub: IAnnounceTextSub[];
}

interface IAnnounceTextSub {
    imagePath: string;
    link: string;
}

export const RecruitPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let titleText = "";

    let processText: IProcessText = {
        title: "",
        sub: [],
    };

    let announceText: IAnnounceText = {
        title: "",
        description: "",
        sub: [
            {
                imagePath: "recruit_jobkorea.png",
                link: "https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/scarecorp",
            },
            {
                imagePath: "recruit_saramin.png",
                link: "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=bDdOVlM3YSt6bG1FZHlhYVV4TnNwUT09",
            },
        ],
    };

    if (langCode === "en") {
        titleText = "Jobs";
        processText = {
            title: "채용 프로세스",
            sub: ["Document Screening", "Interview", "Recruitment negotiation", "Recruitment"],
        };

        announceText = {
            title: "How to apply",
            description: "You can check the application fields and duties through the job announcement buttons below and please submit the document.",
            sub: [
                {
                    imagePath: "recruit_jobkorea.png",
                    link: "https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/scarecorp",
                },
                {
                    imagePath: "recruit_saramin.png",
                    link: "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=bDdOVlM3YSt6bG1FZHlhYVV4TnNwUT09",
                },
            ],
        };
    } else {
        titleText = "인재채용";
        processText = {
            title: "채용 프로세스",
            sub: ["서류전형", "인터뷰", "채용협상", "채용"],
        };

        announceText = {
            title: "지원방법",
            description:
                "하단의 채용공고 버튼을 통해 지원분야 및 직무를 확인하신 후, 서류를 제출해주시면 됩니다.",
            sub: [
                {
                    imagePath: "recruit_jobkorea.png",
                    link: "https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/scarecorp",
                },
                {
                    imagePath: "recruit_saramin.png",
                    link: "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=bDdOVlM3YSt6bG1FZHlhYVV4TnNwUT09",
                },
            ],
        };
    }

    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{titleText}</div>
            </div>
            <div className={styles.cont}>
                <div className={styles.title}>{processText.title}</div>
                <div className={styles.circleLine}>
                    {processText.sub.map(value => (
                        <>
                            <div className={styles.circle}>{value}</div>
                            <div className={styles.circleBar}>
                                <div className={styles.circleBarTrue}/>
                                <div className={styles.circleBarFalse}/>
                            </div>
                        </>
                    ))}
                </div>

                <div className={styles.title}>{announceText.title}</div>
                <div className={styles.description}>{announceText.description}</div>
                <div className={styles.announceLine}>
                    {announceText.sub.map(value => (
                        <>
                            <div className={styles.announceItem}>
                                <a className={styles.link} href={value.link} target="_blank" rel="noreferrer">
                                    <img
                                        src={`/assets/images/recruit/${value.imagePath}`}
                                        height={45}
                                        alt={"링크이미지"}
                                        style={{margin: "10px 20px", width: 210, height: 50}}
                                    />
                                </a>
                            </div>
                        </>
                    ))}
                </div>
                <div style={{height: 100}}/>
            </div>
        </>
    );
};
