import React from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Query } from "../../graphql/graphql";
import { GlobalVars } from "../../globalVars/globalVars";
import { NewsListView } from "../news/list/NewsListView";
import { DefaultTitle } from "../../components/title/defaultTitle/DefaultTitle";
import { Pager } from "../../components/pagination/Pager";
import { FindResultProductList } from "./productList/FindResultProductList";
import { FindResultWord } from "./findWord/FindResultWord";

export const FindResultPage = () => {
    const { findWord, page } = useParams();
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const navigate = useNavigate();
    const { data } = useQuery<
        Query,
        {
            findWord?: string;
            newsPage: number;
            langCode: string;
        }
    >(gqlData, {
        variables: {
            findWord,
            newsPage: Number(page || 0),
            langCode,
        },
    });

    const handler = {
        onChangePage: (p: number) => {
            navigate(`/find/${findWord}/${p}`);
        },
    };

    return (
        <>
            {data && (
                <div>
                    <div style={{ height: 120 }} />

                    <FindResultWord findWord={findWord || ""} />
                    <DefaultTitle>제품</DefaultTitle>
                    <FindResultProductList value={data.productWithFindWord} />

                    <div style={{ height: 120 }} />
                    <DefaultTitle>뉴스</DefaultTitle>
                    <NewsListView value={data.newsList.list} />
                    <Pager
                        page={data.newsList.page}
                        size={data.newsList.size}
                        total={data.newsList.total}
                        onChange={handler.onChangePage}
                    />

                    <div style={{ height: 120 }} />
                </div>
            )}
        </>
    );
};

const gqlData = gql`
    query DATA($findWord: String!, $newsPage: Int!, $langCode: String!) {
        productWithFindWord(findWord: $findWord) {
            id
            backgroundSrc
            brochureLink
            header
            imposeEffect
            isActivate
            mainContent
            referenceLogo
            subHeader
            createdAt
            updatedAt
        }
        newsList(
            page: $newsPage
            size: 10
            options: { findWord: $findWord, langCode: $langCode, newsType: ANY }
        ) {
            page
            size
            total
            list {
                id
                title
                badge
                contents
                createdAt
                imgSrc
                langCode
                newsLabel
            }
        }
    }
`;
