import React, {useEffect, useState} from "react";
import { IndexCarousel } from "./carousel/IndexCarousel";
import { IndexSector } from "./sector/IndexSector";
import { IndexNews } from "./news/IndexNews";
import { IndexAsk } from "./ask/IndexAsk";
import { IndexPageFloatingButton } from "./pageFloatingButton/IndexPageFloatingButton";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Query } from "../../graphql/graphql";
import {GlobalVars, ViewMode} from "../../globalVars/globalVars";
import { IndexClients } from "./clients/IndexClients";
import { IndexPopup } from "./popup/IndexPopup";
import ReactPageScroller from "react-page-scroller";
import {Footer} from "../../components/layout/web/footer/Footer";
import {useLocation} from "react-router-dom";

export const IndexPage = () => {
    const { pathname } = useLocation();
    const viewMode = useReactiveVar(GlobalVars.window.viewMode);
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const { data } = useQuery<Query>(gqlData, {
        variables: {
            langCode: langCode,
        },
    });
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);

    useEffect(() => {
        if (pathname === "/") {
            GlobalVars.layout.footer(false);
        } else {
            GlobalVars.layout.footer(true);
        }

        return () => {
            GlobalVars.layout.footer(true);
        };
    }, [pathname]);

    const handler = {
        onPageChange: (number: number) => {
            setCurrentPage(number);
            setIsFooterVisible(number === 3)
        },
        beforePageChange: (number: number) => {
            console.log(number);
        },
    }

    return (
        <>
            {data && (
                <IndexPageFloatingButton>
                    <IndexPopup notice={data.directNoticeIndex} />

                    {viewMode === ViewMode.Web && (
                        <>
                            <ReactPageScroller
                                pageOnChange={handler.onPageChange}
                                onBeforePageScroll={handler.beforePageChange}
                                customPageNumber={currentPage}>
                                <IndexCarousel data={data.indexCarousel} />
                                <IndexSector data={data.indexInfoBar} />
                                <IndexNews list={data.newsInIndex} />
                                <IndexClients />
                            </ReactPageScroller>
                            {isFooterVisible && (
                                <>
                                    <IndexAsk />
                                    <Footer />
                                </>
                            )}
                        </>
                    )}
                    {viewMode === ViewMode.Mobile && (
                        <>
                            <IndexCarousel data={data.indexCarousel} />
                            <IndexSector data={data.indexInfoBar} />
                            <IndexNews list={data.newsInIndex} />
                            <IndexClients />
                            <IndexAsk />
                            <Footer />
                        </>
                    )}
                </IndexPageFloatingButton>
            )}
        </>
    );
};

const gqlData = gql`
    query Data($langCode: String!) {
        indexCarousel {
            imgSrc
            link
            preHeader
            header
            description
        }
        newsInIndex(langCode: $langCode) {
            id
            imgSrc
            badge
            createdAt
            newsLabel
            title
        }
        directNoticeIndex(langCode: $langCode) {
            id
            langCode
            content
            urgent
        }
        indexInfoBar {
            bgUrl
            createdAt
            iconUrl
            id
            isActivate
            label
            link
            linkType
            order
            title
        }
    }
`;
