import React, { Fragment, useState } from "react";
import styles from "./DashboardMainInputFindWord.module.scss";
import { OnChangeValue } from "../../../../graphql/types";
import { NoDataTable } from "../../../../components/table/none/NoDataTable";
import { TitleWithAddButton } from "../../../../components/input/inputProductView/InputProductView";
import { CopyUtils } from "../../../../services/copyUtils/copyUtils";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete } from "@material-ui/icons";
import { SwalUtils } from "../../../../services/swal/SwalUtils";
import { ApolloCatch } from "../../../../services/apollo/apolloCatch";
import { TableBtnView } from "../../../../components/table/btn/TableBtnView";

export const DashboardMainInputFindWord = (props: {
    value: string[];
    onUpdateFindWord: OnChangeValue<string[]>;
}) => {
    const [value, setValue] = useState(props.value);

    const handler = {
        onClickAdd: () => {
            const copy = CopyUtils.copyAll(value);
            copy.push("");
            setValue(copy);
        },
        onClickUp: (idx: number) => {
            if (idx === 0) {
                SwalUtils.ok({
                    msg: "최 상단 데이터 입니다.",
                    icon: "warning",
                });
                return;
            }

            const copy = CopyUtils.copyAll(value);
            const cache = copy[idx - 1];
            copy[idx - 1] = copy[idx];
            copy[idx] = cache;
            setValue(copy);
        },
        onClickDown: (idx: number) => {
            if (idx === value.length - 1) {
                SwalUtils.ok({
                    msg: "최 하단 데이터 입니다.",
                    icon: "warning",
                });
                return;
            }

            const copy = CopyUtils.copyAll(value);
            const cache = copy[idx + 1];
            copy[idx + 1] = copy[idx];
            copy[idx] = cache;
            setValue(copy);
        },
        onClickDelete: (idx: number) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    let copy = CopyUtils.copyAll(value);
                    copy = [...copy.slice(0, idx), ...copy.slice(idx + 1, copy.length)];
                    setValue(copy);
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <Fragment>
            <TitleWithAddButton toolTip={"추가"} onClickAddButton={handler.onClickAdd}>
                <h4>추천 검색어</h4>
            </TitleWithAddButton>

            <div className={classNames(styles.table, styles.tableFindWord)}>
                <div className={classNames(styles.colOrder, styles.col)}>순서</div>
                <div className={classNames(styles.colValue, styles.col)}>단어</div>
                <div className={classNames(styles.colFunc, styles.col)}>기능</div>
            </div>

            {value.length === 0 && <NoDataTable />}

            {value.map((word, idx) => (
                <div key={idx} className={classNames(styles.table, styles.tableFindWord)}>
                    <div className={classNames(styles.colOrder, styles.col)}>{idx + 1}</div>
                    <div className={classNames(styles.colValue, styles.col)}>
                        <input
                            value={word}
                            onChange={ev => {
                                const copy = CopyUtils.copyAll(value);
                                copy[idx] = ev.target.value;
                                setValue(copy);
                            }}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className={classNames(styles.colFunc, styles.col)}>
                        <IconButton onClick={() => handler.onClickUp(idx)}>
                            <ArrowUpward />
                        </IconButton>
                        <IconButton onClick={() => handler.onClickDown(idx)}>
                            <ArrowDownward />
                        </IconButton>
                        <IconButton onClick={() => handler.onClickDelete(idx)}>
                            <Delete />
                        </IconButton>
                    </div>
                </div>
            ))}
            <TableBtnView>
                <button
                    onClick={() => props.onUpdateFindWord(value)}
                    className="btn btn-sm btn-outline-secondary">
                    저장
                </button>
            </TableBtnView>
        </Fragment>
    );
};
