import React from "react";
import styles from "./IndexAsk.module.scss";
import {BorderButton} from "../../../components/btn/borderButton/BorderButton";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../globalVars/globalVars";
import {LinkUtils} from "../../../services/link/LinkUtils";
import {ArrowRight} from "@material-ui/icons";

export const IndexAsk = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = {
        title: "",
        description: "",
        button: ""
    };

    if (langCode === "en") {
        textList = {
            title: "Ask Anything",
            description: "If you have any questions about Scare products, please contact us.",
            button: "Contact Us"
        }
    } else {
        textList = {
            title: "무엇이든 물어보세요",
            description: "에스케어의 제품에 대해 궁금하신 사항은 문의주세요.",
            button: "문의하기"
        }
    }

    return (
        <div className={styles.cont}>
            <div className={styles.title}>{textList.title}</div>
            <div className={styles.description}>{textList.description}</div>
            <div>
                <div
                    className={styles.button}
                    onClick={() => LinkUtils.openNewWindow(window.location.origin + "/qna")}>
                    <span>{textList.button}</span>
                </div>
            </div>
        </div>
    );
};
