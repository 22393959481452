import React, {useEffect, useRef, useState} from "react";
import styles from "./WebHeader.module.scss";
import {ArrowDropUp, Language, Search} from "@material-ui/icons";
import classNames from "classnames";
import {TopNavBar} from "../../Layout";
import useSize from "@react-hook/size";
import {Link, useNavigate} from "react-router-dom";
import {MultiLang, OnChangeValue} from "../../../../graphql/types";
import {I18nValue} from "../../../system/i18nValue/I18nValue";
import Popover from "@material-ui/core/Popover";
import {I18nUtil} from "../../../../services/i18n/i18n";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../../globalVars/globalVars";
import {SwalUtils} from "../../../../services/swal/SwalUtils";
import imgLogo from "../../../../assets/img/logo/escare_logo.png";

export const WebHeader = (props: { topNavBar: TopNavBar[] }) => {
    const style = headerStyles.show;
    const [selTopNavBarId, setSelTopNavBarId] = useState<number | undefined>();
    const [langBtnAnchor, setLangBtnAnchor] = useState<HTMLDivElement | undefined>();
    const [onFinderView, setOnFinderView] = useState(false);
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const navigate = useNavigate();

    const handler = {
        onMouseLeave: () => {
            if (!selTopNavBarId) {
                return;
            }

            if (!props.topNavBar[selTopNavBarId].sub) {
                setSelTopNavBarId(undefined);
            }
        },
        onClickNavBar: (router?: string) => {
            if (!router) {
                return;
            }

            setSelTopNavBarId(undefined);
            navigate(router);
        },
        onClickLangBtn: (ev: React.MouseEvent<HTMLDivElement>) => {
            setLangBtnAnchor(ev.currentTarget);
        },
        onClickChangeLangCode: (langCode: string) => {
            I18nUtil.chgLang(langCode);
            setLangBtnAnchor(undefined);
        },
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.escare.co.kr/assets/js/digicert.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div style={{background: style.navBackground}} className={styles.cont}>
                <div className={styles.boxHeader}>
                    <div style={{color: style.fontColor}} className={styles.logo}>
                        <Link to={"/"}>
                            <img src={imgLogo} alt="escare"/>
                        </Link>
                    </div>

                    <div style={{color: style.fontColor}} className={styles.boxBtnList}>
                        {props.topNavBar.map((value, index) => (
                            <NavBtn
                                key={index}
                                {...value}
                                onClickNavBar={() => {
                                    handler.onClickNavBar(value.router);
                                }}
                                onMouseLeave={handler.onMouseLeave}
                                isActivate={selTopNavBarId === index}
                                onMouseOver={() => {
                                    setSelTopNavBarId(index);
                                }}
                            />
                        ))}
                    </div>

                    <div className={styles.boxFunction}>
                        <div
                            onClick={() => setOnFinderView(true)}
                            style={{color: style.fontColor}}
                            className={classNames(styles.iconButton, styles.btnSearch)}>
                            <Search/>
                        </div>

                        <div
                            style={{color: style.fontColor}}
                            className={classNames(styles.iconButton, styles.btnLang)}
                            onClick={handler.onClickLangBtn}
                        >
                            <Language/>
                            <span style={{color: style.fontColor}}>
                                {langCode === "en" && "English"}
                                {langCode === "ko" && "한국어"}
                            </span>
                        </div>

                        <Popover
                            anchorEl={langBtnAnchor}
                            open={Boolean(langBtnAnchor)}
                            onClose={() => setLangBtnAnchor(undefined)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}>
                            <div className={styles.contBtnLang}>
                                <div
                                    className={styles.btnLang}
                                    onClick={() => handler.onClickChangeLangCode("en")}>
                                    English
                                </div>
                                <div
                                    className={styles.btnLang}
                                    onClick={() => handler.onClickChangeLangCode("ko")}>
                                    한국어
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>

            {selTopNavBarId !== undefined && (
                <SubNav
                    navBar={props.topNavBar[selTopNavBarId]}
                    onClickNavBar={handler.onClickNavBar}
                    onMouseLeave={() => {
                        setSelTopNavBarId(undefined);
                    }}
                />
            )}

            {onFinderView && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <FinderView onFindWord={s => {
                }} onClose={() => setOnFinderView(false)}/>
            )}
        </>
    );
};

const NavBtn = (props: {
    label: MultiLang;
    router?: string;
    onClickNavBar: VoidFunction;
    onMouseOver: () => void;
    onMouseLeave: () => void;
    isActivate: boolean;
}) => {
    const contRef = useRef<HTMLDivElement>(null);
    const [width] = useSize(contRef);

    return (
        <>
            <div
                ref={contRef}
                className={classNames(styles.navBtn, props.isActivate && styles.activate)}
                onClick={props.onClickNavBar}
                onMouseOver={props.onMouseOver}
                onMouseLeave={props.onMouseLeave}>
                <div className={styles.label}>
                    <I18nValue value={props.label}/>
                </div>
                <div className={styles.bar}>
                    <ArrowDropUp
                        style={{
                            left: (width - 24) / 2,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const SubNav = (props: {
    navBar: TopNavBar;
    onClickNavBar: (router?: string) => void;
    onMouseLeave: () => void;
}) => {
    const [secondSubNav, setSecondSubNav] = useState<TopNavBar[] | undefined>();

    return (
        <>
            {props.navBar.sub && props.navBar.sub.length !== 0 && (
                <div className={styles.contSubNav} onMouseLeave={props.onMouseLeave}>
                    <div className={styles.boxSubNav}>
                        <div className={styles.boxBtnList}>
                            {props.navBar.sub &&
                                props.navBar.sub.map((value, index) => (
                                    <div className={styles.btn} key={index}>
                                        <span
                                            onClick={() => props.onClickNavBar(value.router)}
                                            className={styles.label}
                                            onMouseOver={() => setSecondSubNav(value.sub)}>
                                            <I18nValue value={value.label}/>
                                        </span>
                                    </div>
                                ))}
                        </div>

                        <div className={styles.boxBtnList}>
                            {secondSubNav &&
                                secondSubNav.length !== 0 &&
                                secondSubNav.map((value, index) => (
                                    <div className={styles.btn} key={index}>
                                        <span
                                            onClick={() => props.onClickNavBar(value.router)}
                                            className={styles.label}>
                                            <I18nValue value={value.label}/>
                                        </span>
                                    </div>
                                ))}
                        </div>

                        <div className={styles.boxImg}>{/*<div className={styles.img}/>*/}</div>
                    </div>
                </div>
            )}
        </>
    );
};

const FinderView = (props: { onFindWord: OnChangeValue<string>; onClose: VoidFunction }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const [value, setValue] = useState("");
    const findWordList = useReactiveVar(GlobalVars.layout.recommendationFindWord);
    const navigate = useNavigate();

    let textList = {
        onFindWord: ""
    };

    if (langCode === "en") {
        textList = {
            onFindWord: "Please enter a search term."
        }
    } else {
        textList = {
            onFindWord: "검색어를 입력하여 주세요."
        }
    }

    const handler = {
        onClickWord: (word: string) => {
            setValue(`${word} ${value}`);
        },
        onFindWord: () => {
            if (!value) {
                SwalUtils.ok({
                    msg: textList.onFindWord,
                    icon: "error",
                });
                return;
            }

            navigate(encodeURI(`/find/${value.trim()}`));
            props.onClose();
        },
    };

    return (
        <form
            onSubmit={ev => {
                ev.preventDefault();
                handler.onFindWord();
            }}
            className={styles.contFinder}>
            <div className={styles.boxFinder}>
                <div style={{height: 50}}/>
                <div className={styles.divInput}>
                    <input
                        placeholder={textList.onFindWord}
                        value={value}
                        onChange={ev => setValue(ev.target.value)}
                    />
                    <div onClick={handler.onFindWord} className={styles.divBtn}>
                        <Search/>
                    </div>
                </div>

                <div className={styles.divFindWord}>
                    {findWordList.map((findWord, index) => (
                        <div onClick={() => handler.onClickWord(findWord)} key={index}>
                            #{findWord}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.boxOther} onClick={props.onClose}/>
        </form>
    );
};

const headerStyles: { [key: string]: HeaderStyle } = {
    show: {
        navBackground: "rgba(255,255,255,1)",
        fontColor: "rgb(0,0,0)",
    },
    hide: {
        navBackground: "rgba(255,255,255,0)",
        fontColor: "rgb(255,255,255)",
    },
};

interface HeaderStyle {
    navBackground: string,
    fontColor: string,
}
