import React from "react";
import styles from "./DashboardNewsList.module.scss";
import { News } from "../../../../graphql/graphql";
import classNames from "classnames";
import { NoDataTable } from "../../../../components/table/none/NoDataTable";
import { I18nValue } from "../../../../components/system/i18nValue/I18nValue";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { OnChangeValue } from "../../../../graphql/types";
import { InputUpdateNews } from "../DashboardNews";
import { BadgeNewsType } from "../../../../components/badge/newsType/BadgeNewsType";

export const DashboardNewsList = (props: {
    value: News[];
    onClickUpdateNews: OnChangeValue<InputUpdateNews>;
    onClickRemoveNews: OnChangeValue<string>;
}) => {
    return (
        <>
            <div className={classNames(styles.table, styles.tableNews)}>
                <div className={classNames(styles.header, styles.colImg)}>썸네일</div>
                <div className={classNames(styles.header, styles.colLangCode)}>언어</div>
                <div className={classNames(styles.header, styles.colBadge)}>뱃지</div>
                <div className={classNames(styles.header, styles.colNewsLabel)}>발행</div>
                <div className={classNames(styles.header, styles.colTitle)}>제목</div>
                <div className={classNames(styles.header, styles.colCreatedAt)}>생성일</div>
                <div className={classNames(styles.header, styles.colFunc)}>기능</div>
            </div>

            {props.value.length === 0 && <NoDataTable />}

            {props.value.map((value, index) => (
                <div key={index} className={classNames(styles.table, styles.tableNews)}>
                    <div className={classNames(styles.content, styles.colImg)}>
                        <img src={value.imgSrc} alt="img" />
                    </div>
                    <div className={classNames(styles.content, styles.colLangCode)}>
                        {value.langCode}
                        <br />
                        <BadgeNewsType value={value.newsType} />
                    </div>
                    <div className={classNames(styles.content, styles.colBadge)}>
                        <I18nValue value={value.badge} />
                    </div>
                    <div className={classNames(styles.content, styles.colNewsLabel)}>
                        {value.newsLabel}
                    </div>
                    <div className={classNames(styles.content, styles.colTitle)}>{value.title}</div>
                    <div className={classNames(styles.content, styles.colCreatedAt)}>
                        {moment(value.createdAt).format("YY.MM.DD - hh:mm a")}
                    </div>
                    <div className={classNames(styles.content, styles.colFunc)}>
                        <IconButton
                            onClick={() =>
                                props.onClickUpdateNews({
                                    id: value.id,
                                    input: {
                                        badge: value.badge,
                                        contents: value.contents,
                                        imgSrc: value.imgSrc,
                                        langCode: value.langCode,
                                        newsLabel: value.newsLabel,
                                        title: value.title,
                                        newsType: value.newsType,
                                    },
                                })
                            }>
                            <Edit />
                        </IconButton>

                        <IconButton onClick={() => props.onClickRemoveNews(value.id)}>
                            <Delete />
                        </IconButton>
                    </div>
                </div>
            ))}
        </>
    );
};
