export class BizErrors {
    static default = "default";
    static needLogin = "needLogin";
    static notFoundSession = "notFoundSession";
    static notFoundAdmin = "notFoundAdmin";
    static loginLimited = "loginLimited";
    static loginFail = "loginFail";
    static alreadyHasCompany = "alreadyHasCompany";
    static failCreateCompany = "failCreateCompany";
    static alreadyBidAuctionCompany = "alreadyBidAuctionCompany";
    static notFoundAuctionCompany = "notFoundAuctionCompany";
    static notFoundAuctionCompanyRequest = "notFoundAuctionCompanyRequest";
    static notEnoughMinBid = "notEnoughMinBid";
    static shortageBalance = "shortageBalance";
    static overMaxSize = "overMaxSize";
    static failGoogleLogin = "failGoogleLogin";
    static failJoinWithEmailAndPw = "failJoinWithEmailAndPw";
    static failLoginWithEmailAndPw = "failLoginWithEmailAndPw";
    static notFoundUser = "notFoundUser";
    static wrongPassword = "wrongPassword";
    static alreadyJoined = "alreadyJoined";
    static noConfirmEmailVerified = "noConfirmEmailVerified";
    static alreadyJoinedEmail = "alreadyJoinedEmail";
    static dontActBizErrors = "dontActBizErrors";
    static failLoginFb = "failLoginFb";
    static noHasEmailVerified = "noHasEmailVerified";
    static notFoundData = "notFoundData";
    static noJoinTypeEmail = "noJoinTypeEmail";
    static noHasPermission = "noHasPermission";
    static notEnoughPoint = "notEnoughPoint";
    static swalCancel = "swalCancel";
    static noHasConsultant = "noHasConsultant";
    static swalDismissed = "swalDismissed";
    static swalBackdrop = "swalBackdrop";
    static dupKeyWord = "dupKeyWord";
    static noHasDataInPromiseChain = "noHasDataInPromiseChain";
    static forbiddenRemoveNavItemWithChildren = "ForbiddenRemoveNavItemWithChildren"
}
