import React from "react";
import styles from "./IntelligentPage.module.scss";
import { IntelligentHeader } from "./header/IntelligentHeader";

export const IntelligentPage = () => {
    return (
        <>
            <IntelligentHeader />
            <div className={styles.container}>준비 중입니다</div>
        </>
    );
};
