import React, {useEffect, useState} from "react";
import styles from "./IndexPopup.module.scss";
import {DirectNotice} from "../../../graphql/graphql";
import {LocalStorage} from "../../../services/localstorage/LocalStorage";
import moment from "moment";
import {Button} from "@material-ui/core";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../globalVars/globalVars";

export const IndexPopup = (props: { notice: DirectNotice[] }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const [popup, setPopup] = useState<DirectNotice[]>([]);
    const [check, setCheck] = useState<boolean>(false);

    let nextPopupData: DirectNotice[] = [];
    let textList = {
        notSeen: "",
        closed: ""
    };

    if (langCode === "en") {
        textList = {notSeen: "Not seen for a day", closed: "Closed"};
    } else {
        textList = {notSeen: "하루동안 보지 않기", closed: "닫기"};
    }

    useEffect(() => {
        const localData = LocalStorage.getIndexPopupData(1);

        nextPopupData = props.notice.filter(data => {
            return data.urgent;
        });

        for (const localElement of localData) {
            nextPopupData = nextPopupData.filter(data => {
                return data.id !== localElement.id;
            });
        }

        setPopup(nextPopupData);
    }, [check]);

    const handler = {
        onClickPreventPopup: (id: string) => {
            const localData = LocalStorage.getIndexPopupData(1);
            localData.push({
                id,
                checkedDate: moment().toISOString(),
            });
            LocalStorage.setIndexPopupData(localData);
            setCheck(!check);
        },
        onClickClosePopup: (id: string) => {
            const localData = LocalStorage.getIndexPopupData(1);
            localData.push({
                id,
                checkedDate: moment().toISOString(),
            });
            LocalStorage.setIndexPopupData(localData);
            setCheck(!check);
        },
    };

    return (
        <>
            {popup &&
                popup.map((value, index) => (
                    <div
                        key={index}
                        id={"popup" + index}
                        className={styles.modal}
                        style={{zIndex: 100 + index}}>
                        <div
                            className={styles.content}
                            ref={ref => {
                                if (ref) {
                                    ref.innerHTML = value.content;
                                }
                            }}></div>
                        <div className={styles.unSee}>
                            <Button
                                variant="text"
                                className={styles.notShow}
                                onClick={() => handler.onClickPreventPopup(value.id)}>
                                {textList.notSeen}
                            </Button>
                            <Button
                                variant="text"
                                className={styles.notShow}
                                onClick={_ => {
                                    const popupDiv = document.getElementById("popup" + index);
                                    if (popupDiv) {
                                        popupDiv.style.display = "none";
                                    }
                                    return;
                                }}>
                                {textList.closed}
                            </Button>
                        </div>
                    </div>
                ))}
        </>
    );
};
