import { ContentUnit, MultiLang } from "../../graphql/types";
import { GlobalVars } from "../../globalVars/globalVars";
import { InputProduct } from "../../graphql/graphql";
import { SwalUtils } from "../swal/SwalUtils";

export class BizValidator {
    static checkMultiLang(value: MultiLang): boolean {
        const langCodeList = GlobalVars.i18n.langCodeList();

        for (const langCode of langCodeList) {
            if (!value.hasOwnProperty(langCode)) {
                return false;
            }

            if (!value[langCode]) {
                return false;
            }
        }

        return true;
    }

    static checkContentUnit(value: ContentUnit): boolean {
        if (!BizValidator.checkMultiLang(value.title)) {
            return false;
        }

        if (!BizValidator.checkMultiLang(value.content)) {
            return false;
        }

        return true;
    }

    static checkInputProduct(value: InputProduct): boolean {
        if (!BizValidator.checkMultiLang(value.header)) {
            SwalUtils.ok({
                msg: "상품명을 입력하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        if (!BizValidator.checkMultiLang(value.subHeader)) {
            SwalUtils.ok({
                msg: "부 상품명을 입력하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        // if (!BizValidator.checkMultiLang(value.brochureLink)) {
        //     SwalUtils.ok({
        //         msg: "브로셔 파일을 확인하여 주십시오.",
        //         icon: "warning",
        //     });
        //     return false;
        // }

        if (!BizValidator.checkMultiLang(value.backgroundSrc)) {
            SwalUtils.ok({
                msg: "백그라운드 이미지를 확인하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        if (!BizValidator.checkContentUnit(value.mainContent)) {
            SwalUtils.ok({
                msg: "제품 설명을 확인하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        for (let i = 0; i < value.imposeEffect.length; i++) {
            const contentUnit = value.imposeEffect[i];
            if (!BizValidator.checkContentUnit(contentUnit)) {
                SwalUtils.ok({
                    msg: `${i + 1} 번째 도입효과를 확인하여 주십시오.`,
                    icon: "warning",
                });
                return false;
            }
        }

        for (let i = 0; i < value.referenceLogo.length; i++) {
            const refLogoLink = value.referenceLogo[i];
            if (!refLogoLink) {
                SwalUtils.ok({
                    msg: `${i + 1} 번째 레퍼런스 이미지를 확인하여 주십시오.`,
                    icon: "warning",
                });
                return false;
            }
        }

        return true;
    }
}
