import React from "react";
import styles from "./NoDataTable.module.scss";
import classNames from "classnames";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";

export const NoDataTable = (props: { noBorder?: boolean }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = "";

    if (langCode === "en") {
        textList = "No Data.";
    } else {
        textList = "데이터가 없습니다.";
    }
    return (
        <div className={styles.table} style={{ borderTop: props.noBorder ? "none" : "" }}>
            <div style={{ width: "100%" }} className={classNames(styles.colFull, styles.col)}>
                {textList}
            </div>
        </div>
    );
};
