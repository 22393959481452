import React, { useEffect, useState } from "react";
import { RouteObject, useLocation, useRoutes } from "react-router-dom";
import { GetRouter } from "./Router";
import { Layout } from "../components/layout/Layout";
import { NotFoundPage } from "./notFound/NotFoundPage";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../globalVars/globalVars";
import { LoginCheck } from "../components/system/loginCheck/LoginCheck";

function App() {
    const isLogin = useReactiveVar(GlobalVars.user.isLogin);
    const [router, setRouter] = useState<RouteObject[]>(GetRouter(isLogin));
    const { pathname } = useLocation();
    const page = useRoutes(router);

    useEffect(() => {
        setRouter(GetRouter(isLogin));
    }, [isLogin]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Layout>
            <LoginCheck />
            {page || <NotFoundPage />}
        </Layout>
    );
}

export default App;
