import React from "react";
import {InputNavItem} from "../../../../graphql/graphql";
import {OnChangeValue} from "../../../../graphql/types";
import styles from "../../category/inputNavBar/InputNavBarView.module.scss";
import classNames from "classnames";
import {InputMultiLang} from "../../../../components/input/multiLang/InputMultiLang";
import NumberFormat from "react-number-format";
import {Checkbox} from "@material-ui/core";

export const InputNavItemView = (props: {
    value: InputNavItem,
    onChangeValue: OnChangeValue<InputNavItem>
}) => {
    const value = props.value;

    return (
        <div className={styles.cont}>
            <div className={styles.boxInput}>
                <div className={classNames(styles.tableInput)}>
                    <div className={classNames(styles.colLabel, styles.col)}>단</div>
                    <div className={classNames(styles.colValue, "text-center")}>
                        {value.depth + 1} 단
                    </div>
                </div>

                <div className={classNames(styles.tableInput)}>
                    <div className={classNames(styles.colLabel, styles.col)}>활성화</div>
                    <div className={classNames(styles.colValue)}>
                        <Checkbox
                            checked={value.isActivate}
                            onChange={ev => props.onChangeValue({...value, isActivate: ev.target.checked})}/>
                    </div>
                </div>

                <div className={classNames(styles.tableInput)}>
                    <div className={classNames(styles.colLabel, styles.col)}>이름</div>
                    <div className={classNames(styles.colValue)}>
                        <InputMultiLang
                            value={props.value.label}
                            onChangeValue={label => props.onChangeValue({...props.value, label})}
                        />
                    </div>
                </div>

                <div className={classNames(styles.tableInput)}>
                    <div className={classNames(styles.colLabel, styles.col)}>순서</div>
                    <div className={classNames(styles.colValue)}>
                        <NumberFormat
                            className="form-control form-control-sm"
                            value={props.value.order}
                            allowNegative={false}
                            decimalScale={0}
                            onValueChange={v =>
                                props.onChangeValue({...props.value, order: v.floatValue || 0})
                            }
                        />
                    </div>
                </div>

                <div className={classNames(styles.tableInput)}>
                    <div className={classNames(styles.colLabel, styles.col)}>주소</div>
                    <div className={classNames(styles.colValue)}>
                        <input
                            className="form-control form-control-sm"
                            value={props.value.link || ""}
                            onChange={ev =>
                                props.onChangeValue({...props.value, link: ev.target.value || ""})
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
