import React, {useEffect, useState} from "react";
import {DashboardLayout} from "../../../components/dashboardLayout/DashboardLayout";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {
    InputProduct, InputProductOrder,
    Mutation,
    MutationUpdateProductArgs, MutationUpsertProductOrderArgs,
    Query,
    QueryProductForAdminArgs,
} from "../../../graphql/graphql";
import {InitInputProduct} from "../createProduct/DashboardCreateProductPage";
import {CopyUtils} from "../../../services/copyUtils/copyUtils";
import {InputProductView} from "../../../components/input/inputProductView/InputProductView";
import {TableBtnView} from "../../../components/table/btn/TableBtnView";
import {BizValidator} from "../../../services/validator/BizValidator";
import {SwalUtils} from "../../../services/swal/SwalUtils";
import {ApolloCatch} from "../../../services/apollo/apolloCatch";
import {ActiveProductView} from "../../../components/input/ActiveProductView/ActiveProductView";

export const DashboardUpdateProductPage = () => {
    const {id} = useParams();
    const [input, setInput] = useState<InputProduct>(CopyUtils.copyAll(InitInputProduct));
    const [orderInput, setOrderInput] = useState<InputProductOrder>(
        CopyUtils.copyAll(InitInputProductOrder),
    );

    const {data} = useQuery<Query, QueryProductForAdminArgs>(gqlData, {
        variables: {
            id: id || "",
        },
    });

    const [mutUpdate] = useMutation<Mutation, MutationUpdateProductArgs>(gqlUpdate);
    const [mutUpsertProduct] = useMutation<Mutation, MutationUpsertProductOrderArgs>(
        gqlUpsertProduct,
    );

    useEffect(() => {
        if (!data) {
            return;
        }

        const value = data.productForAdmin;
        const orderValue = data.productOrder;

        setInput(
            CopyUtils.copyAll({
                isActivate: value.isActivate,
                header: value.header,
                subHeader: value.subHeader,
                brochureLink: value.brochureLink,
                mainContent: value.mainContent,
                imposeEffect: value.imposeEffect,
                referenceLogo: value.referenceLogo,
                backgroundSrc: value.backgroundSrc,
                homepage: value.homepage,
                subContent: value.subContent,
                descContent: value.descContent,
            }),
        );

        setOrderInput(
            CopyUtils.copyAll({
                part1: orderValue.part1,
                part2: orderValue.part2,
                part3: orderValue.part3,
                part4: orderValue.part4,
                part5: orderValue.part5,
            })
        )
    }, [data]);

    const handler = {
        onClickUpdate: () => {
            if (!BizValidator.checkInputProduct(input)) {
                return;
            }

            if (!id) {
                return;
            }

            SwalUtils.ynWithPromise({
                msg: "수정 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdate({
                        variables: {
                            id,
                            input,
                        },
                    });
                })
                .then(() => {
                    return mutUpsertProduct({
                        variables: {
                            productId: id,
                            input: orderInput
                        }
                    })
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "수정 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <DashboardLayout router="/dashboard/product/manage">
            <h4>제품 수정 ({id})</h4>
            <div style={{height: 20}}/>
            <ActiveProductView orderValue={orderInput} onChange={setOrderInput}/>
            <InputProductView langCodeList={["ko", "en"]} value={input} onChange={setInput}/>
            <TableBtnView>
                <button onClick={handler.onClickUpdate} className="btn btn-outline-secondary">
                    수정
                </button>
            </TableBtnView>
        </DashboardLayout>
    );
};


const InitInputProductOrder: InputProductOrder = {
    part1: true,
    part2: true,
    part3: true,
    part4: true,
    part5: true,
};

const gqlUpdate = gql`
    mutation Update($id: String!, $input: InputProduct!) {
        updateProduct(id: $id, input: $input)
    }
`;

const gqlData = gql`
    query DATA($id: String!) {
        productForAdmin(id: $id) {
            id
            backgroundSrc
            brochureLink
            header
            imposeEffect
            isActivate
            mainContent
            referenceLogo
            subHeader
            homepage
            subContent
            descContent
            createdAt
            updatedAt
        }
        productOrder(id: $id) {
            part1
            part2
            part3
            part4
            part5
        }
    }
`;


const gqlUpsertProduct = gql`
    mutation UpsertProduct($input: InputProductOrder!, $productId: String!) {
        upsertProductOrder(input: $input, productId: $productId)
    }
`;

