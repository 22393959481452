import React from "react";
import styles from "./ArticleContent.module.scss";
import { News } from "../../../graphql/graphql";
import { IconButton } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import moment from "moment";
import copy from "copy-to-clipboard";
import { SwalUtils } from "../../../services/swal/SwalUtils";

export const ArticleContent = (props: { value: News }) => {
    const handler = {
        onClickShare: (id: string) => {
            const url = `${window.location.origin}/article/${id}`;
            copy(url);
            SwalUtils.ok({
                msg: "클립보드에 주소가<br/> 복사 되었습니다.",
                icon: "success",
            });
        },
    };

    return (
        <div className={styles.cont}>
            <div className={styles.boxHeader}>
                <div className={styles.divTitle}>
                    <div className={styles.title}>{props.value.title}</div>
                    <div className={styles.createdAt}>
                        {moment(props.value.createdAt).format("YY.MM.DD")}
                    </div>
                </div>
                <div className={styles.divShare}>
                    <IconButton onClick={() => handler.onClickShare(props.value.id)}>
                        <Share />
                    </IconButton>
                </div>
            </div>

            <div
                className={styles.boxContent}
                ref={ref => {
                    if (ref) {
                        ref.innerHTML = props.value.contents;
                    }
                }}
            />
        </div>
    );
};
