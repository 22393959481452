import React, { ReactNode, useEffect, useState } from "react";
import styles from "./InputProductView.module.scss";
import { InputProduct } from "../../../graphql/graphql";
import { ContentUnit, MultiLang, OnChangeValue } from "../../../graphql/types";
import classNames from "classnames";
import { CopyUtils } from "../../../services/copyUtils/copyUtils";
import { FileManager, FileType } from "../../../services/fileManager/FileManager";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { NoDataTable } from "../../table/none/NoDataTable";
import { IconButton, Tooltip } from "@material-ui/core";
import { Add, ArrowDownward, ArrowUpward, Close } from "@material-ui/icons";
import { SwalUtils } from "../../../services/swal/SwalUtils";

export const InputProductView = (props: {
    langCodeList: string[];
    value: InputProduct;
    onChange: OnChangeValue<InputProduct>;
}) => {
    const [contDiv, setContDiv] = useState<HTMLDivElement | null>();
    const [colWidth, setColWidth] = useState(0);
    const colLabelWidth = 200;

    useEffect(() => {
        if (contDiv) {
            const width = (contDiv.offsetWidth - colLabelWidth) / props.langCodeList.length;
            setColWidth(width);
        }
    }, [contDiv, props.langCodeList.length]);

    const handler = {
        onClickAddImposeEffect: () => {
            const copy = CopyUtils.copyAll(props.value);
            copy.imposeEffect.push({
                title: {},
                content: {},
            });
            props.onChange(copy);
        },
        onClickAddReference: () => {
            const copy = CopyUtils.copyAll(props.value);
            copy.referenceLogo.push("");
            props.onChange(copy);
        },
    };

    return (
        <div ref={setContDiv}>
            <TableTitle>상단</TableTitle>
            <TableLangCode
                label={""}
                labelWidth={colLabelWidth}
                colWidth={colWidth}
                langCodeList={props.langCodeList}
            />

            <TableMultiInput
                label={"상품명"}
                langCodeList={props.langCodeList}
                value={props.value.header}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.header = v;
                    props.onChange(copy);
                }}
            />

            <TableMultiInput
                label={"부 상품명"}
                langCodeList={props.langCodeList}
                value={props.value.subHeader}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.subHeader = v;
                    props.onChange(copy);
                }}
            />

            <TableMultiInput
                label={"홈페이지"}
                langCodeList={props.langCodeList}
                value={props.value.homepage}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.homepage = v;
                    props.onChange(copy);
                }}
            />

            <TableFileInput
                label={"브로셔 파일"}
                langCodeList={props.langCodeList}
                value={props.value.brochureLink}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                fileType={FileType.ANY}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.brochureLink = v;
                    props.onChange(copy);
                }}
            />

            <TableFileInput
                label={"백그라운드 이미지"}
                langCodeList={props.langCodeList}
                value={props.value.backgroundSrc}
                fileType={FileType.IMAGE}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.backgroundSrc = v;
                    props.onChange(copy);
                }}
            />

            <TableContentUnit
                hasImgUploader
                label={"제품설명 (HTML 코드)"}
                langCodeList={props.langCodeList}
                value={props.value.mainContent}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.mainContent = v;
                    props.onChange(copy);
                }}
            />
            <TableBottom />

            <TitleWithAddButton
                onClickAddButton={handler.onClickAddImposeEffect}
                toolTip={"도입효과 추가"}>
                도입효과
            </TitleWithAddButton>
            {props.value.imposeEffect.length === 0 && <NoDataTable />}
            {props.value.imposeEffect.map((content, index) => (
                <TableContentUnit
                    hasImgUploader
                    key={index}
                    label={
                        <ContentUnitListLabel
                            index={index}
                            value={props.value.imposeEffect}
                            onChangeValue={v =>
                                props.onChange({
                                    ...props.value,
                                    imposeEffect: v,
                                })
                            }
                        />
                    }
                    langCodeList={props.langCodeList}
                    value={content}
                    colWidth={colWidth}
                    labelWidth={colLabelWidth}
                    onChangeValue={v => {
                        const copy = CopyUtils.copyAll(props.value);
                        copy.imposeEffect[index] = v;
                        props.onChange(copy);
                    }}
                />
            ))}
            <TableBottom />

            <TableTitle>제품설명 2</TableTitle>
            <TableContentUnit
                hasImgUploader
                label={"제품설명2 (HTML 코드)"}
                langCodeList={props.langCodeList}
                value={props.value.subContent || { title: {}, content: {} }}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.subContent = v;
                    props.onChange(copy);
                }}
            />
            <TableBottom />

            <TableTitle>기술</TableTitle>
            <TableContentUnit
                hasImgUploader
                label={"기술 (HTML 코드)"}
                langCodeList={props.langCodeList}
                value={props.value.descContent || { title: {}, content: {} }}
                colWidth={colWidth}
                labelWidth={colLabelWidth}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.descContent = v;
                    props.onChange(copy);
                }}
            />
            <TableBottom />

            <TitleWithAddButton onClickAddButton={handler.onClickAddReference} toolTip={"추가"}>
                레퍼런스 이미지
            </TitleWithAddButton>
            <TableReference
                value={props.value.referenceLogo}
                onChangeValue={v => props.onChange({ ...props.value, referenceLogo: v })}
                labelWidth={colLabelWidth}
            />
            <TableBottom />
        </div>
    );
};

const TableTitle = (props: { children: ReactNode }) => {
    return (
        <>
            <h5>{props.children}</h5>
        </>
    );
};

export const TitleWithAddButton = (props: {
    children: ReactNode;
    toolTip: string;
    onClickAddButton: VoidFunction;
}) => {
    return (
        <TableTitle>
            <div className={styles.contTitle}>
                <div className={styles.title}>{props.children}</div>
                <div className={styles.boxIcon}>
                    <Tooltip title={props.toolTip}>
                        <IconButton onClick={props.onClickAddButton} size="small">
                            <Add />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </TableTitle>
    );
};

const TableLangCode = (props: {
    label: ReactNode;
    labelWidth: number;
    colWidth: number;
    langCodeList: string[];
}) => {
    return (
        <div className={classNames(styles.cont, styles.borderTop)}>
            <div
                style={{ width: props.labelWidth }}
                className={classNames(styles.label, styles.borderRight)}>
                {props.label}
            </div>
            {props.langCodeList.map((langCode, index) => (
                <div
                    key={index}
                    style={{ width: props.colWidth }}
                    className={classNames(
                        styles.col,
                        styles.fontBold,
                        props.langCodeList.length - 1 !== index ? styles.borderRight : "",
                    )}>
                    {langCode === "en" && "영어"}
                    {langCode === "ko" && "한국어"}
                </div>
            ))}
        </div>
    );
};

const TableMultiInput = (props: {
    label: ReactNode;
    langCodeList: string[];
    value: MultiLang;
    onChangeValue: OnChangeValue<MultiLang>;
    labelWidth: number;
    colWidth: number;
}) => {
    return (
        <div className={classNames(styles.cont, styles.borderTop)}>
            <div
                className={classNames(styles.label, styles.borderRight)}
                style={{ width: props.labelWidth }}>
                {props.label}
            </div>
            {props.langCodeList.map((langCode, index) => (
                <div
                    key={index}
                    style={{ width: props.colWidth }}
                    className={classNames(
                        styles.col,
                        props.langCodeList.length - 1 !== index ? styles.borderRight : "",
                    )}>
                    <input
                        value={props.value.hasOwnProperty(langCode) ? props.value[langCode] : ""}
                        onChange={ev =>
                            props.onChangeValue({ ...props.value, [langCode]: ev.target.value })
                        }
                        className="form-control"
                    />
                </div>
            ))}
        </div>
    );
};

const TableContentUnit = (props: {
    label: ReactNode;
    langCodeList: string[];
    value: ContentUnit;
    onChangeValue: OnChangeValue<ContentUnit>;
    labelWidth: number;
    colWidth: number;
    hasImgUploader?: boolean;
}) => {
    const handler = {
        onUploadImage: (langCode: string) => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(filePath => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.content[langCode] =
                        (copy.content[langCode] || "") + `<img src="${filePath}" alt="img"/>`;
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <div className={classNames(styles.cont, styles.borderTop)}>
            <div
                className={classNames(styles.label, styles.borderRight)}
                style={{ width: props.labelWidth }}>
                {props.label}
            </div>
            {props.langCodeList.map((langCode, index) => (
                <div
                    key={index}
                    style={{ width: props.colWidth }}
                    className={classNames(
                        styles.col,
                        styles.flexWrap,
                        props.langCodeList.length - 1 !== index ? styles.borderRight : "",
                    )}>
                    <input
                        placeholder="제목"
                        value={
                            props.value.title.hasOwnProperty(langCode)
                                ? props.value.title[langCode]
                                : ""
                        }
                        onChange={ev => {
                            const copy = CopyUtils.copyAll(props.value);
                            copy.title[langCode] = ev.target.value;
                            props.onChangeValue(copy);
                        }}
                        className="form-control"
                    />

                    <textarea
                        placeholder="내용"
                        value={
                            props.value.content.hasOwnProperty(langCode)
                                ? props.value.content[langCode]
                                : ""
                        }
                        onChange={ev => {
                            const copy = CopyUtils.copyAll(props.value);
                            copy.content[langCode] = ev.target.value;
                            props.onChangeValue(copy);
                        }}
                        className="form-control"
                    />

                    {props.hasImgUploader && (
                        <div
                            className="text-right"
                            style={{ marginTop: 10, width: "100%", textAlign: "right" }}>
                            <button
                                onClick={() => handler.onUploadImage(langCode)}
                                className="btn btn-sm btn-outline-secondary">
                                이미지 업로드
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const TableFileInput = (props: {
    label: ReactNode;
    langCodeList: string[];
    value: MultiLang;
    onChangeValue: OnChangeValue<MultiLang>;
    labelWidth: number;
    colWidth: number;
    fileType: FileType;
}) => {
    const handler = {
        onUploadFile: (langCode: string) => {
            FileManager.uploadFile(props.fileType)
                .then(fileSrc => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy[langCode] = fileSrc;
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
        onClickDownload: (src: string) => {
            FileManager.downloadFile(src);
        },
    };

    return (
        <div className={classNames(styles.cont, styles.borderTop)}>
            <div
                className={classNames(styles.label, styles.borderRight)}
                style={{ width: props.labelWidth }}>
                {props.label}
            </div>
            {props.langCodeList.map((langCode, index) => (
                <div
                    key={index}
                    style={{ width: props.colWidth }}
                    className={classNames(
                        styles.col,
                        props.langCodeList.length - 1 !== index ? styles.borderRight : "",
                    )}>
                    <div className={classNames(styles.divFileNm, "form-control form-control-sm")}>
                        <div>
                            {props.value.hasOwnProperty(langCode)
                                ? props.value[langCode]
                                : "파일을 업로드 하여 주십시오."}
                        </div>
                    </div>
                    <div className={styles.divBtn}>
                        <button
                            onClick={() => handler.onUploadFile(langCode)}
                            className="btn btn-sm btn-outline-secondary">
                            업로드
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

const ContentUnitListLabel = (props: {
    index: number;
    value: ContentUnit[];
    onChangeValue: OnChangeValue<ContentUnit[]>;
}) => {
    const handler = {
        onClickUp: (index: number) => {
            if (index === 0) {
                SwalUtils.ok({
                    msg: "최 상단 입니다.",
                    icon: "error",
                });
                return;
            }

            const copy = CopyUtils.copyAll(props.value);
            const prev = copy[index - 1];
            copy[index - 1] = copy[index];
            copy[index] = prev;
            props.onChangeValue(copy);
        },
        onClickDown: (index: number) => {
            if (index === props.value.length - 1) {
                SwalUtils.ok({
                    msg: "최 하단 입니다.",
                    icon: "error",
                });
                return;
            }

            const copy = CopyUtils.copyAll(props.value);
            const prev = copy[index + 1];
            copy[index + 1] = copy[index];
            copy[index] = prev;
            props.onChangeValue(copy);
        },
        onClickDelete: (index: number) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    let copy = CopyUtils.copyAll(props.value);
                    copy = [...copy.slice(0, index), ...copy.slice(index + 1, copy.length)];
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <div>
            <div>
                <Tooltip title={"위로"}>
                    <IconButton onClick={() => handler.onClickUp(props.index)}>
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>

                <Tooltip title={"아래로"}>
                    <IconButton onClick={() => handler.onClickDown(props.index)}>
                        <ArrowDownward />
                    </IconButton>
                </Tooltip>

                <Tooltip title={"삭제"}>
                    <IconButton onClick={() => handler.onClickDelete(props.index)}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

const TableReference = (props: {
    value: string[];
    onChangeValue: OnChangeValue<string[]>;
    labelWidth: number;
}) => {
    const handler = {
        onUploadFile: (index: number) => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(fileSrc => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy[index] = fileSrc;
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
        onClickUp: (index: number) => {
            if (index === 0) {
                SwalUtils.ok({
                    msg: "최 상단 입니다.",
                    icon: "error",
                });
                return;
            }

            const copy = CopyUtils.copyAll(props.value);
            const prev = copy[index - 1];
            copy[index - 1] = copy[index];
            copy[index] = prev;
            props.onChangeValue(copy);
        },
        onClickDown: (index: number) => {
            if (index === props.value.length - 1) {
                SwalUtils.ok({
                    msg: "최 하단 입니다.",
                    icon: "error",
                });
                return;
            }

            const copy = CopyUtils.copyAll(props.value);
            const prev = copy[index + 1];
            copy[index + 1] = copy[index];
            copy[index] = prev;
            props.onChangeValue(copy);
        },
        onClickDelete: (index: number) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    let copy = CopyUtils.copyAll(props.value);
                    copy = [...copy.slice(0, index), ...copy.slice(index + 1, copy.length)];
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            {props.value.length === 0 && <NoDataTable />}
            {props.value.map((fileSrc, index) => (
                <div key={index} className={classNames(styles.cont, styles.borderTop)}>
                    <div
                        className={classNames(styles.label, styles.borderRight)}
                        style={{ width: props.labelWidth }}>
                        <IconButton onClick={() => handler.onClickUp(index)}>
                            <ArrowUpward />
                        </IconButton>

                        <IconButton onClick={() => handler.onClickDown(index)}>
                            <ArrowDownward />
                        </IconButton>

                        <IconButton onClick={() => handler.onClickDelete(index)}>
                            <Close />
                        </IconButton>
                    </div>
                    <div key={index} style={{ flexGrow: 1 }} className={classNames(styles.col)}>
                        <div className={styles.divImg}>
                            {fileSrc && <img src={fileSrc} alt="logo" />}
                        </div>
                        <div
                            className={classNames(
                                styles.divFileNm,
                                "form-control form-control-sm",
                            )}>
                            <div>{fileSrc ? fileSrc : "파일을 업로드 하여 주십시오."}</div>
                        </div>
                        <div className={styles.divBtn}>
                            <button
                                onClick={() => handler.onUploadFile(index)}
                                className="btn btn-sm btn-outline-secondary">
                                업로드
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};


const TableBottom = () => {
    return (
        <>
            <div className={styles.borderTop}/>
            <div style={{height: 40}}/>
        </>
    )
}
