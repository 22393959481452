import React, { ReactNode } from "react";
import styles from "./DefaultModal.module.scss";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export const DefaultModal = (props: {
    title: ReactNode;
    children: ReactNode;
    onClose: VoidFunction;
    open: boolean;
}) => {
    return (
        <>
            {props.open && (
                <div className={styles.maximum}>
                <div className={styles.cont}>
                    <div className={styles.box}>
                        <div className={styles.header}>
                            <h5>{props.title}</h5>
                            <IconButton onClick={props.onClose} size="small">
                                <Close />
                            </IconButton>
                        </div>
                        <div className={styles.contents}>{props.children}</div>
                    </div>
                </div>
                </div>
            )}
        </>
    );
};
