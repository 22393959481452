import React from "react";
import { NewsType } from "../../../graphql/graphql";

export const BadgeNewsType = (props: { value: NewsType }) => {
    return (
        <>
            {props.value === NewsType.Escare && "에스케어"}
            {props.value === NewsType.Product && "상품"}
            {props.value === NewsType.Letter && "뉴스레터"}
        </>
    );
};
