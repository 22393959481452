import React from "react";
import styles from "./IndexClients.module.scss";
import {I18nValue} from "../../../components/system/i18nValue/I18nValue";
import {MultiLang} from "../../../graphql/types";
import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars, ViewMode} from "../../../globalVars/globalVars";

export const IndexClients = () => {
    const viewMode = useReactiveVar(GlobalVars.window.viewMode);

    let settings1: Settings;
    if (viewMode === ViewMode.Mobile) {
        settings1 = {
            className: "slider",
            centerMode: true,
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            variableWidth: true,
            pauseOnHover: true,
            slidesPerRow: 1,
            rows: 2
        };
    } else {
        settings1 = {
            className: "slider",
            centerMode: true,
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            variableWidth: true,
            pauseOnHover: true,
            slidesPerRow: 1,
            rows: 2
        };
    }

    return (
        <>
            <div className={styles.cont}>
                <div className={styles.box}>
                    <div className={styles.info}>
                        <div className={styles.header}>
                            <I18nValue value={data.header}/>
                        </div>
                        <div className={styles.logoSlider}>
                            <Slider {...settings1}>
                                {referenceData.map((value, index) => (
                                    <div className={styles.imageBox}>
                                        <div className={styles.what}>
                                            <img
                                                key={index}
                                                src={value.image}
                                                alt={value.image}
                                                className={styles.image}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const data: {
    imgSrc: string;
    header: MultiLang;
} = {
    imgSrc: "/assets/images/index/clients_background.jpg",
    header: {
        ko: "레퍼런스",
        en: "Reference",
    },
};

const referenceData = [
    {
        image: "/assets/images/index/reference_kakaobank.png",
        width: 245
    },
    {
        image: "/assets/images/index/reference_uribank.png",
        width: 230
    },
    {
        image: "/assets/images/index/reference_kbsecurities.png",
        width: 245,
    },
    {
        image: "/assets/images/index/reference_toss.png",
        width: 210
    },
    {
        image: "/assets/images/index/reference_bithumb.png",
        width: 215
    },
    {
        image: "/assets/images/index/reference_ibksecurities.png",
        width: 265
    },
    {
        image: "/assets/images/index/reference_kbank.png",
        width: 190
    },
    {
        image: "/assets/images/index/reference_samsung.png",
        width: 315
    },
    {
        image: "/assets/images/index/reference_kakaobank.png",
        width: 245
    },
    {
        image: "/assets/images/index/reference_hyundai.png",
        width: 340
    },
    {
        image: "/assets/images/index/reference_skhynix.png",
        width: 140
    },
    {
        image: "/assets/images/index/reference_coupang.png",
        width: 235
    },
    {
        image: "/assets/images/index/reference_celltrion.png",
        width: 210
    },
    {
        image: "/assets/images/index/reference_gscaltex.png",
        width: 200,
    }
];
