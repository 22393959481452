import React, { useState } from "react";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
    InputNews,
    Mutation,
    MutationCreateNewsArgs,
    MutationRemoveNewsArgs,
    MutationUpdateNewsArgs,
    NewsType,
    Query,
    QueryNewsListArgs,
} from "../../../graphql/graphql";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import { useParams } from "react-router-dom";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { DashboardNewsList } from "./list/DashboardNewsList";
import { Pager } from "../../../components/pagination/Pager";
import { TableBtnView } from "../../../components/table/btn/TableBtnView";
import { DefaultModal } from "../../../components/modal/default/DefaultModal";
import { InputNewsView } from "./inputNews/InputNewsView";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { BizValidator } from "../../../services/validator/BizValidator";
import { GlobalVars } from "../../../globalVars/globalVars";
import { Pipe } from "../../../services/pipe/Pipe";

export const DashboardNews = () => {
    const { page, size, newsType } = useParams();
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const { data, refetch } = useQuery<Query, QueryNewsListArgs>(gqlData, {
        variables: {
            page: Number(page || 0),
            size: Number(size || 20),
            options: {
                langCode,
                newsType: Pipe.toNewsType(newsType),
            },
        },
    });

    const [createNews, setCreateNews] = useState<InputNews | undefined>();
    const [updateNews, setUpdateNews] = useState<InputUpdateNews | undefined>();

    const [mutCreateNews] = useMutation<Mutation, MutationCreateNewsArgs>(gqlCreate);
    const [mutUpdateNews] = useMutation<Mutation, MutationUpdateNewsArgs>(gqlUpdate);
    const [mutRemoveNews] = useMutation<Mutation, MutationRemoveNewsArgs>(gqlRemove);

    const handler = {
        onChangePage: (p: number) => {
            // queryParam.set("page", `${p}`);
            // const langCode = queryParam.get("langCode") || undefined;
            // refetch({
            //     page: p,
            //     size: 20,
            //     options: {
            //         langCode
            //     }
            // }).catch(ApolloCatch({}));
        },
        onClickCreateNews: () => {
            setCreateNews({
                newsType: NewsType.Escare,
                badge: {},
                contents: "",
                imgSrc: "",
                langCode: "ko",
                newsLabel: "",
                title: "",
            });
        },
        onClickUpdateNews: (value: InputUpdateNews) => {
            console.log(value.id);
            setUpdateNews(value);
        },
        onClickRemoveNews: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return mutRemoveNews({
                        variables: { id },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        onClickUploadCreateNews: () => {
            if (!createNews) {
                return;
            }

            if (!handler.validateNews(createNews)) {
                return;
            }

            mutCreateNews({
                variables: {
                    input: createNews,
                },
            })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "등록 되었습니다.",
                        icon: "success",
                    });
                    setCreateNews(undefined);
                })
                .catch(ApolloCatch({}));
        },
        onClickUploadUpdateNews: () => {
            if (!updateNews) {
                return;
            }

            if (!handler.validateNews(updateNews.input)) {
                return;
            }

            SwalUtils.ynWithPromise({
                msg: "수정 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdateNews({
                        variables: updateNews,
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "수정되었습니다.",
                        icon: "success",
                    });
                    setUpdateNews(updateNews);
                })
                .catch(ApolloCatch({}));
        },
        validateNews: (value: InputNews): boolean => {
            if (!value.imgSrc) {
                SwalUtils.ok({
                    msg: "이미지를 추가하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            if (!value.contents) {
                SwalUtils.ok({
                    msg: "내용을 추가하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            if (!value.imgSrc) {
                SwalUtils.ok({
                    msg: "이미지를 추가하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            if (!value.langCode) {
                SwalUtils.ok({
                    msg: "언어를 선택하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            if (!value.title) {
                SwalUtils.ok({
                    msg: "제목을 추가하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.badge)) {
                SwalUtils.ok({
                    msg: "뱃지의 내용을 추가하여 주십시오",
                    icon: "error",
                });
                return false;
            }

            return true;
        },
    };

    return (
        <DashboardLayout router={"/dashboard/news"}>
            {data && (
                <>
                    <h4>뉴스</h4>
                    <p>* 메인 페이지에는 언어별, 최신 3건이 표시됩니다.</p>
                    <DashboardNewsList {...handler} value={data.newsList.list} />
                    <TableBtnView>
                        <button
                            onClick={handler.onClickCreateNews}
                            className="btn btn-sm btn-primary">
                            추가
                        </button>
                    </TableBtnView>
                    <Pager
                        page={data.newsList.page}
                        size={data.newsList.size}
                        total={data.newsList.total}
                        onChange={handler.onChangePage}
                    />
                </>
            )}

            <DefaultModal
                title={"뉴스 추가"}
                onClose={() => setCreateNews(undefined)}
                open={Boolean(createNews)}>
                {createNews && (
                    <>
                        <InputNewsView value={createNews} onChangeValue={setCreateNews} />
                        <TableBtnView>
                            <button
                                onClick={handler.onClickUploadCreateNews}
                                className="btn btn-sm btn-primary">
                                추가
                            </button>
                        </TableBtnView>
                    </>
                )}
            </DefaultModal>

            <DefaultModal
                title={"뉴스 수정"}
                onClose={() => setUpdateNews(undefined)}
                open={Boolean(updateNews)}>
                {updateNews && (
                    <>
                        <InputNewsView
                            value={updateNews.input}
                            onChangeValue={input => setUpdateNews({ ...updateNews, input })}
                        />
                        <TableBtnView>
                            <button
                                onClick={handler.onClickUploadUpdateNews}
                                className="btn btn-sm btn-primary">
                                수정
                            </button>
                        </TableBtnView>
                    </>
                )}
            </DefaultModal>
        </DashboardLayout>
    );
};

export interface InputUpdateNews {
    id: string;
    input: InputNews;
}

const gqlData = gql`
    query News($page: Int!, $size: Int!, $options: NewsFindOptions!) {
        newsList(page: $page, size: $size, options: $options) {
            page
            size
            total
            list {
                id
                langCode
                badge
                imgSrc
                title
                contents
                newsLabel
                newsType
                createdAt
            }
        }
    }
`;

const gqlCreate = gql`
    mutation CreateNews($input: InputNews!) {
        createNews(input: $input)
    }
`;

const gqlUpdate = gql`
    mutation UpdateNews($id: String!, $input: InputNews!) {
        updateNews(id: $id, input: $input)
    }
`;

const gqlRemove = gql`
    mutation RemoveNews($id: String!) {
        removeNews(id: $id)
    }
`;
