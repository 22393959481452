import React from "react";
import { ImgPageHeader } from "../../../components/header/imgPageHeader/ImgPageHeader";
import imgBackground from "../../../assets/img/intelligent/header.jpg";
import { GlobalVars } from "../../../globalVars/globalVars";
import { useReactiveVar } from "@apollo/client";

interface TextListType {
    title: string;
}

export const IntelligentHeader = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: TextListType = {
        title: "",
    };

    if (langCode === "en") {
        textList = {
            title: "",
        };
    } else {
        textList = {
            title: "지능형 관제 서비스",
        };
    }

    return (
        <ImgPageHeader background={<img src={imgBackground} alt={""} />}>
            <>
                <h1 style={{ textShadow: "0 0 14px #000000" }}>{textList.title}</h1>
            </>
        </ImgPageHeader>
    );
};
