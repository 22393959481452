import React, { ReactNode } from "react";
import { DirectNoticeInput } from "../../../../graphql/graphql";
import { OnChangeValue } from "../../../../graphql/types";
import { FileManager, FileType } from "../../../../services/fileManager/FileManager";
import { ApolloCatch } from "../../../../services/apollo/apolloCatch";
import { CopyUtils } from "../../../../services/copyUtils/copyUtils";
import styles from "./InputDirectNoticeView.module.scss";
import { Checkbox } from "@material-ui/core";

export const InputDirectNoticeView = (props: {
    value: DirectNoticeInput;
    onChangeValue: OnChangeValue<DirectNoticeInput>;
}) => {
    return (
        <>
            <div className={styles.cont}>
                <div className={styles.label}>활성화</div>
                <div className={styles.input}>
                    <Checkbox
                        color="primary"
                        checked={props.value.urgent}
                        onChange={() => {
                            const copy = CopyUtils.copyAll(props.value);
                            copy.urgent = !props.value.urgent;
                            props.onChangeValue(copy);
                        }}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </div>
            </div>
            <TableContentUnit
                hasImgUploader
                label={"팝업 내용"}
                value={props.value.content}
                onChangeValue={v => {
                    const copy = CopyUtils.copyAll(props.value);
                    copy.content = v;
                    props.onChangeValue(copy);
                }}
            />
        </>
    );
};

const TableContentUnit = (props: {
    label: ReactNode;
    value: string;
    onChangeValue: OnChangeValue<string>;
    hasImgUploader?: boolean;
}) => {
    const handler = {
        onUploadImage: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(filePath => {
                    let copy = CopyUtils.copyAll(props.value);
                    copy = (copy || "") + `<img src="${filePath}" alt="img"/>`;
                    props.onChangeValue(copy);
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <div className={styles.cont}>
            <div className={styles.label}>{props.label}</div>
            <div className={styles.content}>
                <textarea
                    placeholder="내용"
                    value={props.value}
                    onChange={ev => {
                        const copy: string = ev.target.value;
                        props.onChangeValue(copy);
                    }}
                    className="form-control"
                />

                {props.hasImgUploader && (
                    <div
                        className="text-right"
                        style={{ marginTop: 10, width: "100%", textAlign: "right" }}>
                        <button
                            onClick={() => handler.onUploadImage()}
                            className="btn btn-sm btn-outline-secondary">
                            이미지 업로드
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
