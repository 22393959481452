import React, { useState } from "react";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import { InputProduct, Mutation, MutationCreateProductArgs } from "../../../graphql/graphql";
import { InputProductView } from "../../../components/input/inputProductView/InputProductView";
import { TableBtnView } from "../../../components/table/btn/TableBtnView";
import { gql, useMutation } from "@apollo/client";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { CopyUtils } from "../../../services/copyUtils/copyUtils";
import { BizValidator } from "../../../services/validator/BizValidator";

export const DashboardCreateProductPage = () => {
    const [input, setInput] = useState<InputProduct>(CopyUtils.copyAll(InitInputProduct));

    const [mutCreateProduct] = useMutation<Mutation, MutationCreateProductArgs>(gqlCreateProduct);

    const handler = {
        onCreateProduct: () => {
            if (!BizValidator.checkInputProduct(input)) {
                return;
            }

            SwalUtils.ynWithPromise({
                msg: "등록 하시겠습니까?",
            })
                .then(() => {
                    return mutCreateProduct({
                        variables: {
                            input,
                        },
                    });
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "등록 되었습니다.",
                        icon: "success",
                    });
                    setInput(CopyUtils.copyAll(InitInputProduct));
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <DashboardLayout router="/dashboard/product/create">
            <h4>제품 등록</h4>
            <div style={{ height: 20 }} />
            <InputProductView langCodeList={["ko", "en"]} value={input} onChange={setInput} />

            <TableBtnView>
                <button
                    onClick={handler.onCreateProduct}
                    className="btn btn-sm btn-outline-secondary">
                    등록
                </button>
            </TableBtnView>
        </DashboardLayout>
    );
};

export const InitInputProduct: InputProduct = {
    isActivate: true,
    header: {},
    subHeader: {},
    brochureLink: {
        en: "",
        ko: "",
    },
    mainContent: {
        title: {},
        content: {},
    },
    imposeEffect: [],
    referenceLogo: [],
    backgroundSrc: {},
    homepage: {
        en: "",
        ko: "",
    },
    subContent: {
        title: {},
        content: {},
    },
    descContent: {
        title: {
            en: "",
            ko: "",
        },
        content: {
            en: "",
            ko: "",
        },
    },
};

const InitInputProductForDummy: InputProduct = {
    isActivate: true,
    header: {
        en: "English",
        ko: "한국어",
    },
    subHeader: {
        en: "English",
        ko: "한국어",
    },
    brochureLink: {
        en: "http://localhost:4000/statics/d8012291-cbe8-4614-8a23-1634d72cc83b/220215-01.jpg",
        ko: "http://localhost:4000/statics/abbf6706-0e2c-439b-9e09-2041a30112d8/220219-05.jpg",
    },
    mainContent: {
        title: {
            en: "English",
            ko: "한국어",
        },
        content: {
            en: "English",
            ko: "한국어",
        },
    },
    imposeEffect: [
        {
            title: {
                en: "English",
                ko: "한국어",
            },
            content: {
                en: "English",
                ko: "한국어",
            },
        },
    ],
    referenceLogo: [
        "http://localhost:4000/statics/abbf6706-0e2c-439b-9e09-2041a30112d8/220219-05.jpg",
    ],
    backgroundSrc: {
        en: "http://localhost:4000/statics/d8012291-cbe8-4614-8a23-1634d72cc83b/220215-01.jpg",
        ko: "http://localhost:4000/statics/abbf6706-0e2c-439b-9e09-2041a30112d8/220219-05.jpg",
    },
    homepage: {
        en: "",
        ko: "",
    },
    subContent: {
        title: {
            en: "English",
            ko: "한국어",
        },
        content: {
            en: "English",
            ko: "한국어",
        },
    },
    descContent: {
        title: {
            en: "English",
            ko: "한국어",
        },
        content: {
            en: "English",
            ko: "한국어",
        },
    },
};

const gqlCreateProduct = gql`
    mutation CreateProduct($input: InputProduct!) {
        createProduct(input: $input)
    }
`;
