import React from "react";
import styles from "./FindResultWord.module.scss";

export const FindResultWord = (props: { findWord: string }) => {
    return (
        <div className={styles.cont}>
            <span>{props.findWord}</span> 에 대한 검색 결과 입니다.
        </div>
    );
};
