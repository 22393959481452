import React from "react";
import styles from "./IndexInfoBarList.module.scss";
import classNames from "classnames";
import { IndexInfoBar } from "../../../../../graphql/graphql";
import { NoDataTable } from "../../../../../components/table/none/NoDataTable";
import { I18nValue } from "../../../../../components/system/i18nValue/I18nValue";
import { OnChangeValue } from "../../../../../graphql/types";
import { InputUpdateIndexInfoBar } from "../../DashboardMainPage";
import { IconButton } from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";

export const IndexInfoBarList = (props: {
    data: IndexInfoBar[];
    OnOpenUpdateIndexInfoBar: OnChangeValue<InputUpdateIndexInfoBar>;
    onClickDeleteIndexInfoBar: OnChangeValue<string>
}) => {
    return (
        <>
            <div className={classNames(styles.table, styles.tableList)}>
                <div className={classNames(styles.col, styles.colBgUrl)}>배경이미지</div>
                <div className={classNames(styles.col, styles.colIconUrl)}>아이콘이미지</div>
                <div className={classNames(styles.col, styles.colTitle)}>타이틀</div>
                <div className={classNames(styles.col, styles.colOrder)}>순서</div>
                <div className={classNames(styles.col, styles.colFunction)}>기능</div>
            </div>

            {props.data.length === 0 && <NoDataTable />}

            {props.data.map((value, index) => (
                <div key={index} className={classNames(styles.table, styles.tableList)}>
                    <div className={classNames(styles.col, styles.colBgUrl)}>
                        <img src={value.bgUrl} alt="img" />
                    </div>
                    <div className={classNames(styles.col, styles.colIconUrl)}>
                        <img src={value.iconUrl} alt="img" />
                    </div>
                    <div className={classNames(styles.col, styles.colTitle)}>
                        <I18nValue value={value.title} />
                    </div>
                    <div className={classNames(styles.col, styles.colOrder)}>{value.order}</div>
                    <div className={classNames(styles.col, styles.colFunction)}>
                        <IconButton
                            onClick={() =>
                                props.OnOpenUpdateIndexInfoBar({
                                    id: value.id,
                                    input: {
                                        ...value,
                                    },
                                })
                            }>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => props.onClickDeleteIndexInfoBar(value.id)}>
                            <Delete />
                        </IconButton>
                    </div>
                </div>
            ))}
        </>
    );
};
