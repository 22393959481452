import React from "react";
import styles from "./OverviewPage.module.scss";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";

interface TextListType {
    [key: string]: {
        [key: string]: string;
    };
}

export const OverviewPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: TextListType = {
        title: {
            title: "OVERVIEW"
        },
        introduction: {
            title: "",
            description: "",
        },
        business: {
            title: "",
            consulting: "Consulting",
            consultingDescription: "",
            development: "Development",
            developmentDescription: "",
            maintenance: "Maintenance",
            maintenanceDescription: "",
        },
        growth: {
            title: "",
            description1: "",
            description2: "",
            description3: "",
            result: "",
        },
        organization: {
            title: "ORGANIZATION",
            organization: "",
        },
        result: {
            title: "",
            description: "",
            total: "",
            tech: "",
            sales: "",
            admin: "",
        }
    };

    if (langCode === "en") {
        textList = {
            title: {
              title: "OVERVIEW"
            },
            introduction: {
                title: "INTRODUCTION",
                description: `ESCARE is a customer-centered security/certification company based on technology and research and development.<br /><br />We build and consult security/authentication solutions to respond to rapidly changing external threats and information leakage incidents, and provide security product development and operational services, especially for financial customers to build a differentiated, integrated security infrastructure.<br /><br />We provided Korea Development Bank, Woori Bank, Standard Chartered Bank, Kakao Bank, K Bank, KB Life Insurance, Prudential Life Insurance, LINA Life Insurance, EUGENE Investment, KTB Investment, KB Securities, Standard Chartered Securities, Hyundai Card, Woori Card, etc. with specialized security solutions and services.<br /><br />In particular, the ESCARE's "PowerPack" solution in 2013 provided management solutions to ensure integrated security and security of corporate business data, and was recognized for its technology by many financial customers. And with continued growth, we've introduced a new Zero Trust VPN (ZT-VPN) security solution for telecommuting security. At the endpoint security center, we commercialize the latest global TOP solutions such as network security, cloud security, infrastructure security, authentication (HMS/KMS), and EPP/EDR (Fortinet, SentinelOne, CyberArk, Fortanix). Through this, we are expanding our position as a leading security professional.<br /><br />ESCARE leads the best deployment methodology for customer security stability to become a global leader.`,
            },
            business: {
                title: `BUSINESS<br />STRATEGY`,
                consulting: "Consulting",
                consultingIcon: "business_icon1.png",
                consultingDescription1: "01. Korea's leading consulting firm specializing in information security",
                consultingDescription2: `-Establishing and consulting security solutions to respond to rapidly changing external attacks and information leakage incidents<br />-Development and supply of ESCARE's own solutions to support Korea's customer requirements, which are the limitations of foreign solutions`,
                development: "Development",
                developmentIcon: "business_icon2.png",
                developmentDescription1: "02. Have a number of next-generation integrated security infrastructure consulting cases",
                developmentDescription2: `-Reliable business based on years of financial security experience<br />-Management of more than 40% of large financial companies such as Woori Bank, Standard Chartered Bank, and Kakao Bank`,
                maintenance: "Maintenance",
                maintenanceIcon: "business_icon3.png",
                maintenanceDescription1: "03. Symantec's Platinum Partner, Leading the Global Information Security Industry",
                maintenanceDescription2: `-Security compliance in more than 10 countries including the United States, Japan, and China<br />-Global security partners such as Samsung Group, AmorePacific Group, and Standard Chartered Bank`,
            },
            growth: {
                title: `GROWTH<br />STRATEGY`,
                description1: "Incorporating the Global No. 1 Security Solutions",
                description2: "Developing the products fitting the country local environments\n",
                description3: "Providing a Trusted Security Infrastructure Consulting",
                result: `No. 1<br />Security Leader<br />Maximizing the Customer Satisfaction in Cyber-security market`,
            },
            organization: {
                title: "ORGANIZATION",
                organization: "organization_en.png",
            },
            result: {
                title: "People ratio%",
                description: "As of 2022.04",
                total: "Total",
                tech: "Tech",
                sales: "Sales",
                admin: "Admin",
            }
        };
    } else {
        textList = {
            title: {
                title: "회사개요"
            },
            introduction: {
                title: "에스케어<br />회사소개",
                description: `주식회사 에스케어는 테크놀로지와 연구 개발에 기반한 고객 중심의 보안/인증 전문 기업입니다.<br /><br /> 에스케어는 급변하는 외부 위협과 정보유출 사고에 대응하기 위한 보안/인증 솔루션 구축 및 컨설팅 사업을 수행하고 있으며, 특히 금융 고객 대상으로 차별화된 통합 보안 인프라 구축을 위한 보안 제품개발과 운영 서비스를 안정적으로 제공하고 있습니다.<br /><br />산업은행, 우리은행, SC제일은행, 카카오뱅크, K뱅크, 푸르덴셜 생명보험, 유진투자증권, KTB투자증권, KB증권, SC증권, 현대카드, 우리카드 등 다양한 금융 고객사에 보안 솔루션과 서비스를 공급하고 있습니다.<br /><br />특히 2013년 자체 개발한 "파워팩"을 통하여 기업 업무 자료의 통합 보안 및 보안성을 확보할 수 있는 관리 솔루션을 제공하고 있으며, 다수의 금융권 고객에 그 기술력을 인정받고 있습니다.<br /><br />지속적인 성장을 통하여 자사솔루션으로 재택근무 보안을 위한 ZT-VPN (Zero Trust VPN) 보안 솔루션을 새롭게 출시하여 레퍼런스를 확보하였고, 앤드포인트 보안 중심에서 네트워크 보안, 클라우드 보안, 인프라 보안, 인증(HMS/KMS), EPP/EDR (Fortinet, SentinelOne, CyberAck, Fortanix) 등 최신 글로벌 TOP 솔루션들을 사업화함으로써 명실상부한 보안 전문기업으로 그 위상을 확대해 나가고 있습니다.`,
            },
            business: {
                title: "에스케어<br />사업영역",
                consulting: "Consulting",
                consultingIcon: "business_icon1.png",
                consultingDescription1: "01 국내 최고의 정보보안 전문 컨설팅 기업",
                consultingDescription2: `- 급변하고 있는 외부 공격 및 정보유출사고에 대응하기 위한 보안솔루션 구축 및 컨설팅<br />- 외산 솔루션의 한계인 한국의 고객 요건을 지원하기 위하여 자사 솔루션을 개발 공급`,
                development: "Development",
                developmentIcon: "business_icon2.png",
                developmentDescription1: "02 차세대 통합 보안 인프라 구축 컨설팅 사례 다수 보유",
                developmentDescription2: `- 다년간 금융 보안 경험을 토대로 안정적인 사업을 수행<br />- 우리은행, SC은행, 카카오뱅크 등 대형 금융기업 40% 이상 관리`,
                maintenance: "Maintenance",
                maintenanceIcon: "business_icon3.png",
                maintenanceDescription1: "03 시만텍의 플래티넘 파트너사 글로벌 정보 보안 산업 선도",
                maintenanceDescription2: `- 미국, 일본, 중국 등 10개국 이상의 국가의 보안 컴플라이언스 보유<br />- 삼성그룹, 아모레퍼시픽 그룹, SC은행 등 글로벌 보안 파트너`,
            },
            growth: {
                title: `에스케어<br />사업전략`,
                description1: "Global No. 1 보안 솔루션 융합",
                description2: "Local 보안환경에 맞춘 효율적인 제품개발",
                description3: "신뢰할 수 있는 보안 인프라 컨설팅",
                result: "No. 1<br />Security Leader<br /><br />고객 보안 강화<br />보안 극대화 솔루션",
            },
            organization: {
                title: "에스케어<br />회사 조직도",
                organization: "organization_kr.png",
            },
            result: {
                title: "임직원 구성 비율",
                description: "2022.04. 기준",
                total: "전체",
                tech: "기술",
                sales: "영업",
                admin: "지원",
            }
        };
    }

    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{textList.title.title}</div>
            </div>
            <Introduction textList={textList}/>
            <Business textList={textList}/>
            <Growth textList={textList}/>
            <Organization textList={textList}/>
            <div className={styles.percentContainer}>
                <div className={styles.percentLine}>
                    <div className={styles.resultBox}>
                        <div className={styles.resultTitle}>{textList.result.title}</div>
                        <div className={styles.resultDescription}>{textList.result.description}</div>
                    </div>
                    <div className={styles.mobileBox}>
                        <div className={styles.elementBox}>
                            <div className={styles.number}>56</div>
                            <div className={styles.item}>
                                {textList.result.total}
                                <br/>
                                100%
                            </div>
                        </div>
                        <div className={styles.elementBox}>
                            <div className={styles.number}>40</div>
                            <div className={styles.item}>
                                {textList.result.tech}
                                <br/>
                                71%
                            </div>
                        </div>
                        <div className={styles.elementBox}>
                            <div className={styles.number}>12</div>
                            <div className={styles.item}>
                                {textList.result.sales}
                                <br/>
                                21%
                            </div>
                        </div>
                        <div className={styles.elementBox}>
                            <div className={styles.number}>4</div>
                            <div className={styles.item}>
                                {textList.result.admin}
                                <br/>
                                7%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const LineTitle = (props: { title: string }) => {
    return (
        <>
            <div>
                <div
                    className={styles.lineTitle}
                    dangerouslySetInnerHTML={{__html: props.title}}
                />
                <div className={styles.lineTitleBorder}/>
            </div>
        </>
    );
};

const Introduction = (props: { textList: TextListType }) => {
    return (
        <>
            <div className={styles.introductionLine}>
                <div className={styles.imgBox}/>
                <div className={styles.contentBox}>
                    <LineTitle title={props.textList.introduction.title}/>
                    <div
                        className={styles.contentDescription}
                        dangerouslySetInnerHTML={{
                            __html: props.textList.introduction.description,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const Business = (props: { textList: TextListType }) => {
    return (
        <>
            <div className={styles.businessContainer}>
                <div className={styles.businessLine}>
                    <div className={styles.titleBox}>
                        <LineTitle title={props.textList.business.title}/>
                    </div>
                    <div className={styles.contentLine}>
                        <BusinessBox
                            title={props.textList.business.consulting}
                            icon={props.textList.business.consultingIcon}
                            description1={props.textList.business.consultingDescription1}
                            description2={props.textList.business.consultingDescription2}
                        />
                        <BusinessBox
                            title={props.textList.business.development}
                            icon={props.textList.business.developmentIcon}
                            description1={props.textList.business.developmentDescription1}
                            description2={props.textList.business.developmentDescription2}
                        />
                        <BusinessBox
                            title={props.textList.business.maintenance}
                            icon={props.textList.business.maintenanceIcon}
                            description1={props.textList.business.maintenanceDescription1}
                            description2={props.textList.business.maintenanceDescription2}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const BusinessBox = (props: {
    title: string;
    icon: string;
    description1: string;
    description2: string;
}) => {
    return (
        <>
            <div className={styles.contentBox}>
                <div className={styles.leftBox}>
                    <img src={`/assets/images/overview/${props.icon}`} alt={"아이콘"}/>
                </div>
                <div className={styles.rightBox}>
                    <div className={styles.title}>{props.title}</div>
                    <div className={styles.description1}>{props.description1}</div>
                    <div
                        className={styles.description2}
                        dangerouslySetInnerHTML={{__html: props.description2}}
                    />
                </div>
            </div>
        </>
    );
};

const Growth = (props: { textList: TextListType }) => {
    return (
        <>
            <div className={styles.growthContainer}>
                <div className={styles.growthLine}>
                    <div className={styles.titleBox}>
                        <LineTitle title={props.textList.growth.title}/>
                    </div>
                    <div className={styles.descriptionLine}>
                        <GrowthBox number={1} description={props.textList.growth.description1}/>
                        <GrowthBox number={2} description={props.textList.growth.description2}/>
                        <GrowthBox number={3} description={props.textList.growth.description3}/>
                        <div className={styles.emptyBox}/>
                        <div
                            className={styles.resultBox}
                            dangerouslySetInnerHTML={{__html: props.textList.growth.result}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const GrowthBox = (props: { number: number; description: string }) => {
    return (
        <>
            <div className={styles.growthBox}>
                <div className={styles.number}>0{props.number}</div>
                <div className={styles.description}>{props.description}</div>
                <div className={styles.icon}>
                    <img
                        src={`/assets/images/overview/growth_icon${props.number}.png`}
                        height={40}
                        alt={"아이콘"}
                    />
                </div>
            </div>
        </>
    );
};

const Organization = (props: { textList: TextListType }) => {
    return (
        <>
            <div className={styles.organizationLine}>
                <div className={styles.titleBox}>
                    <LineTitle title={props.textList.organization.title}/>
                </div>
                <div className={styles.organization}>
                    <img src={`/assets/images/overview/${props.textList.organization.organization}`} alt={"조직도"}/>
                </div>
            </div>
        </>
    );
};
