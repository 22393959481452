import React, { Fragment, useState } from "react";
import { AuthLoginForm } from "./form/AuthLoginForm";
import { gql, useMutation } from "@apollo/client";
import { Mutation, MutationLoginArgs, MutationSaltArgs } from "../../../graphql/graphql";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { CryptoUtils } from "../../../services/crypoUtils/CryptoUtils";
import { LocalStorage } from "../../../services/localstorage/LocalStorage";
import { UserVarUtils } from "../../../globalVars/globalVars";
import { useNavigate } from "react-router-dom";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { BizErrors } from "../../../graphql/bizErrors";

export const AuthLoginPage = () => {
    const [state, setState] = useState<AuthLoginState>({
        id: "",
        pw: "",
    });
    const [mutGetSalt] = useMutation<Mutation, MutationSaltArgs>(gqlSalt);
    const [mutLogin] = useMutation<Mutation, MutationLoginArgs>(gqlLogin);
    const navigate = useNavigate();

    const handler = {
        onClickLogin: () => {
            if (state.id === "") {
                SwalUtils.ok({
                    msg: "아이디를 입력하여 주십시오.",
                    icon: "warning",
                });
                return;
            }

            if (state.pw === "") {
                SwalUtils.ok({
                    msg: "비밀번호를 입력하여 주십시오.",
                    icon: "warning",
                });
                return;
            }

            mutGetSalt({
                variables: {
                    username: state.id,
                },
            })
                .then(res => {
                    const salt = res.data?.salt || "";
                    return mutLogin({
                        variables: {
                            id: state.id,
                            pw: CryptoUtils.encrypt(state.pw, salt),
                        },
                    });
                })
                .then(res => {
                    const token = res.data?.login || "";
                    LocalStorage.setSession(token);
                    UserVarUtils.login(state.id);
                    navigate("/dashboard/main");
                })
                .catch(
                    ApolloCatch({
                        [BizErrors.notFoundAdmin]: () => {
                            SwalUtils.ok({
                                msg: "아이디, 비밀번호가 틀렸습니다.",
                                icon: "error",
                            });
                        },
                        [BizErrors.noHasPermission]: () => {
                            SwalUtils.ok({
                                msg: "로그인 제한 아이디 입니다.",
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <Fragment>
            <AuthLoginForm value={state} onChangeValue={setState} {...handler} />
        </Fragment>
    );
};

export interface AuthLoginState {
    id: string;
    pw: string;
}

const gqlSalt = gql`
    mutation Salt($username: String!) {
        salt(username: $username)
    }
`;

const gqlLogin = gql`
    mutation Login($id: String!, $pw: String!) {
        login(id: $id, pw: $pw)
    }
`;
