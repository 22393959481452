import React from "react";
import imgBackground from "../../../assets/img/news/header.jpg";
import { ImgPageHeader } from "../../../components/header/imgPageHeader/ImgPageHeader";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";

interface TextListType {
    [key: string]: string;
}

export const NewsHeader = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: TextListType = {
        title: "",
    };

    if (langCode === "en") {
        textList = {
            title: "News & Event",
            description: "",
        };
    } else {
        textList = {
            title: "뉴스 & Event",
            description: "에스케어의 새로운 소식 및 정보를 공유 해 드립니다.",
        };
    }

    return (
        <ImgPageHeader background={<></>}>
            <>
                <h1 style={{ color: "black" }}>{textList.title}</h1>
                <h2 style={{ color: "black" }}>{textList.description}</h2>
            </>
        </ImgPageHeader>
    );
};
