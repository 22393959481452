import React, { ReactNode, useEffect } from "react";
import { WebHeader } from "./header/WebHeader";
import { GlobalVars } from "../../../globalVars/globalVars";
import { Footer } from "./footer/Footer";
import { useReactiveVar } from "@apollo/client";
import { TopNavBar } from "../Layout";

export const LayoutWeb = (props: { children: ReactNode; topNavBar: TopNavBar[] }) => {
    const hasFooter = useReactiveVar(GlobalVars.layout.footer);

    useEffect(() => {
        return () => {
            GlobalVars.layout.header.isTransparent(false);
        };
    });

    return (
        <>
            <WebHeader topNavBar={props.topNavBar} />
            <div style={{ minHeight: "100vh" }}>{props.children}</div>
            {hasFooter && <Footer />}
        </>
    );
};
