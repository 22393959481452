import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import {
    InputNavItem,
    NavItem,
    useNavItemAllQuery,
    useNavItemCreateNavItemMutation,
    useNavItemDeleteNavItemMutation,
    useNavItemUpdateNavItemMutation,
} from "../../../graphql/graphql";
import { DashboardNavItemBox, DashboardNavItemColumn } from "./column/DashboardNavItemColumn";
import { MultiLang } from "../../../graphql/types";
import { DefaultModal } from "../../../components/modal/default/DefaultModal";
import { InputNavItemView } from "./inputNavItem/InputNavItemView";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { TableBtnView } from "../../../components/table/btn/TableBtnView";
import { BizValidator } from "../../../services/validator/BizValidator";
import { BizErrors } from "../../../graphql/bizErrors";

export const DashboardNavItemPage = () => {
    const { data, refetch } = useNavItemAllQuery();
    const [first, setFirst] = useState<INavItemColumn>(FirstColumn);
    const [second, setSecond] = useState<INavItemColumn>(SecondNoColumn);
    const [third, setThrid] = useState<INavItemColumn>(ThirdNoColumn);
    const [iCreateNavItem, setICreateNavItem] = useState<InputNavItem | undefined>();
    const [iUpdateNavItem, setIUpdateNavItem] = useState<IUpdateNavItem | undefined>();

    useEffect(() => {
        if (!data) {
            return;
        }

        setFirst({
            ...first,
            list: NavItemUtil.getNavItems(data.allNavItemList, 0),
        });

        if (second.parentId != "") {
            setSecond({
                ...second,
                list: NavItemUtil.getNavItemByParentId(data.allNavItemList, second.parentId),
            });
        }

        if (third.parentId != "") {
            setThrid({
                ...third,
                list: NavItemUtil.getNavItemByParentId(data.allNavItemList, third.parentId),
            });
        }
    }, [data]);

    const [mutCreate] = useNavItemCreateNavItemMutation();
    const [mutUpdate] = useNavItemUpdateNavItemMutation();
    const [mutDelete] = useNavItemDeleteNavItemMutation();

    const handler = {
        onClickCreate: (input: InputNavItem) => {
            setICreateNavItem(input);
        },
        onClickUpdate: (input: IUpdateNavItem) => {
            setIUpdateNavItem(input);
        },
        onClickSelect: (item: INavItemColumn) => {
            if (!data) {
                return;
            }

            switch (item.depth) {
                case 1:
                    item.list = NavItemUtil.getNavItemByParentId(
                        data.allNavItemList,
                        item.parentId,
                    );
                    setSecond(item);
                    setThrid(ThirdNoColumn);
                    break;
                case 2:
                    item.list = NavItemUtil.getNavItemByParentId(
                        data.allNavItemList,
                        item.parentId,
                    );
                    setThrid(item);
                    break;
            }
        },
        onClickDelete: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return mutDelete({ variables: { id } });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제되었습니다.",
                        icon: "success",
                    });
                })
                .catch(
                    ApolloCatch({
                        [BizErrors.forbiddenRemoveNavItemWithChildren]: () => {
                            SwalUtils.ok({
                                msg: "하위 아이템이 존재합니다. 하위 아이템을 삭제하시고 다시 해주세요.",
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onCreateNavItem: (input: InputNavItem) => {
            if (!NavItemValidate.create(input)) {
                SwalUtils.ok({
                    msg: "데이터를 확인하여 주십시오.",
                    icon: "warning",
                });
                return;
            }

            mutCreate({
                variables: { input },
            })
                .then(() => {
                    setICreateNavItem(undefined);
                    return refetch();
                })
                .catch(ApolloCatch({}));
        },
        onUpdateNavItem: (input: IUpdateNavItem) => {
            if (!NavItemValidate.create(input.input)) {
                SwalUtils.ok({
                    msg: "데이터를 확인하여 주십시오.",
                    icon: "warning",
                });
                return;
            }

            mutUpdate({
                variables: input,
            })
                .then(() => {
                    setIUpdateNavItem(undefined);
                    return refetch();
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <DashboardLayout router="/dashboard/nav">
            {data && (
                <>
                    <h4>네비게이션</h4>
                    <p>* 상단의 메뉴를 구성합니다.</p>
                    <DashboardNavItemBox>
                        <>
                            <DashboardNavItemColumn {...handler} data={first} />
                            <DashboardNavItemColumn {...handler} data={second} />
                            <DashboardNavItemColumn {...handler} data={third} />
                        </>
                    </DashboardNavItemBox>
                </>
            )}

            <DefaultModal
                title={"생성"}
                onClose={() => setICreateNavItem(undefined)}
                open={Boolean(iCreateNavItem)}>
                {iCreateNavItem && (
                    <>
                        <InputNavItemView
                            value={iCreateNavItem}
                            onChangeValue={setICreateNavItem}
                        />
                        <TableBtnView>
                            <button
                                onClick={() => handler.onCreateNavItem(iCreateNavItem)}
                                className="btn btn-primary">
                                생성
                            </button>
                        </TableBtnView>
                    </>
                )}
            </DefaultModal>

            <DefaultModal
                title={"수정"}
                onClose={() => setIUpdateNavItem(undefined)}
                open={Boolean(iUpdateNavItem)}>
                {iUpdateNavItem && (
                    <>
                        <InputNavItemView
                            value={iUpdateNavItem.input}
                            onChangeValue={input => setIUpdateNavItem({ ...iUpdateNavItem, input })}
                        />
                        <TableBtnView>
                            <button
                                onClick={() => handler.onUpdateNavItem(iUpdateNavItem)}
                                className="btn btn-primary">
                                수정
                            </button>
                        </TableBtnView>
                    </>
                )}
            </DefaultModal>
        </DashboardLayout>
    );
};

const NavItemUtil = {
    getNavItems: (list: NavItem[], depth: number): NavItem[] => {
        return list.filter(value => value.depth === depth);
    },
    getNavItemByParentId: (list: NavItem[], parentId: string): NavItem[] => {
        return list.filter(value => value.parentId == parentId);
    },
};
const NavItemValidate = {
    create: (input: InputNavItem): boolean => {
        if (!BizValidator.checkMultiLang(input.label)) {
            SwalUtils.ok({
                msg: "이름을 모두 입력하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        if (!input.order) {
            SwalUtils.ok({
                msg: "순서를 입력하여 주십시오.",
                icon: "warning",
            });
            return false;
        }

        return true;
    },
};

export interface INavItemColumn {
    parentId: string;
    parentLabel: MultiLang;
    depth: number;
    list: NavItem[];
}

export interface IUpdateNavItem {
    id: string;
    input: InputNavItem;
}

const FirstColumn: INavItemColumn = {
    parentId: "",
    parentLabel: {},
    depth: 0,
    list: [],
};

const SecondNoColumn: INavItemColumn = {
    parentId: "",
    parentLabel: {},
    depth: 1,
    list: [],
}

const ThirdNoColumn: INavItemColumn = {
    parentId: "",
    parentLabel: {},
    depth: 2,
    list: [],
}

