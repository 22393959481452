import React from "react";
import { OnChangeValue } from "../../../../../graphql/types";
import { InputUpdateIndexCarousel } from "../../DashboardMainPage";
import { InputIndexCarouselView } from "../inputIndexCarousel/InputIndexCarouselView";
import { TableBtnView } from "../../../../../components/table/btn/TableBtnView";

export const IndexCarouselUpdateModal = (props: {
    value: InputUpdateIndexCarousel;
    onChangeValue: OnChangeValue<InputUpdateIndexCarousel>;
    onUpdateIndexCarousel: OnChangeValue<InputUpdateIndexCarousel>;
}) => {
    return (
        <>
            <InputIndexCarouselView
                value={props.value.input}
                onChangeValue={input => props.onChangeValue({ ...props.value, input })}
            />
            <TableBtnView>
                <button
                    onClick={() => props.onUpdateIndexCarousel(props.value)}
                    className="btn btn-sm btn-primary">
                    수정
                </button>
            </TableBtnView>
        </>
    );
};
