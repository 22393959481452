import moment from "moment";

export class LocalStorage {
    static IS_NAVER_LOGINED = "IS_NAVER_LOGINED";
    static GOODS_CREATE_LANG_CODE = "GOOD_CREATE_LANG_CODE";
    static POPUP_DATA = "POPUP_DATA";

    static getIsNaverLogined(): boolean {
        const value = localStorage.getItem(this.IS_NAVER_LOGINED);
        return value === "true";
    }

    static setIsNaverLogined(swt: boolean) {
        localStorage.setItem(this.IS_NAVER_LOGINED, swt ? "true" : "false");
    }

    static setSession(token: string) {
        localStorage.setItem("api-token", token);
    }

    static getSession(): string {
        return localStorage.getItem("api-token") || "";
    }

    static clearSession() {
        localStorage.removeItem("api-token");
    }

    static setLangCode(langCode: string) {
        localStorage.setItem("langCode", langCode);
    }

    static getLangCode(): string {
        return localStorage.getItem("langCode") || "ko";
    }

    static goodsCreateQuillLangCode(): string {
        return localStorage.getItem(this.GOODS_CREATE_LANG_CODE) || "";
    }

    static setGoodsCreateQuillLangCode(value: string) {
        localStorage.setItem(this.GOODS_CREATE_LANG_CODE, value);
    }

    static setIndexPopupData(list: IndexPopupData[]) {
        const id: string[] = [];
        const saveList: IndexPopupData[] = [];
        for (const elem of list) {
            const idx = id.findIndex(value => {
                return value === elem.id;
            });

            if (idx === -1) {
                id.push(elem.id);
                saveList.push(elem);
            }
        }

        const strBody = JSON.stringify(saveList);
        localStorage.setItem(this.POPUP_DATA, strBody);
    }

    static getIndexPopupData(diffDay: number): IndexPopupData[] {
        const strBody = localStorage.getItem(this.POPUP_DATA);
        if (!strBody) {
            this.setIndexPopupData([]);
            return [];
        }

        const list = JSON.parse(strBody);

        if (!(list instanceof Array)) {
            this.setIndexPopupData([]);
            return [];
        }

        const res: IndexPopupData[] = [];
        for (const elem of list) {
            if (!elem.hasOwnProperty("id")) {
                continue;
            }

            if (!elem.hasOwnProperty("checkedDate")) {
                continue;
            }

            const elemDiffDay = moment(elem.checkedDate).diff(moment(), "d");

            if (diffDay && diffDay < elemDiffDay) {
                continue;
            }

            res.push({
                id: elem.id,
                checkedDate: elem.checkedDate,
            });
        }

        this.setIndexPopupData(res);
        return res;
    }
}


export interface IndexPopupData {
    id: string;
    checkedDate: string;
}
