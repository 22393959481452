import React, {useEffect, useState} from "react";
import styles from "./MobileHeader.module.scss";
import logo from "../../../../assets/img/logo/escare_logo.png";
import { Language, Menu, Search } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { OnChangeValue } from "../../../../graphql/types";
import { Link } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import { I18nUtil } from "../../../../services/i18n/i18n";

export const MobileHeader = (props: { onChange: OnChangeValue<boolean> }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handler = {
        onClickLangBtn: (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        onClickChangeLangCode: (langCode: string) => {
            I18nUtil.chgLang(langCode);
            setAnchorEl(null);
        },
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.escare.co.kr/assets/js/digicert.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div className={styles.contHeader}>
                <div className={styles.logo}>
                    <Link to={"/"}>
                        <img src={logo} alt={"로고"} height={35} />
                    </Link>
                </div>
                <div className={styles.space} />
                <div className={styles.boxBtn}>
                    <IconButton
                        onClick={handler.onClickLangBtn}
                    >
                        <Language />
                    </IconButton>
                    <IconButton>
                        <Search />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            props.onChange(true);
                        }}>
                        <Menu />
                    </IconButton>
                </div>
            </div>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}>
                <div className={styles.contBtnLang}>
                    <div
                        className={styles.btnLang}
                        onClick={() => handler.onClickChangeLangCode("en")}>
                        English
                    </div>
                    <div
                        className={styles.btnLang}
                        onClick={() => handler.onClickChangeLangCode("ko")}>
                        한국어
                    </div>
                </div>
            </Popover>
        </>
    );
};
