import {ContentUnit, MultiLang} from './types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** title-content MultiLang Content */
  ContentUnit: ContentUnit;
  /** multiLang object */
  MultiLang: MultiLang;
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  order: Scalars['Int'];
};

export type CreateAdmin = {
  id: Scalars['String'];
  pw: Scalars['String'];
  salt: Scalars['String'];
};

export type DirectNotice = {
  __typename?: 'DirectNotice';
  content: Scalars['String'];
  id: Scalars['String'];
  langCode: Scalars['String'];
  urgent: Scalars['Boolean'];
};

export type DirectNoticeInput = {
  content: Scalars['String'];
  langCode: Scalars['String'];
  urgent: Scalars['Boolean'];
};

export type DirectNoticeList = {
  __typename?: 'DirectNoticeList';
  list: Array<DirectNotice>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
};

export type I18nContents = {
  __typename?: 'I18nContents';
  contents: Scalars['MultiLang'];
  id: Scalars['Int'];
  mainKey: Scalars['String'];
  subKey: Scalars['String'];
};

export type IndexCarousel = {
  __typename?: 'IndexCarousel';
  description: Scalars['MultiLang'];
  header: Scalars['MultiLang'];
  id: Scalars['String'];
  imgSrc: Scalars['String'];
  link: Scalars['String'];
  order: Scalars['Int'];
  preHeader: Scalars['MultiLang'];
};

export type IndexInfoBar = {
  __typename?: 'IndexInfoBar';
  bgUrl: Scalars['String'];
  createdAt: Scalars['Time'];
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  isActivate: Scalars['Boolean'];
  label: Scalars['MultiLang'];
  link: Scalars['String'];
  linkType: LinkType;
  order: Scalars['Int'];
  title: Scalars['MultiLang'];
};

export type InputIndexCarousel = {
  description: Scalars['MultiLang'];
  header: Scalars['MultiLang'];
  imgSrc: Scalars['String'];
  link: Scalars['String'];
  order: Scalars['Int'];
  preHeader: Scalars['MultiLang'];
};

export type InputIndexInfoBar = {
  bgUrl: Scalars['String'];
  iconUrl: Scalars['String'];
  isActivate: Scalars['Boolean'];
  label: Scalars['MultiLang'];
  link: Scalars['String'];
  linkType: LinkType;
  order: Scalars['Int'];
  title: Scalars['MultiLang'];
};

export type InputNavBar = {
  depth: Scalars['Int'];
  keyWord: Scalars['String'];
  label: Scalars['MultiLang'];
  order: Scalars['Int'];
  parentId: Scalars['String'];
};

export type InputNavItem = {
  depth: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  label: Scalars['MultiLang'];
  link: Scalars['String'];
  linkType: LinkType;
  order: Scalars['Int'];
  parentId: Scalars['String'];
};

export type InputNews = {
  badge: Scalars['MultiLang'];
  contents: Scalars['String'];
  imgSrc: Scalars['String'];
  langCode: Scalars['String'];
  newsLabel: Scalars['String'];
  newsType: NewsType;
  title: Scalars['String'];
};

export type InputProduct = {
  backgroundSrc: Scalars['MultiLang'];
  brochureLink: Scalars['MultiLang'];
  descContent?: InputMaybe<Scalars['ContentUnit']>;
  header: Scalars['MultiLang'];
  homepage: Scalars['MultiLang'];
  imposeEffect: Array<Scalars['ContentUnit']>;
  isActivate: Scalars['Boolean'];
  mainContent: Scalars['ContentUnit'];
  referenceLogo: Array<Scalars['String']>;
  subContent?: InputMaybe<Scalars['ContentUnit']>;
  subHeader: Scalars['MultiLang'];
};

export type InputProductOrder = {
  part1: Scalars['Boolean'];
  part2: Scalars['Boolean'];
  part3: Scalars['Boolean'];
  part4: Scalars['Boolean'];
  part5: Scalars['Boolean'];
};

export type LangCode = {
  __typename?: 'LangCode';
  code: Scalars['String'];
  nm: Scalars['String'];
};

export enum LinkType {
  Inner = 'INNER',
  None = 'NONE',
  Outer = 'OUTER'
}

export type Mutation = {
  __typename?: 'Mutation';
  appendIndexCarousel: Scalars['Boolean'];
  changeOrderIndexCarousel: Scalars['Boolean'];
  createAdmin: Scalars['Boolean'];
  createDirectNotice: Scalars['Boolean'];
  createIndexInfoBar: Scalars['Boolean'];
  /** @deprecated No longer supported */
  createNavBar: Scalars['Boolean'];
  createNavItem: Scalars['Boolean'];
  createNews: Scalars['Boolean'];
  createProduct: Scalars['Boolean'];
  deleteDirectNotice: Scalars['Boolean'];
  deleteIndexInfoBar: Scalars['Boolean'];
  getAdmin: Admin;
  login: Scalars['String'];
  logout: Scalars['Boolean'];
  removeIndexCarousel: Scalars['Boolean'];
  /** @deprecated No longer supported */
  removeNavBar: Scalars['Boolean'];
  removeNavItem: Scalars['Boolean'];
  removeNews: Scalars['Boolean'];
  removeProduct?: Maybe<Scalars['Boolean']>;
  salt: Scalars['String'];
  updateDirectNotice: Scalars['Boolean'];
  updateIndexCarousel: Scalars['Boolean'];
  updateIndexInfoBar: Scalars['Boolean'];
  /** @deprecated No longer supported */
  updateNavBar: Scalars['Boolean'];
  updateNavItem: Scalars['Boolean'];
  updateNews: Scalars['Boolean'];
  updateProduct: Scalars['Boolean'];
  updateProductActivation: Scalars['Boolean'];
  updateRecommendationFindWord: Scalars['Boolean'];
  uploadFile: Scalars['String'];
  upsertProductOrder: Scalars['Boolean'];
};


export type MutationAppendIndexCarouselArgs = {
  input: InputIndexCarousel;
};


export type MutationChangeOrderIndexCarouselArgs = {
  input: Array<OrderIndexCarousel>;
};


export type MutationCreateAdminArgs = {
  input: CreateAdmin;
};


export type MutationCreateDirectNoticeArgs = {
  input: DirectNoticeInput;
};


export type MutationCreateIndexInfoBarArgs = {
  input: InputIndexInfoBar;
};


export type MutationCreateNavBarArgs = {
  input: InputNavBar;
};


export type MutationCreateNavItemArgs = {
  input: InputNavItem;
};


export type MutationCreateNewsArgs = {
  input: InputNews;
};


export type MutationCreateProductArgs = {
  input: InputProduct;
};


export type MutationDeleteDirectNoticeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteIndexInfoBarArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  id: Scalars['String'];
  pw: Scalars['String'];
};


export type MutationRemoveIndexCarouselArgs = {
  id: Scalars['String'];
};


export type MutationRemoveNavBarArgs = {
  id: Scalars['String'];
};


export type MutationRemoveNavItemArgs = {
  id: Scalars['String'];
};


export type MutationRemoveNewsArgs = {
  id: Scalars['String'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['String'];
};


export type MutationSaltArgs = {
  username: Scalars['String'];
};


export type MutationUpdateDirectNoticeArgs = {
  id: Scalars['String'];
  input: DirectNoticeInput;
};


export type MutationUpdateIndexCarouselArgs = {
  id: Scalars['String'];
  input: InputIndexCarousel;
};


export type MutationUpdateIndexInfoBarArgs = {
  id: Scalars['String'];
  input: InputIndexInfoBar;
};


export type MutationUpdateNavBarArgs = {
  id: Scalars['String'];
  input: InputNavBar;
};


export type MutationUpdateNavItemArgs = {
  id: Scalars['String'];
  input: InputNavItem;
};


export type MutationUpdateNewsArgs = {
  id: Scalars['String'];
  input: InputNews;
};


export type MutationUpdateProductArgs = {
  id: Scalars['String'];
  input: InputProduct;
};


export type MutationUpdateProductActivationArgs = {
  id: Scalars['String'];
  isActivate: Scalars['Boolean'];
};


export type MutationUpdateRecommendationFindWordArgs = {
  words: Array<Scalars['String']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUpsertProductOrderArgs = {
  input: InputProductOrder;
  productId: Scalars['String'];
};

export type NavBar = {
  __typename?: 'NavBar';
  depth: Scalars['Int'];
  id: Scalars['String'];
  keyWord: Scalars['String'];
  label: Scalars['MultiLang'];
  order: Scalars['Int'];
  parentId: Scalars['String'];
};

export type NavItem = {
  __typename?: 'NavItem';
  depth: Scalars['Int'];
  id: Scalars['String'];
  isActivate: Scalars['Boolean'];
  label: Scalars['MultiLang'];
  link: Scalars['String'];
  linkType: LinkType;
  order: Scalars['Int'];
  parentId: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  badge: Scalars['MultiLang'];
  contents: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['String'];
  imgSrc: Scalars['String'];
  langCode: Scalars['String'];
  newsLabel: Scalars['String'];
  newsType: NewsType;
  title: Scalars['String'];
};

export type NewsFindOptions = {
  findWord?: InputMaybe<Scalars['String']>;
  langCode: Scalars['String'];
  newsType: NewsType;
};

export type NewsList = {
  __typename?: 'NewsList';
  list: Array<News>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NewsType {
  Any = 'ANY',
  Escare = 'ESCARE',
  Letter = 'LETTER',
  Product = 'PRODUCT'
}

export type OrderIndexCarousel = {
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  backgroundSrc: Scalars['MultiLang'];
  brochureLink: Scalars['MultiLang'];
  createdAt: Scalars['Time'];
  descContent?: Maybe<Scalars['ContentUnit']>;
  header: Scalars['MultiLang'];
  homepage: Scalars['MultiLang'];
  id: Scalars['String'];
  imposeEffect: Array<Scalars['ContentUnit']>;
  isActivate: Scalars['Boolean'];
  mainContent: Scalars['ContentUnit'];
  referenceLogo: Array<Scalars['String']>;
  subContent?: Maybe<Scalars['ContentUnit']>;
  subHeader: Scalars['MultiLang'];
  updatedAt: Scalars['Time'];
};

export type ProductList = {
  __typename?: 'ProductList';
  list: Array<Product>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProductOrder = {
  __typename?: 'ProductOrder';
  part1: Scalars['Boolean'];
  part2: Scalars['Boolean'];
  part3: Scalars['Boolean'];
  part4: Scalars['Boolean'];
  part5: Scalars['Boolean'];
  productId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allIndexInfoBar: Array<IndexInfoBar>;
  /** @deprecated No longer supported */
  allNavBarList: Array<NavBar>;
  allNavItemList: Array<NavItem>;
  category: Array<Category>;
  directNoticeIndex: Array<DirectNotice>;
  directNoticeList: DirectNoticeList;
  i18nContents: Array<I18nContents>;
  indexCarousel: Array<IndexCarousel>;
  indexInfoBar: Array<IndexInfoBar>;
  langCode: Array<LangCode>;
  /** @deprecated No longer supported */
  navBarList: Array<NavBar>;
  navItemList: Array<NavItem>;
  news: News;
  newsInIndex: Array<News>;
  newsList: NewsList;
  product: Product;
  productForAdmin: Product;
  productOrder: ProductOrder;
  productWithFindWord: Array<Product>;
  productWithFindWordForAdmin: ProductList;
  recommendationFindWord: Array<Scalars['String']>;
};


export type QueryDirectNoticeIndexArgs = {
  langCode: Scalars['String'];
};


export type QueryDirectNoticeListArgs = {
  langCode: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryNavBarListArgs = {
  parentId: Scalars['String'];
};


export type QueryNewsArgs = {
  id: Scalars['String'];
};


export type QueryNewsInIndexArgs = {
  langCode: Scalars['String'];
};


export type QueryNewsListArgs = {
  options: NewsFindOptions;
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryProductForAdminArgs = {
  id: Scalars['String'];
};


export type QueryProductOrderArgs = {
  id: Scalars['String'];
};


export type QueryProductWithFindWordArgs = {
  findWord: Scalars['String'];
};


export type QueryProductWithFindWordForAdminArgs = {
  findWord: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type NavItemCreateNavItemMutationVariables = Exact<{
  input: InputNavItem;
}>;


export type NavItemCreateNavItemMutation = { __typename?: 'Mutation', createNavItem: boolean };

export type NavItemDeleteNavItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type NavItemDeleteNavItemMutation = { __typename?: 'Mutation', removeNavItem: boolean };

export type NavItemUpdateNavItemMutationVariables = Exact<{
  id: Scalars['String'];
  input: InputNavItem;
}>;


export type NavItemUpdateNavItemMutation = { __typename?: 'Mutation', updateNavItem: boolean };

export type NavItemAllQueryVariables = Exact<{ [key: string]: never; }>;


export type NavItemAllQuery = { __typename?: 'Query', allNavItemList: Array<{ __typename?: 'NavItem', depth: number, id: string, isActivate: boolean, label: MultiLang, link: string, linkType: LinkType, order: number, parentId: string }> };

export type NavItemQueryVariables = Exact<{ [key: string]: never; }>;


export type NavItemQuery = { __typename?: 'Query', navItemList: Array<{ __typename?: 'NavItem', depth: number, id: string, isActivate: boolean, label: MultiLang, link: string, linkType: LinkType, order: number, parentId: string }> };


export const NavItemCreateNavItemDocument = gql`
    mutation NavItemCreateNavItem($input: InputNavItem!) {
  createNavItem(input: $input)
}
    `;
export type NavItemCreateNavItemMutationFn = Apollo.MutationFunction<NavItemCreateNavItemMutation, NavItemCreateNavItemMutationVariables>;

/**
 * __useNavItemCreateNavItemMutation__
 *
 * To run a mutation, you first call `useNavItemCreateNavItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNavItemCreateNavItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [navItemCreateNavItemMutation, { data, loading, error }] = useNavItemCreateNavItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNavItemCreateNavItemMutation(baseOptions?: Apollo.MutationHookOptions<NavItemCreateNavItemMutation, NavItemCreateNavItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NavItemCreateNavItemMutation, NavItemCreateNavItemMutationVariables>(NavItemCreateNavItemDocument, options);
      }
export type NavItemCreateNavItemMutationHookResult = ReturnType<typeof useNavItemCreateNavItemMutation>;
export type NavItemCreateNavItemMutationResult = Apollo.MutationResult<NavItemCreateNavItemMutation>;
export type NavItemCreateNavItemMutationOptions = Apollo.BaseMutationOptions<NavItemCreateNavItemMutation, NavItemCreateNavItemMutationVariables>;
export const NavItemDeleteNavItemDocument = gql`
    mutation NavItemDeleteNavItem($id: String!) {
  removeNavItem(id: $id)
}
    `;
export type NavItemDeleteNavItemMutationFn = Apollo.MutationFunction<NavItemDeleteNavItemMutation, NavItemDeleteNavItemMutationVariables>;

/**
 * __useNavItemDeleteNavItemMutation__
 *
 * To run a mutation, you first call `useNavItemDeleteNavItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNavItemDeleteNavItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [navItemDeleteNavItemMutation, { data, loading, error }] = useNavItemDeleteNavItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNavItemDeleteNavItemMutation(baseOptions?: Apollo.MutationHookOptions<NavItemDeleteNavItemMutation, NavItemDeleteNavItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NavItemDeleteNavItemMutation, NavItemDeleteNavItemMutationVariables>(NavItemDeleteNavItemDocument, options);
      }
export type NavItemDeleteNavItemMutationHookResult = ReturnType<typeof useNavItemDeleteNavItemMutation>;
export type NavItemDeleteNavItemMutationResult = Apollo.MutationResult<NavItemDeleteNavItemMutation>;
export type NavItemDeleteNavItemMutationOptions = Apollo.BaseMutationOptions<NavItemDeleteNavItemMutation, NavItemDeleteNavItemMutationVariables>;
export const NavItemUpdateNavItemDocument = gql`
    mutation NavItemUpdateNavItem($id: String!, $input: InputNavItem!) {
  updateNavItem(id: $id, input: $input)
}
    `;
export type NavItemUpdateNavItemMutationFn = Apollo.MutationFunction<NavItemUpdateNavItemMutation, NavItemUpdateNavItemMutationVariables>;

/**
 * __useNavItemUpdateNavItemMutation__
 *
 * To run a mutation, you first call `useNavItemUpdateNavItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNavItemUpdateNavItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [navItemUpdateNavItemMutation, { data, loading, error }] = useNavItemUpdateNavItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNavItemUpdateNavItemMutation(baseOptions?: Apollo.MutationHookOptions<NavItemUpdateNavItemMutation, NavItemUpdateNavItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NavItemUpdateNavItemMutation, NavItemUpdateNavItemMutationVariables>(NavItemUpdateNavItemDocument, options);
      }
export type NavItemUpdateNavItemMutationHookResult = ReturnType<typeof useNavItemUpdateNavItemMutation>;
export type NavItemUpdateNavItemMutationResult = Apollo.MutationResult<NavItemUpdateNavItemMutation>;
export type NavItemUpdateNavItemMutationOptions = Apollo.BaseMutationOptions<NavItemUpdateNavItemMutation, NavItemUpdateNavItemMutationVariables>;
export const NavItemAllDocument = gql`
    query NavItemAll {
  allNavItemList {
    depth
    id
    isActivate
    label
    link
    linkType
    order
    parentId
  }
}
    `;

/**
 * __useNavItemAllQuery__
 *
 * To run a query within a React component, call `useNavItemAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavItemAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavItemAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavItemAllQuery(baseOptions?: Apollo.QueryHookOptions<NavItemAllQuery, NavItemAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavItemAllQuery, NavItemAllQueryVariables>(NavItemAllDocument, options);
      }
export function useNavItemAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavItemAllQuery, NavItemAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavItemAllQuery, NavItemAllQueryVariables>(NavItemAllDocument, options);
        }
export type NavItemAllQueryHookResult = ReturnType<typeof useNavItemAllQuery>;
export type NavItemAllLazyQueryHookResult = ReturnType<typeof useNavItemAllLazyQuery>;
export type NavItemAllQueryResult = Apollo.QueryResult<NavItemAllQuery, NavItemAllQueryVariables>;
export const NavItemDocument = gql`
    query NavItem {
  navItemList {
    depth
    id
    isActivate
    label
    link
    linkType
    order
    parentId
  }
}
    `;

/**
 * __useNavItemQuery__
 *
 * To run a query within a React component, call `useNavItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavItemQuery(baseOptions?: Apollo.QueryHookOptions<NavItemQuery, NavItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavItemQuery, NavItemQueryVariables>(NavItemDocument, options);
      }
export function useNavItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavItemQuery, NavItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavItemQuery, NavItemQueryVariables>(NavItemDocument, options);
        }
export type NavItemQueryHookResult = ReturnType<typeof useNavItemQuery>;
export type NavItemLazyQueryHookResult = ReturnType<typeof useNavItemLazyQuery>;
export type NavItemQueryResult = Apollo.QueryResult<NavItemQuery, NavItemQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    