import {RouteObject} from "react-router-dom";
import {IndexPage} from "./index/IndexPage";
import {ProductDetailPage} from "./product/detail/ProductDetailPage";
import {AuthLoginPage} from "./auth/login/AuthLoginPage";
import {AuthJoinPage} from "./auth/join/AuthJoinPage";
import {DashboardMainPage} from "./dashboard/main/DashboardMainPage";
import {DashboardCategoryPage} from "./dashboard/category/DashboardCategoryPage";
import {DashboardNews} from "./dashboard/news/DashboardNews";
import {CategoryPage} from "./category/CategoryPage";
import {DashboardCreateProductPage} from "./dashboard/createProduct/DashboardCreateProductPage";
import {DashboardManageProductPage} from "./dashboard/manageProduct/DashboardManageProductPage";
import {DashboardUpdateProductPage} from "./dashboard/updateProduct/DashboardUpdateProductPage";
import {NewsPage} from "./news/NewsPage";
import {ArticlePage} from "./article/ArticlePage";
import {FindResultPage} from "./findResult/FindResultPage";
import {QnAPage} from "./qna/QnAPage";
import {RecruitPage} from "./recruit/RecruitPage";
import {HistoryPage} from "./history/HistoryPage";
import {LocationPage} from "./location/LocationPage";
import {OverviewPage} from "./overview/OverviewPage";
import {DashboardPopup} from "./dashboard/popup/DashboardPopup";
import {ConsultingPage} from "./consulting/consultingPage";
import {PatnersPage} from "./partners/PatnersPage";
import {CustomersPage} from "./customers/CustomersPage";
import {IntelligentPage} from "./intelligent/IntelligentPage";
import {DashboardNavItemPage} from "./dashboard/navItem/DashboardNavItemPage";

export const GetRouter = (isLogin: boolean): RouteObject[] => {
    let router: RouteObject[] = [
        {
            path: "/",
            element: <IndexPage/>,
        },
        {
            path: "/category/:id",
            element: <CategoryPage/>,
        },
        {
            path: "/history",
            element: <HistoryPage/>,
        },
        {
            path: "/location",
            element: <LocationPage/>,
        },
        {
            path: "/product/:id",
            element: <ProductDetailPage/>,
        },
        {
            path: "/service/consulting",
            element: <ConsultingPage/>,
        },
        {
            path: "/customers",
            element: <CustomersPage/>,
        },
        {
            path: "/service/intelligent",
            element: <IntelligentPage/>,
        },
        {
            path: "/news",
            element: <NewsPage/>,
        },
        {
            path: "/news/:page/:size",
            element: <NewsPage/>,
        },
        {
            path: "/news/:page/:size/:newsType",
            element: <NewsPage/>,
        },
        {
            path: "/news/:page/:size/:newsType/:findWord",
            element: <NewsPage/>,
        },
        {
            path: "/article/:id",
            element: <ArticlePage/>,
        },
        {
            path: "/find/:findWord",
            element: <FindResultPage/>,
        },
        {
            path: "/find/:findWord/:page",
            element: <FindResultPage/>,
        },
        {
            path: "/overview",
            element: <OverviewPage/>,
        },
        {
            path: "/qna",
            element: <QnAPage/>,
        },
        {
            path: "/partners",
            element: <PatnersPage/>,
        },
        {
            path: "/recruit",
            element: <RecruitPage/>,
        },
    ];

    if (isLogin) {
        router = [
            ...router,
            {
                path: "/dashboard/main",
                element: <DashboardMainPage/>,
            },
            {
                path: "/dashboard/nav",
                element: <DashboardNavItemPage/>,
            },
            {
                path: "/dashboard/news",
                element: <DashboardNews/>,
            },
            {
                path: "/dashboard/product/create",
                element: <DashboardCreateProductPage/>,
            },
            {
                path: "/dashboard/product/manage",
                element: <DashboardManageProductPage/>,
            },
            {
                path: "/dashboard/product/manage/:page/:size",
                element: <DashboardManageProductPage/>,
            },
            {
                path: "/dashboard/product/manage/:page/:size/:findWord",
                element: <DashboardManageProductPage/>,
            },
            {
                path: "/dashboard/product/update/:id",
                element: <DashboardUpdateProductPage/>,
            },
            {
                path: "/dashboard/popup",
                element: <DashboardPopup/>,
            },
        ];
    } else {
        router = [
            ...router,
            {
                path: "/auth/login",
                element: <AuthLoginPage/>,
            },
            {
                path: "/auth/join",
                element: <AuthJoinPage/>,
            },
        ];
    }

    return router;
};
