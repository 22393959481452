import React, {ReactNode, useEffect, useState} from "react";
import {ViewSelector} from "../utils/viewSelector/ViewSelector";
import {LayoutWeb} from "./web/LayoutWeb";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";
import {MultiLang} from "../../graphql/types";
import {LayoutMobile} from "./mobile/LayoutMobile";
import {NavItem, useNavItemQuery} from "../../graphql/graphql";

export const Layout = (props: { children: ReactNode }) => {
    const [navItemList, setNavItemList] = useState<TopNavBar[]>([]);
    const isLogin = useReactiveVar(GlobalVars.user.isLogin);

    const {data} = useNavItemQuery();

    useEffect(() => {
        if (!data) {
            return;
        }

        const itemList = data.navItemList;
        const list: TopNavBar[] = [...NavItemUtil.getNavItems(itemList, 0)];

        for (const firstItem of list) {
            firstItem.sub = NavItemUtil.getNavItemByParentId(itemList, firstItem.id);

            for (const secondItem of firstItem.sub) {
                secondItem.sub = NavItemUtil.getNavItemByParentId(itemList, secondItem.id);
            }
        }

        if (isLogin) {
            list.push({
                id: "",
                label: {
                    ko: "대시보드",
                    en: "Dashboard",
                },
                router: "/dashboard/main",
                sub: [],
            })
        }

        setNavItemList(list);

    }, [isLogin, data]);

    return (
        <>
            <ViewSelector
                web={<LayoutWeb topNavBar={navItemList}>{props.children}</LayoutWeb>}
                mobile={<LayoutMobile data={navItemList}>{props.children}</LayoutMobile>}
            />
            <div id="DigiCertClickID_qsP6uv-y" className="sslSeal" lang="ko"></div>
        </>
    );
};

const NavItemUtil = {
    getNavItems: (list: NavItem[], depth: number): TopNavBar[] => {
        return list.filter(value => value.depth === depth).map(value => ({
            id: value.id,
            label: value.label,
            router: value.link,
            sub: [],
        }));
    },
    getNavItemByParentId: (list: NavItem[], parentId: string): TopNavBar[] => {
        return list.filter(value => value.parentId == parentId).map(value => ({
            id: value.id,
            label: value.label,
            router: value.link,
            sub: [],
        }))
    }
}

export interface TopNavBar {
    id: string;
    label: MultiLang;
    router: string;
    sub: TopNavBar[];
}
