import React from "react";
import styles from "./IndexNews.module.scss";
import moment from "moment";
import { BorderButton } from "../../../components/btn/borderButton/BorderButton";
import { News } from "../../../graphql/graphql";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";
import {useNavigate} from "react-router-dom";

export const IndexNews = (props: { list: News[] }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const navigate = useNavigate();
    const handler = {
        onClickNews: (id: string) => {
            return navigate(`/article/${id}`);
        },
    };

    let textList = {
        title: "",
        subTitle: "",
        moreEscare: "",
        moreProduct: "",
    };

    if (langCode === "en") {
        textList = {
            title: "The Latest News",
            subTitle: "Get the latest and vivid news from ESCARE.",
            moreEscare: "ESCARE NEWS",
            moreProduct: "PRODUCT NEWS",
        };
    } else {
        textList = {
            title: "최신 소식",
            subTitle: "에스케어의 생생한 최신 소식을 만나보세요.",
            moreEscare: "에스케어 뉴스",
            moreProduct: "제품 뉴스",
        };
    }

    return (
        <div className={styles.cont}>
            <div className={styles.boxTitle}>
                <div className={styles.title}>{textList.title}</div>
                <div className={styles.subTitle}>{textList.subTitle}</div>
            </div>

            <div className={styles.boxNews}>
                {props.list.map((value, index) => (
                    <div
                        key={index}
                        className={styles.news}
                        onClick={() => handler.onClickNews(value.id)}>
                        <div className={styles.boxImg}>
                            <div className={styles.img}>
                                <img src={value.imgSrc} alt="img" />
                            </div>
                            <div className={styles.description}>
                                <p>{value.title}</p>

                                <div className={styles.date}>
                                    {moment(value.createdAt).format("YY.MM.DD")} | {value.newsLabel}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.boxMore}>
                <BorderButton link={`/news/0/10/ESCARE`} more={true}>
                    <></>
                </BorderButton>
            </div>
        </div>
    );
};
