import React, { Fragment, useState } from "react";
import { AuthJoinForm } from "./form/AuthJoinForm";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { gql, useMutation } from "@apollo/client";
import { Mutation, MutationCreateAdminArgs } from "../../../graphql/graphql";
import { CryptoUtils } from "../../../services/crypoUtils/CryptoUtils";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { useNavigate } from "react-router-dom";

export const AuthJoinPage = () => {
    const [state, setState] = useState<AuthJoinState>({
        id: "",
        password: "",
        confirm: "",
    });

    const [mutCreateAdmin] = useMutation<Mutation, MutationCreateAdminArgs>(gqlJoin);
    const navigate = useNavigate();

    const handler = {
        onClickJoin: () => {
            const idRegExp = RegExp("^[a-z][a-z0-9]*$");

            if (!idRegExp.test(state.id)) {
                SwalUtils.ok({
                    msg: "아이디는 영문, 숫자 조합으로 입력하여 주십시오.",
                    icon: "error",
                });
                return;
            }

            if (state.id.length < 4) {
                SwalUtils.ok({
                    msg: "아이디는 4글자 이상으로 입력하여 주십시오.",
                    icon: "error",
                });
                return;
            }

            if (state.password !== state.confirm) {
                SwalUtils.ok({
                    msg: "두 비밀번호가 다릅니다.",
                    icon: "error",
                });
                return;
            }

            if (state.password.length < 8) {
                SwalUtils.ok({
                    msg: "비밀번호는 8글자 이상으로 입력하여 주십시오.",
                    icon: "error",
                });
                return;
            }

            const salt = CryptoUtils.newSalt();
            const pw = CryptoUtils.encrypt(state.password, salt);

            mutCreateAdmin({
                variables: {
                    input: {
                        id: state.id,
                        pw,
                        salt,
                    },
                },
            })
                .then(() => {
                    SwalUtils.ok({
                        msg: "회원 가입되었습니다. 관리자 승인후 사용 가능합니다.",
                        icon: "success",
                    });
                    navigate("/auth/login");
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <Fragment>
            <AuthJoinForm value={state} onChange={setState} {...handler} />
        </Fragment>
    );
};

export interface AuthJoinState {
    id: string;
    password: string;
    confirm: string;
}

const gqlJoin = gql`
    mutation Join($input: CreateAdmin!) {
        createAdmin(input: $input)
    }
`;
