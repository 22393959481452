import { ApolloError, gql } from "@apollo/client";
import { GraphQLError } from "graphql";
import { Apollo } from "../apollo/apollo";
import { Mutation, MutationUploadFileArgs } from "../../graphql/graphql";

export enum FileType {
    IMAGE = "image/*",
    ANY = "*",
}

export class FileManager {
    static async uploadFile(fileType: FileType): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const input = document.createElement("input");
            input.setAttribute("hidden", "true");
            input.setAttribute("type", "file");
            input.setAttribute("accept", fileType.toString());

            input.click();
            input.onchange = () => {
                if (!input.files) {
                    reject();
                    return;
                }

                const list: File[] = [];
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files[i];
                    if (!file) {
                        continue;
                    }

                    // 파일 사이즈 체크 (10m)
                    // if (10000000 < file.size) {
                    //     reject(new ApolloError({
                    //         graphQLErrors: [
                    //             new GraphQLError(BizErrors.overMaxSize)
                    //         ]
                    //     }));
                    //     return;
                    // }

                    list.push(file);
                }

                if (list.length === 0) {
                    reject(
                        new ApolloError({
                            graphQLErrors: [new GraphQLError("fileNotImported")],
                        }),
                    );
                    return;
                }

                Apollo.client
                    .mutate<Mutation, MutationUploadFileArgs>({
                        mutation: gql`
                            mutation UploadFile($file: Upload!) {
                                uploadFile(file: $file)
                            }
                        `,
                        variables: {
                            file: list[0],
                        },
                    })
                    .then(res => {
                        resolve(res.data?.uploadFile || "");
                    })
                    .catch(reject);
            };
        });
    }

    static downloadFile(src: string) {
        const tag = document.createElement("a");
        tag.href = src;
        tag.target = "_blank";
        tag.setAttribute("hidden", "true");
        tag.click();
    }
}
