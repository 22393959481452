import React from "react";
import { TableBtnView } from "../../../../../components/table/btn/TableBtnView";
import { InputUpdateIndexInfoBar } from "../../DashboardMainPage";
import { OnChangeValue } from "../../../../../graphql/types";
import { InputIndexInfoBarView } from "../inputIndexInfoBar/InputIndexInfoBarView";

export const IndexInfoBarUpdateModal = (props: {
    value: InputUpdateIndexInfoBar;
    onChangeValue: OnChangeValue<InputUpdateIndexInfoBar>;
    onUpdateIndexInfoBar: OnChangeValue<InputUpdateIndexInfoBar>;
}) => {
    return (
        <>
            <InputIndexInfoBarView
                value={props.value.input}
                onChangeValue={input => props.onChangeValue({ ...props.value, input })}
            />
            <TableBtnView>
                <button
                    onClick={() => props.onUpdateIndexInfoBar(props.value)}
                    className="btn btn-sm btn-primary">
                    수정
                </button>
            </TableBtnView>
        </>
    );
};
