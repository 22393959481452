import React, { ReactNode } from "react";
import styles from "./DashboardNavItemColumn.module.scss";
import { INavItemColumn, IUpdateNavItem } from "../DashboardNavItemPage";
import { I18nValue } from "../../../../components/system/i18nValue/I18nValue";
import { OnChangeValue } from "../../../../graphql/types";
import { InputNavItem, LinkType, NavItem } from "../../../../graphql/graphql";
import { IconButton } from "@material-ui/core";
import { Check, Delete, Edit } from "@material-ui/icons";

export const DashboardNavItemColumn = (props: {
    data: INavItemColumn;
    onClickCreate: OnChangeValue<InputNavItem>;
    onClickUpdate: OnChangeValue<IUpdateNavItem>;
    onClickSelect: OnChangeValue<INavItemColumn>;
    onClickDelete: OnChangeValue<string>;
}) => {
    const data = props.data;

    const handler = {
        onClickCreate: () => {
            props.onClickCreate({
                depth: data.depth,
                isActivate: true,
                label: {},
                link: "",
                linkType: LinkType.Inner,
                order: 0,
                parentId: data.parentId,
            });
        },
        onClickUpdate: (item: NavItem) => {
            props.onClickUpdate({
                id: item.id,
                input: {
                    depth: item.depth,
                    isActivate: item.isActivate,
                    label: item.label,
                    link: item.link,
                    linkType: item.linkType,
                    order: item.order,
                    parentId: item.parentId,
                },
            });
        },
        onClickSelect: (item: NavItem) => {
            props.onClickSelect({
                depth: item.depth + 1,
                list: [],
                parentId: item.id,
                parentLabel: item.label,
            });
        },
        onClickDelete: props.onClickDelete,
    };

    console.log(data);

    return (
        <div className={styles.boxColumn}>
            <div className={styles.depthTitle}>
                {data.depth + 1} 단{" "}
                {data.parentId && (
                    <>
                        (<I18nValue value={data.parentLabel} />)
                    </>
                )}
            </div>

            {data.list
                .sort((a, b) => a.order - b.order)
                .map((value, index) => (
                    <div key={index} className={styles.menuBox}>
                        <div className={styles.title}>
                            <I18nValue value={value.label} />
                        </div>

                        <div className={styles.statusLine}>
                            <div className={styles.status}>순서값</div>
                            <div className={styles.value}>{value.order}</div>
                        </div>

                        <div className={styles.statusLine}>
                            <div className={styles.status}>링크</div>
                            <div className={styles.value}>{value.link ? "연결됨" : "링크없음"}</div>
                        </div>

                        <div className={styles.statusLine}>
                            <div className={styles.status}>상태</div>
                            <div className={styles.value}>{value.isActivate ? "활성화" : "비활성화"}</div>
                        </div>

                        <div className={styles.functionLine}>
                            <IconButton onClick={() => handler.onClickSelect(value)}>
                                <Check />
                            </IconButton>
                            <IconButton onClick={() => handler.onClickUpdate(value)}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => handler.onClickDelete(value.id)}>
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                ))}
            {data.depth !== 0 && !data.parentId && <div>상위 뎁스가 선택되지 않았습니다.</div>}
            {data.parentId && data.list.length == 0 && <div>데이터가 없습니다.</div>}

            {data.depth == 0 && (
                <button onClick={handler.onClickCreate} className="btn btn-outline-primary" style={{marginBottom: 30}}>
                    추가
                </button>
            )}

            {0 < data.depth && data.parentId != "" && (
                <button onClick={handler.onClickCreate} className="btn btn-outline-primary" style={{marginBottom: 30}}>
                    추가
                </button>
            )}
        </div>
    );
};

export const DashboardNavItemBox = (props: { children: ReactNode }) => {
    return <div className={styles.cont}>{props.children}</div>;
};
