import React from "react";
import styles from "./InputIndexInfoBarView.module.scss";
import { InputIndexInfoBar, LinkType } from "../../../../../graphql/graphql";
import { OnChangeValue } from "../../../../../graphql/types";
import { FileManager, FileType } from "../../../../../services/fileManager/FileManager";
import { ApolloCatch } from "../../../../../services/apollo/apolloCatch";
import classNames from "classnames";
import { MenuItem, Select, Switch } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { InputMultiLang } from "../../../../../components/input/multiLang/InputMultiLang";

export const InputIndexInfoBarView = (props: {
    value: InputIndexInfoBar;
    onChangeValue: OnChangeValue<InputIndexInfoBar>;
}) => {
    const handler = {
        onUploadBgUrl: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(res => {
                    props.onChangeValue({ ...props.value, bgUrl: res });
                })
                .catch(ApolloCatch({}));
        },
        onUploadIconUrl: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(res => {
                    props.onChangeValue({ ...props.value, iconUrl: res });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>활성화</div>
                <div className={classNames(styles.colValue)}>
                    <Switch
                        color="primary"
                        checked={props.value.isActivate}
                        onChange={() => {
                            props.onChangeValue({
                                ...props.value,
                                isActivate: !props.value.isActivate,
                            });
                        }}
                    />
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>배경 이미지</div>
                <div className={styles.colPath}>
                    <div className="form-control">
                        {props.value.bgUrl || "파일을 업로드하여 주십시오."}
                    </div>
                </div>
                <div className={styles.colFunc}>
                    <button onClick={handler.onUploadBgUrl} className="btn btn-outline-primary">
                        업로드
                    </button>
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>아이콘 이미지</div>
                <div className={styles.colPath}>
                    <div className="form-control">
                        {props.value.iconUrl || "파일을 업로드하여 주십시오."}
                    </div>
                </div>
                <div className={styles.colFunc}>
                    <button onClick={handler.onUploadIconUrl} className="btn btn-outline-primary">
                        업로드
                    </button>
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>링크</div>
                <div className={styles.colValue}>
                    <input
                        value={props.value.link}
                        onChange={ev =>
                            props.onChangeValue({ ...props.value, link: ev.target.value })
                        }
                        placeholder=""
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>링크타입</div>
                <div className={styles.colValue}>
                    <Select
                        label="링크타입"
                        variant="standard"
                        defaultValue={props.value.linkType}
                        onChange={ev => {
                            props.onChangeValue({
                                ...props.value,
                                linkType: ev.target.value as LinkType,
                            });
                        }}>
                        <MenuItem
                            value={LinkType.None}
                            selected={props.value.linkType === LinkType.None}>
                            링크없음
                        </MenuItem>
                        <MenuItem
                            value={LinkType.Inner}
                            selected={props.value.linkType === LinkType.Inner}>
                            내부링크
                        </MenuItem>
                        <MenuItem
                            value={LinkType.Outer}
                            selected={props.value.linkType === LinkType.Outer}>
                            외부링크
                        </MenuItem>
                    </Select>
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>순서</div>
                <div className={styles.colValue}>
                    <NumberFormat
                        className="form-control"
                        value={props.value.order}
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={v =>
                            props.onChangeValue({ ...props.value, order: v.floatValue || 0 })
                        }
                    />
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>타이틀</div>
                <div className={styles.colValue}>
                    <InputMultiLang
                        value={props.value.title}
                        onChangeValue={v => props.onChangeValue({ ...props.value, title: v })}
                    />
                </div>
            </div>
            <div className={classNames(styles.tableCreate, styles.table)}>
                <div className={classNames(styles.colLabel, styles.col)}>설명글</div>
                <div className={styles.colValue}>
                    <InputMultiLang
                        value={props.value.label}
                        onChangeValue={v => props.onChangeValue({ ...props.value, label: v })}
                    />
                </div>
            </div>
        </>
    );
};
