import React, { Fragment, ReactNode } from "react";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars, ViewMode } from "../../../globalVars/globalVars";

export const ViewSelector = (props: { web: ReactNode; mobile: ReactNode }) => {
    const viewMode = useReactiveVar(GlobalVars.window.viewMode);

    return (
        <Fragment>
            {viewMode === ViewMode.Web && props.web}
            {viewMode === ViewMode.Mobile && props.mobile}
        </Fragment>
    );
};
