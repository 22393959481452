import React from "react";
import styles from "./IndexCarousel.module.scss";
import {Carousel} from "react-bootstrap";
import {LinkUtils} from "../../../services/link/LinkUtils";
import {IndexCarousel as Data} from "../../../graphql/graphql";
import {I18nValue} from "../../../components/system/i18nValue/I18nValue";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../globalVars/globalVars";

type testListType = {
    more: string;
}

export const IndexCarousel = (props: { data: Data[] }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: testListType;

    if (langCode === "en") {
        textList = {
            more: "View More"
        };
    } else {
        textList = {
            more: "자세히보기"
        }
    }

    return (
        <div className={styles.cont}>
            <Carousel>
                {props.data.map((value, index) => (
                    <Carousel.Item key={index}>
                        <div className={styles.box}>
                            <img className={styles.bg} src={value.imgSrc} alt={`${index} 이미지`}/>
                            <div className={styles.boxInfo}>
                                <div className={styles.info}>
                                    <div className={styles.preHeader}>
                                        <I18nValue value={value.preHeader}/>
                                    </div>
                                    <div className={styles.header}>
                                        <I18nValue value={value.header}/>
                                    </div>
                                    <div className={styles.description}>
                                        <I18nValue value={value.description}/>
                                    </div>
                                    <div
                                        className={styles.btn}
                                        onClick={() => LinkUtils.openNewWindow(value.link)}>
                                        {textList.more}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};
