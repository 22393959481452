import React from "react";
import styles from "./AuthJoinForm.module.scss";
import { AuthJoinState } from "../AuthJoinPage";
import { OnChangeValue, VoidFunction } from "../../../../graphql/types";

export const AuthJoinForm = (props: {
    value: AuthJoinState;
    onChange: OnChangeValue<AuthJoinState>;
    onClickJoin: VoidFunction;
}) => {
    return (
        <div className={styles.cont}>
            <form
                onSubmit={ev => {
                    ev.preventDefault();
                    props.onClickJoin();
                }}
                className={styles.box}>
                <h4>회원가입</h4>
                <input
                    value={props.value.id}
                    onChange={ev => props.onChange({ ...props.value, id: ev.target.value })}
                    pattern="^[a-z][a-z0-9]*$"
                    placeholder="아이디"
                    type="text"
                    className="form-control form-control-sm"
                />

                <input
                    value={props.value.password}
                    onChange={ev => props.onChange({ ...props.value, password: ev.target.value })}
                    type="password"
                    placeholder="비밀번호"
                    className="form-control form-control-sm"
                />

                <input
                    value={props.value.confirm}
                    onChange={ev => props.onChange({ ...props.value, confirm: ev.target.value })}
                    type="password"
                    placeholder="비밀번호 확인"
                    className="form-control form-control-sm"
                />

                <button className="btn btn-sm btn-primary">회원가입</button>
            </form>
        </div>
    );
};
