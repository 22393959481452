import React, { Fragment } from "react";
import styles from "./ProductManageProductList.module.scss";
import { MutationUpdateProductActivationArgs, Product } from "../../../../graphql/graphql";
import classNames from "classnames";
import { NoDataTable } from "../../../../components/table/none/NoDataTable";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../../globalVars/globalVars";
import { I18nUtil } from "../../../../services/i18n/i18n";
import { I18nValue } from "../../../../components/system/i18nValue/I18nValue";
import { Checkbox, IconButton } from "@material-ui/core";
import { Delete, Edit, Share } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { OnChangeValue } from "../../../../graphql/types";
import { SwalUtils } from "../../../../services/swal/SwalUtils";
import copy from "copy-to-clipboard";

export const ProductManageProductList = (props: {
    value: Product[];
    onClickRemove: OnChangeValue<string>;
    onClickProductCheckBox: OnChangeValue<MutationUpdateProductActivationArgs>;
}) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const navigate = useNavigate();

    const handler = {
        onClickEdit: (id: string) => {
            navigate(`/dashboard/product/update/${id}`);
        },
        onClickCopy: (id: string) => {
            copy(`/product/${id}`);
            SwalUtils.ok({
                msg: "클립보드에 복사되었습니다.",
                icon: "success",
            });
        },
    };

    return (
        <>
            <div className={classNames(styles.table, styles.tableProduct)}>
                <div className={styles.colIsActivate}>활성화</div>
                <div className={styles.colId}>아이디</div>
                <div className={styles.colImage}>이미지</div>
                <div className={styles.colHeader}>제품명</div>
                <div className={styles.colSubHeader}>부 제품명</div>
                <div className={styles.colFunc}>기능</div>
            </div>

            {props.value.length === 0 && <NoDataTable />}
            {props.value.map((value, index) => (
                <div
                    key={index}
                    className={classNames(styles.table, styles.tableProduct, styles.hover)}>
                    <div className={styles.colIsActivate}>
                        <Checkbox
                            color="primary"
                            checked={value.isActivate}
                            onChange={ev =>
                                props.onClickProductCheckBox({
                                    id: value.id,
                                    isActivate: ev.target.checked,
                                })
                            }
                        />
                    </div>
                    <div className={styles.colId}>{value.id}</div>
                    <div className={styles.colImage}>
                        <img src={I18nUtil.value(langCode, value.backgroundSrc)} alt="logo" />
                    </div>
                    <div className={styles.colHeader}>
                        <I18nValue value={value.header} />
                    </div>
                    <div className={styles.colSubHeader}>
                        <I18nValue value={value.subHeader} />
                    </div>
                    <div className={styles.colFunc}>
                        <IconButton onClick={() => handler.onClickCopy(value.id)}>
                            <Share />
                        </IconButton>
                        <IconButton onClick={() => handler.onClickEdit(value.id)}>
                            <Edit />
                        </IconButton>

                        <IconButton onClick={() => props.onClickRemove(value.id)}>
                            <Delete />
                        </IconButton>
                    </div>
                </div>
            ))}
        </>
    );
};
