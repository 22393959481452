import React, { Fragment } from "react";
import styles from "./FindResultProductList.module.scss";
import { Product } from "../../../graphql/graphql";
import { NoDataTable } from "../../../components/table/none/NoDataTable";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { I18nValue } from "../../../components/system/i18nValue/I18nValue";
import { I18nUtil } from "../../../services/i18n/i18n";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";

export const FindResultProductList = (props: { value: Product[] }) => {
    const navigate = useNavigate();
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    const handler = {
        onClickArticle: (id: string) => {
            navigate(`/product/${id}`);
        },
    };

    return (
        <Fragment>
            {props.value.length === 0 && <NoDataTable noBorder />}

            {props.value.map((value, index) => (
                <div
                    key={index}
                    className={classNames(
                        styles.borderTop,
                        styles.cont,
                        props.value.length - 1 === index ? styles.borderBottom : "",
                    )}
                    onClick={() => handler.onClickArticle(value.id)}>
                    <div className={styles.boxInfo}>
                        <div className={styles.title}>
                            <I18nValue value={value.header} />
                        </div>
                        <div className={styles.content}>
                            <I18nValue value={value.mainContent.content} />
                        </div>
                        <div className={styles.createdAt}>
                            {moment(value.createdAt).format("YY.MM.DD")}
                        </div>
                    </div>
                    <div className={styles.boxImg}>
                        <img src={I18nUtil.value(langCode, value.backgroundSrc)} alt="img" />
                    </div>
                </div>
            ))}
        </Fragment>
    );
};
