import React from "react";
import styles from "./HistoryPage.module.scss";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";

interface IHistoryText {
    title: string;
    description: string;
    sub: IHistoryTextSub[];
}

interface IHistoryTextSub {
    year: number;
    image: string;
    sub: IHistoryTextSubSub[];
}

interface IHistoryTextSubSub {
    month: string;
    sub: string[];
}

export const HistoryPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let historyText: IHistoryText;
    if (langCode === "en") {
        historyText = {
            title: "History",
            description: "Now ~ 2012",
            sub: [
                {
                    year: 2022,
                    image: "/assets/images/history/2022.jpg",
                    sub: [
                        {
                            month: "06",
                            sub: ["3rd Office Open / Jeju Start-up Support Center"],
                        },
                        {
                            month: "05",
                            sub: ["ZT-VPN Release"],
                        },
                        {
                            month: "02",
                            sub: ["Signing Quest Software Partner Contract"],
                        },
                    ],
                },
                {
                    year: 2021,
                    image: "/assets/images/history/2021.png",
                    sub: [
                        {
                            month: "10",
                            sub: ["2nd Office Open / Soongsil University Startup Support Center"],
                        },
                        {
                            month: "08",
                            sub: ["Signing SentinelOne Distributor Contract"],
                        },
                        {
                            month: "03",
                            sub: ["SigningSAMSUNG SDS Partner Contract", "CyberArk Partner Contract"],
                        },
                    ],
                },
                {
                    year: 2020,
                    image: "/assets/images/history/2020.png",
                    sub: [
                        {
                            month: "12",
                            sub: ["Signing SentinelOne Partner Contract"],
                        },
                        {
                            month: "11",
                            sub: ["Signing Fortanix Partner Contract"],
                        },
                        {
                            month: "09",
                            sub: ["Kyung-won Chong Takes Office as New CEO", "Signing Fortinet Partner Contract"],
                        },
                        {
                            month: "02",
                            sub: ["Signing Trend Micro Partner Contract"],
                        },
                    ],
                },
                {
                    year: 2019,
                    image: "",
                    sub: [
                        {
                            month: "10",
                            sub: ["Development and Supply of Terminal Integrated Security Solutions"],
                        },
                    ],
                },
                {
                    year: 2018,
                    image: "",
                    sub: [
                        {
                            month: "08",
                            sub: ["Signing ForeScout Partners"],
                        },
                        {
                            month: "01",
                            sub: ["Development and Supply of Power Pack Cloud Service (ASP Business)"],
                        },
                    ],
                },
                {
                    year: 2017,
                    image: "",
                    sub: [
                        {
                            month: "02",
                            sub: [
                                "Signing overseas sales partner contract: Contract of Vision Create Co., Ltd. in Japan",
                                "Development and Supply of Power Pack Mac Agent",
                            ],
                        },
                        {
                            month: "01",
                            sub: ["Start Symatec Blucote Network Security Business"],
                        },
                    ],
                },
                {
                    year: 2016,
                    image: "/assets/images/history/2016.png",
                    sub: [
                        {
                            month: "03",
                            sub: ["Development and Supply of Server DLP Solution"],
                        },
                        {
                            month: "01",
                            sub: ["Symantec Platinum Partner Certification"],
                        },
                    ],
                },
                {
                    year: 2015,
                    image: "",
                    sub: [
                        {
                            month: "09",
                            sub: ["Accredited Ahnlab Authorized Partner Certification"],
                        },
                        {
                            month: "06",
                            sub: ["Print Output Security Software GS Certification"],
                        },
                        {
                            month: "03",
                            sub: ["Development of Output Security System Solutions"],
                        },
                        {
                            month: "01",
                            sub: ["Symantec Gold Partner Certification"],
                        },
                    ],
                },
                {
                    year: 2014,
                    image: "",
                    sub: [
                        {
                            month: "09",
                            sub: ["VMWare Partner Certification"],
                        },
                        {
                            month: "02",
                            sub: ["Relocated to the location of No. 31, 13/F, 33, International Finance-ro 6-gil, Yeongdeungpo-gu, Seoul (Yeouido-dong, Manhattan Building)"]
                        }
                    ],
                },
                {
                    year: 2013,
                    image: "/assets/images/history/2013.jpg",
                    sub: [
                        {
                            month: "06",
                            sub: ["Development of Media Control Exception Approval System Solution"],
                        },
                        {
                            month: "04",
                            sub: ["Certification of venture company"],
                        },
                        {
                            month: "02",
                            sub: ["Development of File Export Approval System Solutions", "Registration of a MicroSoft Partner"],
                        },
                        {
                            month: "01",
                            sub: ["Establishment of R&D department", "Symantec Silver Partner Certification"],
                        },
                    ],
                },
                {
                    year: 2012,
                    image: "/assets/images/history/2012.jpg",
                    sub: [
                        {
                            month: "11",
                            sub: [
                                "Development of Personal Information Retrieval Solutions (SDLP)",
                                "Symantec Korea S-DLP Professional Partner Certification",
                            ],
                        },
                        {
                            month: "09",
                            sub: ["Mokafive Partner Certification"],
                        },
                        {
                            month: "08",
                            sub: ["Dell Korea Partner Certification", "capital increase to 80 million won"],
                        },
                        {
                            month: "07",
                            sub: [
                                "Symantec Partner Certification",
                                "Established ESCARE Co., Ltd. at the location of No. 303 Bi-dong (Yeouido-dong, Daewoo Trump World 1st) on the 3rd floor of 143, Yeouido-ro, Yeongdeungpo-gu, Seoul",
                            ],
                        },
                    ],
                },
            ],
        };
    } else {
        historyText = {
            title: "연혁",
            description: "현재 ~ 2012",
            sub: [
                {
                    year: 2022,
                    image: "/assets/images/history/2022.jpg",
                    sub: [
                        {
                            month: "06",
                            sub: ["제3 오피스 오픈 / 제주도 창업지원센타 내"],
                        },
                        {
                            month: "05",
                            sub: ["ZT-VPN 출시"],
                        },
                        {
                            month: "02",
                            sub: ["QuestSoftware 파트너 계약 체결"],
                        },
                    ],
                },
                {
                    year: 2021,
                    image: "/assets/images/history/2021.png",
                    sub: [
                        {
                            month: "10",
                            sub: ["제2 오피스 오픈 / 숭실대학교 창업지원센타 내"],
                        },
                        {
                            month: "08",
                            sub: ["SentinelOne 총판 계약 체결"],
                        },
                        {
                            month: "03",
                            sub: ["SAMSUNG SDS 파트너 계약 체결", "CyberArk 파트너 계약 체결"],
                        },
                    ],
                },
                {
                    year: 2020,
                    image: "/assets/images/history/2020.png",
                    sub: [
                        {
                            month: "12",
                            sub: ["SentinelOne 파트너 계약 체결"],
                        },
                        {
                            month: "11",
                            sub: ["Fortanix 파트너 계약 체결 (지사대행)"],
                        },
                        {
                            month: "09",
                            sub: ["정경원 신임 대표이사 취임", "Fortinet 파트너 계약 체결"],
                        },
                        {
                            month: "02",
                            sub: ["TrendMicro 파트너 계약 체결"],
                        },
                    ],
                },
                {
                    year: 2019,
                    image: "",
                    sub: [
                        {
                            month: "10",
                            sub: ["단말 통합보안 솔루션 개발 및 공급"],
                        },
                    ],
                },
                {
                    year: 2018,
                    image: "",
                    sub: [
                        {
                            month: "08",
                            sub: ["ForeScout 파트너 체결"],
                        },
                        {
                            month: "01",
                            sub: ["파워팩 클라우드서비스 개발 공급 (ASP사업)"],
                        },
                    ],
                },
                {
                    year: 2017,
                    image: "",
                    sub: [
                        {
                            month: "02",
                            sub: [
                                "해외판매파트너 계약 : 자사제품 일본 총판 파트너(Vision Create Co., Ltd.)사 계약",
                                "파워팩 Mac Agent 개발공급",
                            ],
                        },
                        {
                            month: "01",
                            sub: ["Symatec Blucote 네트워크 보안사업 시작"],
                        },
                    ],
                },
                {
                    year: 2016,
                    image: "/assets/images/history/2016.png",
                    sub: [
                        {
                            month: "03",
                            sub: ["서버DLP 솔루션 개발공급"],
                        },
                        {
                            month: "01",
                            sub: ["Symantec 플래티넘파트너 인증"],
                        },
                    ],
                },
                {
                    year: 2015,
                    image: "",
                    sub: [
                        {
                            month: "09",
                            sub: ["안랩(Ahnlab) 공인파트너 인증"],
                        },
                        {
                            month: "06",
                            sub: ["프린트출력보안 소프트웨어 GS인증"],
                        },
                        {
                            month: "03",
                            sub: ["출력물보안 시스템 솔루션 개발"],
                        },
                        {
                            month: "01",
                            sub: ["Symantec 골드파트너 인증"],
                        },
                    ],
                },
                {
                    year: 2014,
                    image: "",
                    sub: [
                        {
                            month: "09",
                            sub: ["VMWare 파트너 인증"],
                        },
                        {
                            month: "02",
                            sub: ["본점을 서울 영등포구 국제금융로6길33, 13층 31,32호 (여의도동,맨하탄빌딩)소재로 이전"]
                        }
                    ],
                },
                {
                    year: 2013,
                    image: "/assets/images/history/2013.jpg",
                    sub: [
                        {
                            month: "06",
                            sub: ["매체제어 예외승인시스템 솔루션 개발"],
                        },
                        {
                            month: "04",
                            sub: ["벤처기업 인증"],
                        },
                        {
                            month: "02",
                            sub: ["파일반출 승인시스템 솔루션 개발", "MicroSoft 파트너 등록"],
                        },
                        {
                            month: "01",
                            sub: ["연구개발부서 설립", "Symantec 실버파트너 인증"],
                        },
                    ],
                },
                {
                    year: 2012,
                    image: "/assets/images/history/2012.jpg",
                    sub: [
                        {
                            month: "11",
                            sub: [
                                "개인정보검색 솔루션 개발(SDLP)",
                                "Symantec Korea S-DLP 전문파트너 인증",
                            ],
                        },
                        {
                            month: "09",
                            sub: ["Mokafive 파트너 인증"],
                        },
                        {
                            month: "08",
                            sub: ["Dell Korea 파트너 인증", "자본금을 80백만원으로 증자"],
                        },
                        {
                            month: "07",
                            sub: [
                                "Symantec 파트너 인증",
                                "서울 영등포구 여의동로 143, 3층 비동 303호(여의도동, 대우트럼프월드1차) 소재에서 (주)에스케어 설립[납입자본금:20백만원]",
                            ],
                        },
                    ],
                },
            ],
        };
    }

    return (
        <>
            <div className={styles.cont}>
                <div className={styles.logoLine}>
                    <div className={styles.headerText}>{historyText.title}</div>
                    <div className={styles.logoDescription}>{historyText.description}</div>
                </div>
                <div className={styles.historySection}>
                    <div className={styles.historyLine}>
                        <div className={styles.historyDetailLine}>
                            <div className={styles.historyFirst}/>
                            <div className={styles.historySecond}>
                                <div className={styles.historySecondBlank} style={{height: 50}}>
                                    <div className={styles.half}/>
                                    <div className={styles.half}/>
                                </div>
                            </div>
                            <div className={styles.historyThird}/>
                        </div>
                    </div>
                    {historyText.sub.map((depthOne, index) => (
                        <div key={index} className={styles.historyLine}>
                            <div className={styles.historyDetailLine}>
                                <div className={styles.historyFirst}>
                                    <div className={styles.historyFirstYear}>{depthOne.year}</div>
                                    {depthOne.image !== "" && (
                                        <div className={styles.historyFirstImage}>
                                            <img src={depthOne.image} alt={depthOne.image}/>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.historySecond}>
                                    <div className={styles.historySecondBlank}>
                                        <div className={styles.half}/>
                                        <div className={styles.half}/>
                                    </div>
                                    <div className={styles.historySecondCircle}>
                                        <div className={styles.circle}/>
                                    </div>
                                    <div className={styles.historySecondBlank}>
                                        <div className={styles.half}/>
                                        <div className={styles.half}/>
                                    </div>
                                </div>
                                <div className={styles.historyThird}/>
                            </div>
                            <div className={styles.historyDetailLine}>
                                <div className={styles.historyFirst}>
                                    {depthOne.sub.map(depthTwo => (
                                        <>
                                            <div className={styles.historyContentBox}>
                                                <div className={styles.historyFirstMonth}>
                                                    {depthTwo.month}
                                                </div>
                                                <div className={styles.historyFirstContent}>
                                                    {depthTwo.sub.map(depthThree => (
                                                        <>
                                                            <div
                                                                className={styles.historyFirstText}>
                                                                {depthThree}
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                    <div className={styles.historyFirstMargin}/>
                                </div>
                                <div className={styles.historySecond}>
                                    <div className={styles.historySecondBlankOne}>
                                        <div className={styles.half}/>
                                        <div className={styles.half}/>
                                    </div>
                                </div>
                                <div className={styles.historyThird}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
