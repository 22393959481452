import React from "react";
import styles from "./ConsultingPage.module.scss";
import {ConsultingHeader} from "./header/consultingHeader";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";
import {I18nValue} from "../../components/system/i18nValue/I18nValue";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export const ConsultingPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: IConsultingText;
    if (langCode === "en") {
        textList = {
            title: "Consulting Service",
            description1: "We provide security consulting for customers for an efficient work environment.",
            description2: "We will repay you with the best consulting for security infrastructure construction inquiries from endpoints to networks and servers.",
            sub: [
                {
                    title: "User Security",
                    description: [
                        "Prevention of information leakage by controlling the media use of the external leakage blocking device.",
                        "Advanced patch system configuration support."
                    ]
                },
                {
                    title: "Network Security",
                    description: [
                        "Configure an intelligent firewall system and detect and block inflow of malicious code.",
                        "Support for advanced security system configuration in a telecommuting environment."
                    ]
                },
                {
                    title: "Server Security",
                    description: [
                        "Manage user accounts and access to the server.",
                        "Collection and analysis of intrusion indicators in the server.",
                        "Server system and application patch configuration support."
                    ]
                },
                {
                    title: "Cloud & Virtualization",
                    description: [
                        "Providing cloud and virtualization security consulting."
                    ]
                },
                {
                    title: "Network Separation Security",
                    description: [
                        "Malicious code detection and management system configuration support during data transmission between networks.",
                        "Optimization of network connection configuration plan and support for security system configuration."
                    ]
                },
                {
                    title: "Integrated Security Control",
                    description: [
                        "Provides real-time understanding of information risks through integrated control of security.",
                        "Supports XDR, MDR, firewall log consolidation collection and SOAR configuration."
                    ]
                }
            ]
        };
    } else {
        textList = {
            title: "컨설팅 서비스",
            description1: "효율적인 업무 환경을 위하여 고객을 위한 보안컨설팅을 제공합니다.",
            description2:
                "엔드포인트에서 네트워크, 서버의 보안인프라 구축문의에 대해 최고의 컨설팅으로 보답 드리겠습니다.",
            sub: [
                {
                    title: "사용자 보안",
                    description: [
                        "외부유출 차단 단말의 매체사용을 제어하여 정보유출을 예방 보유한 정보파일에 대한 소명 관리 체계 구성 지원",
                        "진보된 패치 시스템 구성 지원",
                    ],
                },
                {
                    title: "네트워크 보안",
                    description: [
                        "지능형 방화벽 체계 구성 및 악성코드 유입 탐지 및 차단",
                        "재택근무 환경에서 고도화된 보안체계 구성지원",
                    ],
                },
                {
                    title: "서버보안",
                    description: [
                        "서버에 접근하는 사용자 계정 및 접근 관리",
                        "서버 내 침해지표 수집 및 분석",
                        "서버 시스템 및 어플리케이션 패치 구성 지원",
                    ],
                },
                {
                    title: "클라우드 & 가상화",
                    description: ["클라우드와 가상화의 보안 컨설팅 제공"],
                },
                {
                    title: "망분리보안",
                    description: [
                        "망 간 자료전송시 악성코드 탐지 및 관리체계 구성지원",
                        "망연계 구성방안 최적화 및 보안체계 구성 지원",
                    ],
                },
                {
                    title: "보안통합관제",
                    description: [
                        "보안에 대한 통합관제를 통한 정보 위험을 실시간으로 파악할 수 있도록 제공",
                        "XDR, MDR, 방화벽 로그 통합 수집 및 SOAR 구성 지원",
                    ],
                },
            ],
        };
    }
    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{textList.title}</div>
            </div>
            <div className={styles.cont}>
                <div className={styles.descriptionLine}>
                    <div className={styles.description}>{textList.description1}</div>
                    <div className={styles.description}>{textList.description2}</div>
                </div>
                <div className={styles.imageLine}/>
                <div className={styles.gridLine}>
                    {textList.sub &&
                        textList.sub?.map((value, index) => (
                            <div key={index} className={styles.gridBox}>
                                <div className={styles.title}>{value.title}</div>
                                {value.description.map((desc, index) => (
                                    <div key={index} className={styles.description}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            style={{marginRight: 5}}
                                        />
                                        {desc}
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

interface IConsultingText {
    title: string;
    description1: string;
    description2: string;
    sub?: IConsultingSubText[];
}

interface IConsultingSubText {
    title: string;
    description: string[];
}
