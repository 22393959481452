import React, { useState } from "react";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";
import {
    Mutation,
    DirectNoticeInput,
    Query,
    QueryDirectNoticeListArgs,
    MutationCreateDirectNoticeArgs,
    MutationUpdateDirectNoticeArgs,
    MutationDeleteDirectNoticeArgs,
} from "../../../graphql/graphql";
import { useParams } from "react-router-dom";
import { Pager } from "../../../components/pagination/Pager";
import { TableBtnView } from "../../../components/table/btn/TableBtnView";
import { DefaultModal } from "../../../components/modal/default/DefaultModal";
import { InputDirectNoticeView } from "./inputDirectNotice/InputDirectNoticeView";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { DirectNoticeListView } from "./list/DirectNoticeListView";

export const DashboardPopup = () => {
    const { page, size } = useParams();
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const { data, refetch } = useQuery<Query, QueryDirectNoticeListArgs>(gqlData, {
        variables: {
            page: Number(page || 0),
            size: Number(size || 0),
            langCode,
        },
    });
    const initDirectNoticeInput: DirectNoticeInput = {
        langCode,
        content: "",
        urgent: true,
    };

    const [createDirectNotice, setCreateDirectNotice] = useState<DirectNoticeInput | undefined>();
    const [updateDirectNoticeInput, setUpdateDirectNoticeInput] = useState<
        UpdateDirectNoticeInput | undefined
    >(undefined);

    const [createDirectNoticeMutation] = useMutation<Mutation, MutationCreateDirectNoticeArgs>(
        gqlCreateDirectNotice,
    );
    const [updateDirectNoticeMutation] = useMutation<Mutation, MutationUpdateDirectNoticeArgs>(
        gqlUpdateDirectNotice,
    );
    const [deleteDirectNoticeMutation] = useMutation<Mutation, MutationDeleteDirectNoticeArgs>(
        gqlDeleteDirectNotice,
    );

    const handler = {
        onChangePage: (p: number) => {
            return;
        },
        onClickCreateDirectNotice: () => {
            if (!createDirectNotice) {
                return;
            }

            createDirectNoticeMutation({
                variables: {
                    input: createDirectNotice,
                },
            })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    setCreateDirectNotice(undefined);

                    return SwalUtils.ok({
                        msg: "등록 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        onClickUpdateModal: (value: UpdateDirectNoticeInput) => {
            setUpdateDirectNoticeInput(value);
        },
        onClickUpdateDirectNotice: () => {
            if (!updateDirectNoticeInput) {
                return;
            }
            updateDirectNoticeMutation({
                variables: {
                    id: updateDirectNoticeInput.id,
                    input: {
                        ...updateDirectNoticeInput.input,
                    },
                },
            })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    setUpdateDirectNoticeInput(undefined);

                    return SwalUtils.ok({
                        msg: "수정 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        onClickDeleteDirectNotice: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return deleteDirectNoticeMutation({
                        variables: {
                            id,
                        },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            <DashboardLayout router={"/dashboard/popup"}>
                {data && (
                    <>
                        <h4>팝업</h4>
                        <p>* 메인 페이지에는 언어별, 활성화된 건만 표시됩니다.</p>
                        <DirectNoticeListView
                            directNoticeList={data.directNoticeList.list}
                            onClickUpdateModal={handler.onClickUpdateModal}
                            onClickDeleteDirectNotice={handler.onClickDeleteDirectNotice}
                        />
                        <TableBtnView>
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={() => setCreateDirectNotice(initDirectNoticeInput)}>
                                추가
                            </button>
                        </TableBtnView>
                        <Pager
                            page={data.directNoticeList.page}
                            size={data.directNoticeList.size}
                            total={data.directNoticeList.total}
                            onChange={handler.onChangePage}
                        />
                    </>
                )}

                <DefaultModal
                    title={"팝업 추가"}
                    onClose={() => setCreateDirectNotice(undefined)}
                    open={Boolean(createDirectNotice)}>
                    {createDirectNotice && (
                        <>
                            <InputDirectNoticeView
                                value={createDirectNotice}
                                onChangeValue={setCreateDirectNotice}
                            />
                            <TableBtnView>
                                <button
                                    onClick={handler.onClickCreateDirectNotice}
                                    className="btn btn-sm btn-primary">
                                    저장하기
                                </button>
                            </TableBtnView>
                        </>
                    )}
                </DefaultModal>

                <DefaultModal
                    title={"팝업 수정"}
                    onClose={() => setUpdateDirectNoticeInput(undefined)}
                    open={Boolean(updateDirectNoticeInput)}>
                    {updateDirectNoticeInput && (
                        <>
                            <InputDirectNoticeView
                                value={updateDirectNoticeInput.input}
                                onChangeValue={input =>
                                    setUpdateDirectNoticeInput({
                                        ...updateDirectNoticeInput,
                                        input,
                                    })
                                }
                            />
                            <TableBtnView>
                                <button
                                    onClick={handler.onClickUpdateDirectNotice}
                                    className="btn btn-sm btn-primary">
                                    저장하기
                                </button>
                            </TableBtnView>
                        </>
                    )}
                </DefaultModal>
            </DashboardLayout>
        </>
    );
};

export type UpdateDirectNoticeInput = {
    id: string;
    input: DirectNoticeInput;
};

const gqlData = gql`
    query DirectNoticeList($langCode: String!, $page: Int!, $size: Int!) {
        directNoticeList(langCode: $langCode, page: $page, size: $size) {
            page
            size
            total
            list {
                id
                content
                langCode
                urgent
            }
        }
    }
`;

const gqlCreateDirectNotice = gql`
    mutation createDirectNotice($input: DirectNoticeInput!) {
        createDirectNotice(input: $input)
    }
`;

const gqlUpdateDirectNotice = gql`
    mutation updateDirectNotice($id: String!, $input: DirectNoticeInput!) {
        updateDirectNotice(id: $id, input: $input)
    }
`;

const gqlDeleteDirectNotice = gql`
    mutation deleteDirectNotice($id: String!) {
        deleteDirectNotice(id: $id)
    }
`;
