import React from "react";
import {InputIndexInfoBar} from "../../../../../graphql/graphql";
import {OnChangeValue} from "../../../../../graphql/types";
import {TableBtnView} from "../../../../../components/table/btn/TableBtnView";
import {InputIndexInfoBarView} from "../inputIndexInfoBar/InputIndexInfoBarView";

export const IndexInfoBarCreateModal = (props: {
    value: InputIndexInfoBar;
    onChangeValue: OnChangeValue<InputIndexInfoBar>;
    onAppendIndexInfoBar: OnChangeValue<InputIndexInfoBar>;
}) => {
    return (
        <>
            <InputIndexInfoBarView value={props.value} onChangeValue={props.onChangeValue} />

            <TableBtnView>
                <button
                    className="btn btn-primary"
                    onClick={() => props.onAppendIndexInfoBar(props.value)}>
                    추가
                </button>
            </TableBtnView>
        </>
    );
}
