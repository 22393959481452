import React from "react";
import styles from "./AuthLoginForm.module.scss";
import { useNavigate } from "react-router-dom";
import { AuthLoginState } from "../AuthLoginPage";
import { OnChangeValue, VoidFunction } from "../../../../graphql/types";

export const AuthLoginForm = (props: {
    value: AuthLoginState;
    onChangeValue: OnChangeValue<AuthLoginState>;
    onClickLogin: VoidFunction;
}) => {
    const navigate = useNavigate();

    const handler = {
        onClickJoin: () => {
            navigate("/auth/join");
        },
    };
    return (
        <div className={styles.cont}>
            <form
                onSubmit={ev => {
                    ev.preventDefault();
                    props.onClickLogin();
                }}
                className={styles.box}>
                <h4>로그인</h4>
                <input
                    value={props.value.id}
                    onChange={ev => props.onChangeValue({ ...props.value, id: ev.target.value })}
                    type="text"
                    placeholder="아이디"
                    className="form-control form-control-sm"
                />

                <input
                    value={props.value.pw}
                    onChange={ev => props.onChangeValue({ ...props.value, pw: ev.target.value })}
                    type="password"
                    placeholder="비밀번호"
                    className="form-control form-control-sm"
                />

                <button className="btn btn-sm btn-primary">로그인</button>

                <button
                    type="button"
                    onClick={handler.onClickJoin}
                    className="btn btn-sm btn-outline-primary">
                    회원가입
                </button>
            </form>
        </div>
    );
};
