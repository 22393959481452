import React from "react";
import styles from "./ProductDetailHeader.module.scss";
import { Product } from "../../../../graphql/graphql";
import { I18nUtil } from "../../../../services/i18n/i18n";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../../globalVars/globalVars";
import { FileManager } from "../../../../services/fileManager/FileManager";
import classNames from "classnames";

export const ProductDetailHeader = (props: { value: Product }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);
    const value = props.value;

    const handler = {
        onClickBrochure: () => {
            const link = I18nUtil.value(langCode, value.brochureLink);
            if (!link) {
                return;
            }
            FileManager.downloadFile(link);
        },
        onClickHomepage: () => {
            const href = I18nUtil.value(langCode, props.value.homepage);
            if (href) {
                window.open(href, "_blank");
            }
        },
    };

    let textList = {
        brochure: "",
        homepage: "",
    };

    if (langCode === "en") {
        textList = {
            brochure: "Download brochure",
            homepage: "Homepage",
        };
    } else {
        textList = {
            brochure: "브로셔 다운로드",
            homepage: "홈페이지",
        };
    }

    return (
        <div className={styles.cont}>
            <div className={styles.img}>
                <img src={I18nUtil.getValue(value.backgroundSrc, langCode)} alt={"배경 이미지"} />
            </div>
            <div className={styles.boxInfo}>
                <div
                    className={styles.header}
                    ref={ref => {
                        if (ref) {
                            ref.innerHTML = I18nUtil.value(langCode, value.header);
                        }
                    }}
                />
                <div
                    className={styles.subHeader}
                    ref={ref => {
                        if (ref) {
                            ref.innerHTML = I18nUtil.value(langCode, value.subHeader);
                        }
                    }}
                />

                <div className={styles.btnBox}>
                    {I18nUtil.value(langCode, value.brochureLink) && (
                        <div
                            onClick={handler.onClickBrochure}
                            className={classNames(styles.btn, styles.btnMainColor)}>
                            {textList.brochure}
                        </div>
                    )}
                    {I18nUtil.value(langCode, value.homepage) && (
                        <div
                            onClick={handler.onClickHomepage}
                            className={classNames(styles.btn, styles.btnWhiteColor)}>
                            {textList.homepage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
