import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import React, { ReactNode, useState } from "react";
import { TopNavBar } from "../Layout";
import { MobileHeader } from "./header/MobileHeader";
import { MobileSideBar } from "./header/sideBar/MobileSideBar";

export const LayoutMobile = (props: { data: TopNavBar[]; children: ReactNode }) => {
    const [isOpenLeftDrawer, setIsOpenLeftDrawer] = useState(false);

    const handler = {
        onChange: (value: boolean) => {
            setIsOpenLeftDrawer(value);
        },
    };

    return (
        <>
            <SwipeableDrawer
                anchor="left"
                open={isOpenLeftDrawer}
                onClose={() => setIsOpenLeftDrawer(false)}
                onOpen={() => setIsOpenLeftDrawer(true)}>
                <MobileSideBar topNavBar={props.data} onChange={handler.onChange} />
            </SwipeableDrawer>

            <MobileHeader onChange={handler.onChange} />
            {props.children}
        </>
    );
};
