import React from "react";
import { InputIndexCarousel } from "../../../../../graphql/graphql";
import { TableBtnView } from "../../../../../components/table/btn/TableBtnView";
import { OnChangeValue } from "../../../../../graphql/types";
import { InputIndexCarouselView } from "../inputIndexCarousel/InputIndexCarouselView";

export const IndexCarouselCreateModal = (props: {
    value: InputIndexCarousel;
    onChangeValue: OnChangeValue<InputIndexCarousel>;
    onAppendIndexCarousel: OnChangeValue<InputIndexCarousel>;
}) => {
    return (
        <>
            <InputIndexCarouselView value={props.value} onChangeValue={props.onChangeValue} />

            <TableBtnView>
                <button
                    className="btn btn-primary"
                    onClick={() => props.onAppendIndexCarousel(props.value)}>
                    추가
                </button>
            </TableBtnView>
        </>
    );
};
