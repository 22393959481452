import React from "react";
import styles from "./PartnersPage.module.scss";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";

export const PatnersPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = {
        title: "",
        subtitle: "",
    };

    if (langCode === "en") {
        textList = {
            title: "Partners",
            subtitle: "Contact Partner Alliance",
        };
    } else {
        textList = {
            title: "에스케어 파트너사",
            subtitle: "파트너 제휴 문의"
        };
    }
    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{textList.title}</div>
            </div>
            <div className={styles.partnersContainer}>
                <div className={styles.partnersBox}>
                    <div className={styles.partnersLine}>
                        {partnerImage.map((value, index) => (
                            <div key={index} className={styles.partner}>
                                <img src={value} alt={value}/>
                            </div>
                        ))}
                    </div>
                    <div className={styles.contactBox}>
                        <div className={styles.title}>{textList.subtitle}</div>
                        <div>E-mail : escare1@escare.co.kr</div>
                        <div>Phone : 82) 70-4352-7333</div>
                        <div>Blog : https://blog.naver.com/escare1</div>
                    </div>
                </div>
                <div className={styles.partnersBackground}>

                </div>
            </div>
        </>
    );
};

const partnerImage = [
    "/assets/images/logo/symantec.png",
    "/assets/images/logo/cyberark.png",
    "/assets/images/logo/fortanix.png",
    "/assets/images/logo/fortinet.png",
    "/assets/images/logo/imperva.png",
    "/assets/images/logo/neushield.png",
    "/assets/images/logo/proofpoint.png",
    "/assets/images/logo/quest.png",
    "/assets/images/logo/recordedfuture.png",
    "/assets/images/logo/sentinelone.png",
    "/assets/images/logo/tanium.png",
    "/assets/images/logo/trendmicro.png",
];
