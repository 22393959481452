import React from "react";
import {InputNews, NewsType} from "../../../../graphql/graphql";
import styles from "./InputNewsView.module.scss";
import {OnChangeValue} from "../../../../graphql/types";
import classNames from "classnames";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../../globalVars/globalVars";
import {InputMultiLang} from "../../../../components/input/multiLang/InputMultiLang";
import {FileManager, FileType} from "../../../../services/fileManager/FileManager";
import {ApolloCatch} from "../../../../services/apollo/apolloCatch";
import {Pipe} from "../../../../services/pipe/Pipe";

export const InputNewsView = (props: {
    value: InputNews;
    onChangeValue: OnChangeValue<InputNews>;
}) => {
    const langCodeList = useReactiveVar(GlobalVars.i18n.langCodeList);

    const handler = {
        onUpload: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(imgSrc => {
                    props.onChangeValue({...props.value, imgSrc});
                })
                .catch(ApolloCatch({}));
        },
        onUploadImage: () => {
            FileManager.uploadFile(FileType.IMAGE)
                .then(filePath => {
                    props.onChangeValue({
                        ...props.value,
                        contents: props.value.contents + `<img src="${filePath}" alt="img />`
                    });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>제목</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <input
                        value={props.value.title}
                        onChange={ev =>
                            props.onChangeValue({...props.value, title: ev.target.value})
                        }
                        className="form-control form-control-sm"
                    />
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>언어</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <select
                        className="form-control form-control-sm"
                        value={props.value.langCode}
                        onChange={ev =>
                            props.onChangeValue({...props.value, langCode: ev.target.value})
                        }>
                        {langCodeList.map((value, index) => (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>게시판</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <select
                        className="form-control form-control-sm"
                        value={props.value.newsType}
                        onChange={ev =>
                            props.onChangeValue({
                                ...props.value,
                                newsType: Pipe.toNewsType(ev.target.value),
                            })
                        }>
                        <option value={NewsType.Escare}>에스케어</option>
                        <option value={NewsType.Product}>제품</option>
                        <option value={NewsType.Letter}>뉴스레터</option>
                    </select>
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>발행</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <input
                        value={props.value.newsLabel}
                        onChange={ev =>
                            props.onChangeValue({...props.value, newsLabel: ev.target.value})
                        }
                        className="form-control form-control-sm"
                    />
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>뱃지</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <InputMultiLang
                        value={props.value.badge}
                        onChangeValue={badge => props.onChangeValue({...props.value, badge})}
                    />
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>썸네일</div>
                <div className={classNames(styles.col, styles.colPath)}>
                    <div className="form-control form-control-sm">{props.value.imgSrc}</div>
                </div>
                <div className={classNames(styles.col, styles.colFunc)}>
                    <button onClick={handler.onUpload} className="btn btn-sm btn-outline-primary">
                        업로드
                    </button>
                </div>
            </div>

            <div className={classNames(styles.table, styles.newsTable)}>
                <div className={classNames(styles.col, styles.colLabel)}>내용 (HTML 코드)</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    <textarea
                        className="form-control form-control-sm"
                        value={props.value.contents}
                        onChange={ev =>
                            props.onChangeValue({...props.value, contents: ev.target.value})
                        }
                    />
                    <div
                        className="text-right"
                        style={{ marginTop: 10, width: "100%", textAlign: "right" }}>
                        <button
                            onClick={() => handler.onUploadImage()}
                            className="btn btn-sm btn-outline-secondary">
                            이미지 업로드
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
