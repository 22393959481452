import React, { ReactNode } from "react";
import styles from "./TableBtnView.module.scss";
import classNames from "classnames";

export const TableBtnView = (props: { children: ReactNode }) => {
    return (
        <div className={classNames(styles.table, styles.cont)}>
            <div className={styles.col} style={{ width: "100%", textAlign: "right" }}>
                {props.children}
            </div>
        </div>
    );
};
