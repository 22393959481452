import React from "react";
import styles from "./DirectNoticeListView.module.scss";
import { DirectNotice } from "../../../../graphql/graphql";
import { OnChangeValue } from "../../../../graphql/types";
import { UpdateDirectNoticeInput } from "../DashboardPopup";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

export const DirectNoticeListView = (props: {
    directNoticeList: DirectNotice[];
    onClickUpdateModal: OnChangeValue<UpdateDirectNoticeInput>;
    onClickDeleteDirectNotice: OnChangeValue<string>;
}) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.index}>번호</div>
                    <div className={styles.langCode}>언어</div>
                    <div className={styles.urgent}>상태</div>
                    <div className={styles.function}>기능</div>
                </div>
                {props.directNoticeList.map((value, index) => (
                    <div className={styles.column} key={index}>
                        <div className={styles.index}>{index + 1}</div>
                        <div className={styles.langCode}>
                            {value.langCode === "en" ? "영어" : "한국어"}
                        </div>
                        <div className={styles.urgent}>{value.urgent ? "활성" : "비활성"}</div>
                        <div className={styles.function}>
                            <IconButton
                                onClick={() =>
                                    props.onClickUpdateModal({
                                        id: value.id,
                                        input: {
                                            langCode: value.langCode,
                                            content: value.content,
                                            urgent: value.urgent,
                                        },
                                    })
                                }>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => props.onClickDeleteDirectNotice(value.id)}>
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
