import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";

export const CategoryPage = () => {
    // const {data, refetch} = useQuery<Query, any>(gqlData);
    const {id} = useParams();

    const handler = {};

    return (

        <>
            <div style={{height: 100}}/>
            <h1>{id}</h1>
        </>
    )
}
//
// const gqlData = gql`
//     query DATA {
//     }
// `;
