import React from "react";
import styles from "./ProductDetailReference.module.scss";
import { Product } from "../../../../graphql/graphql";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../../globalVars/globalVars";

export const ProductDetailReference = (props: { value: Product }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = {
        title: "",
    };

    if (langCode === "en") {
        textList = {
            title: "References",
        };
    } else {
        textList = {
            title: "레퍼런스",
        };
    }

    return (
        <div className={styles.cont}>
            <div className={styles.header}>{textList.title}</div>
            <div className={styles.boxLogo}>
                {props.value.referenceLogo.map((value, index) => (
                    <img key={index} src={value} alt="logo" />
                ))}
            </div>
        </div>
    );
};
