import React, {useState} from "react";
import styles from "./LocationPage.module.scss";
import imgLogo from "../../assets/img/logo/escare_logo.png";
import {Map, MapMarker} from "react-kakao-maps-sdk";
import {Button} from "@material-ui/core";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap, faTrain} from "@fortawesome/free-solid-svg-icons";

interface locationType {
    locationName: string;
    address: string;
    into: string;
    lat: number;
    lng: number;
    href: string;
}

interface LocationTextListType {
    title: string;
    addressTitle: string;
    intoTitle: string;
    buttonText: string;
    first: string;
    second: string;
    third: string;
    location: locationType[];
}

export const LocationPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let locationTextList: LocationTextListType = {
        title: "",
        addressTitle: "",
        intoTitle: "",
        buttonText: "",
        first: "",
        second: "",
        third: "",
        location: [],
    };

    if (langCode === "en") {
        locationTextList = {
            title: "Location",
            addressTitle: "Address",
            intoTitle: "How to come",
            buttonText: "Find way",
            first: "Yeouido Headquarters",
            second: "Soongsil Univ. 2nd Office.",
            third: "Jeju Univ. 3rd Office.",
            location: [
                {
                    locationName: "ESCARE Co.",
                    address:
                        "Room 1331, Manhattan Building, 33, Gukjegeumyung-ro 6-gil, Yeongdeungpo-gu, Seoul",
                    into: "5 minutes on foot from Yeouido Station Exit 5",
                    lat: 37.52063635893664,
                    lng: 126.92699469474007,
                    href: "http://kko.to/QF25Os0KH",
                },
            ],
        };
    } else {
        locationTextList = {
            title: "찾아오는 길",
            addressTitle: "주소",
            intoTitle: "오시는 길",
            buttonText: "길찾기",
            first: "여의도 본사",
            second: "숭실대 2 오피스",
            third: "제주대 3 오피스",
            location: [
                {
                    locationName: "여의도 본사",
                    address: "서울특별시 영등포구 국제금융로 6길 33, 맨하탄빌딩 12/13층",
                    into: "여의도역 5번출구에서 도보로 5분 소요",
                    lat: 37.52063635893664,
                    lng: 126.92699469474007,
                    href: "http://kko.to/QF25Os0KH",
                },
                {
                    locationName: "숭실대 2 오피스",
                    address: "서울특별시 동작구 상도로 369 숭실대학교 창신관 209/210호",
                    into: "숭실대입구역 도보 10분",
                    lat: 37.49588302216985,
                    lng: 126.95853796755479,
                    href: "http://kko.to/zEqYLKw_5",
                },
                {
                    locationName: "제주대 3 오피스",
                    address: "제주시 중앙로 14길 21번지 제주대학교 창업보육센터 309호",
                    into: "제주국제공항에서 자가용 12분/버스 21분",
                    lat: 33.51078776837292,
                    lng: 126.52205024548962,
                    href: "http://kko.to/6ah6zLXVN",
                },
            ],
        };
    }

    const [tabIndex, setTabIndex] = useState<number>(0);

    const handler = {
        selectTab: (value: number) => {
            setTabIndex(value);
        }
    };

    const tabList = [
        <FirstTab locationTextList={locationTextList}/>,
        <SecondTab locationTextList={locationTextList}/>,
        <ThirdTab locationTextList={locationTextList}/>
    ];

    return (
        <>
            <div className={styles.cont}>
                <div className={styles.logoLine}>
                    <div className={styles.headerText}>{locationTextList.title}</div>
                </div>

                <div className={styles.tabs}>
                    <div className={tabIndex === 0 ? styles.tabActive : styles.tabDis} onClick={() => {
                        setTabIndex(0)
                    }}>{locationTextList.first}</div>
                    <div className={tabIndex === 1 ? styles.tabActive : styles.tabDis} onClick={() => {
                        setTabIndex(1)
                    }}>{locationTextList.second}</div>
                    <div className={tabIndex === 2 ? styles.tabActive : styles.tabDis} onClick={() => {
                        setTabIndex(2)
                    }}>{locationTextList.third}</div>
                </div>

                <div className={styles.contentArea}>
                    {tabList[tabIndex]}
                </div>
            </div>
        </>
    );
};

const FirstTab = (props: { locationTextList: LocationTextListType }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let value = {
        locationName: "",
        address: "",
        into: "",
        lat: 37.52063635893664,
        lng: 126.92699469474007,
        href: "http://kko.to/QF25Os0KH",
    };

    if (langCode === "en") {
        value = {
            locationName: "Yeouido Headquarters",
            address: "International Finance-ro 6-gil 33 12/13th floor of Manhattan Building, , Yeongdeungpo-gu, Seoul",
            into: "5 mimutes on foot from Yeouido Station Exit 5",
            lat: 37.52063635893664,
            lng: 126.92699469474007,
            href: "http://kko.to/QF25Os0KH",
        };
    } else {
        value = {
            locationName: "여의도 본사",
            address: "서울특별시 영등포구 국제금융로 6길 33, 맨하탄빌딩 12/13층",
            into: "여의도역 5번출구에서 도보로 5분 소요",
            lat: 37.52063635893664,
            lng: 126.92699469474007,
            href: "http://kko.to/QF25Os0KH",
        };
    }

    return <>
        <div className={styles.locationLine}>
            <div className={styles.mapBox}>
                <Map
                    center={{lat: value.lat, lng: value.lng}}
                    className={styles.map}>
                    <MapMarker position={{lat: value.lat, lng: value.lng}}>
                        <div style={{width: 150, textAlign: "center"}}>
                            <img
                                src={imgLogo}
                                style={{height: 25}}
                                alt="에스케어"
                            />
                        </div>
                    </MapMarker>
                </Map>
                <div className={styles.route}>
                    <a href={value.href} target={"_blank"} rel="noreferrer">
                        <Button variant={"outlined"}>
                            {props.locationTextList.buttonText}
                        </Button>
                    </a>
                </div>
            </div>
            <div className={styles.locationBox}>
                <div className={styles.nameBox}>
                    <div className={styles.name}>{value.locationName}</div>
                </div>
                <div className={styles.infoBox}>
                    <div className={styles.box}>
                        <div className={styles.title}>
                            <FontAwesomeIcon
                                icon={faMap}
                                style={{marginRight: 10}}
                            />
                            {props.locationTextList.addressTitle}
                        </div>
                        <div className={styles.description}>{value.address}</div>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <div className={styles.box}>
                        <div className={styles.title}>
                            <FontAwesomeIcon
                                icon={faTrain}
                                style={{marginRight: 10}}
                            />
                            {props.locationTextList.intoTitle}
                        </div>
                        <div className={styles.description}>{value.into}</div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

const SecondTab = (props: { locationTextList: LocationTextListType }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let value = {
        locationName: "숭실대 2 오피스",
        address: "서울특별시 동작구 상도로 369 숭실대학교 창신관 209/210호",
        into: "숭실대입구역 도보 10분",
        lat: 37.49588302216985,
        lng: 126.95853796755479,
        href: "http://kko.to/zEqYLKw_5",
    };

    if (langCode === "en") {
        value = {
            locationName: "Soongsil Univ. 2nd Office.",
            address: "Soongsil University ChangsinHall Room 209/210, 369 Sang-ro, Dongjak-gu, Seoul\n",
            into: "10 mimutes on foot from Soongsil University Station",
            lat: 37.49588302216985,
            lng: 126.95853796755479,
            href: "http://kko.to/zEqYLKw_5",
        };
    } else {
        value = {
            locationName: "숭실대 2 오피스",
            address: "서울특별시 동작구 상도로 369 숭실대학교 창신관 209/210호",
            into: "숭실대입구역 도보 10분",
            lat: 37.49588302216985,
            lng: 126.95853796755479,
            href: "http://kko.to/zEqYLKw_5",
        };
    }

    return (
        <>
            <div className={styles.locationLine}>
                <div className={styles.mapBox}>
                    <Map
                        center={{lat: value.lat, lng: value.lng}}
                        className={styles.map}>
                        <MapMarker position={{lat: value.lat, lng: value.lng}}>
                            <div style={{width: 150, textAlign: "center"}}>
                                <img
                                    src={imgLogo}
                                    style={{height: 25}}
                                    alt="에스케어"
                                />
                            </div>
                        </MapMarker>
                    </Map>
                    <div className={styles.route}>
                        <a href={value.href} target={"_blank"} rel="noreferrer">
                            <Button variant={"outlined"}>
                                {props.locationTextList.buttonText}
                            </Button>
                        </a>
                    </div>
                </div>
                <div className={styles.locationBox}>
                    <div className={styles.nameBox}>
                        <div className={styles.name}>{value.locationName}</div>
                    </div>
                    <div className={styles.infoBox}>
                        <div className={styles.box}>
                            <div className={styles.title}>
                                <FontAwesomeIcon
                                    icon={faMap}
                                    style={{marginRight: 10}}
                                />
                                {props.locationTextList.addressTitle}
                            </div>
                            <div className={styles.description}>{value.address}</div>
                        </div>
                    </div>
                    <div className={styles.infoBox}>
                        <div className={styles.box}>
                            <div className={styles.title}>
                                <FontAwesomeIcon
                                    icon={faTrain}
                                    style={{marginRight: 10}}
                                />
                                {props.locationTextList.intoTitle}
                            </div>
                            <div className={styles.description}>{value.into}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

const ThirdTab = (props: { locationTextList: LocationTextListType }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let value = {
        locationName: "제주대 3 오피스",
        address: "제주시 중앙로 14길 21번지 제주대학교 창업보육센터 309호",
        into: "제주국제공항에서 자가용 12분/버스 21분",
        lat: 33.51078776837292,
        lng: 126.52205024548962,
        href: "http://kko.to/6ah6zLXVN",
    };

    if (langCode === "en") {
        value = {
            locationName: "Jeju Univ. 3rd Office.",
            address: "Jeju National University Startup Center Room 309, 21 Jungang-ro 14-gil, Jeju",
            into: "12 minutes by car / 21 minutes by bus from Jeju International Airport",
            lat: 33.51078776837292,
            lng: 126.52205024548962,
            href: "http://kko.to/6ah6zLXVN",
        };
    } else {
        value = {
            locationName: "제주대 3 오피스",
            address: "제주시 중앙로 14길 21번지 제주대학교 창업보육센터 309호",
            into: "제주국제공항에서 자가용 12분/버스 21분",
            lat: 33.51078776837292,
            lng: 126.52205024548962,
            href: "http://kko.to/6ah6zLXVN",
        };
    }

    return (
        <>
            <div className={styles.locationLine}>
                <div className={styles.mapBox}>
                    <Map
                        center={{lat: value.lat, lng: value.lng}}
                        className={styles.map}>
                        <MapMarker position={{lat: value.lat, lng: value.lng}}>
                            <div style={{width: 150, textAlign: "center"}}>
                                <img
                                    src={imgLogo}
                                    style={{height: 25}}
                                    alt="에스케어"
                                />
                            </div>
                        </MapMarker>
                    </Map>
                    <div className={styles.route}>
                        <a href={value.href} target={"_blank"} rel="noreferrer">
                            <Button variant={"outlined"}>
                                {props.locationTextList.buttonText}
                            </Button>
                        </a>
                    </div>
                </div>
                <div className={styles.locationBox}>
                    <div className={styles.nameBox}>
                        <div className={styles.name}>{value.locationName}</div>
                    </div>
                    <div className={styles.infoBox}>
                        <div className={styles.box}>
                            <div className={styles.title}>
                                <FontAwesomeIcon
                                    icon={faMap}
                                    style={{marginRight: 10}}
                                />
                                {props.locationTextList.addressTitle}
                            </div>
                            <div className={styles.description}>{value.address}</div>
                        </div>
                    </div>
                    <div className={styles.infoBox}>
                        <div className={styles.box}>
                            <div className={styles.title}>
                                <FontAwesomeIcon
                                    icon={faTrain}
                                    style={{marginRight: 10}}
                                />
                                {props.locationTextList.intoTitle}
                            </div>
                            <div className={styles.description}>{value.into}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}
