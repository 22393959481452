import React, { Fragment } from "react";
import { MultiLang } from "../../../graphql/types";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";
import { I18nUtil } from "../../../services/i18n/i18n";

export const I18nValue = (props: { value: MultiLang }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    return <>{I18nUtil.value(langCode, props.value)}</>;
};
