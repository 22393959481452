import { createHmac } from "crypto";
import { v4 } from "uuid";

export class CryptoUtils {
    static newSalt(): string {
        debugger;
        return v4().toString();
    }

    static encrypt(pw: string, salt: string): string {
        return createHmac("sha256", salt).update(pw).digest("hex");
    }
}
