import React, { useState } from "react";
import styles from "./IndexSector.module.scss";
import { animated, Spring } from "react-spring";
import { IndexInfoBar, LinkType } from "../../../graphql/graphql";
import { I18nValue } from "../../../components/system/i18nValue/I18nValue";
import { LinkUtils } from "../../../services/link/LinkUtils";
import { useNavigate } from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../globalVars/globalVars";

type testListType = {
    more: string;
}

export const IndexSector = (props: { data: IndexInfoBar[] }) => {
    const navigate = useNavigate();
    const [selIdx, setSelIdx] = useState<number | undefined>();
    const bgColor = {
        show: "rgba(9,63,147,0.6)",
        hide: "rgba(0,0,0,0.3)",
    };

    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList: testListType;

    if (langCode === "en") {
        textList = {
            more: "View More"
        };
    } else {
        textList = {
            more: "자세히보기;"
        }
    }

    return (
        <div className={styles.cont}>
            {props.data.map((value, index) => (
                <Spring
                    key={index}
                    from={{ bgColor: bgColor.hide }}
                    to={{ bgColor: index === selIdx ? bgColor.show : bgColor.hide }}>
                    {params => (
                        <div
                            onMouseOver={() => setSelIdx(index)}
                            onMouseLeave={() => setSelIdx(undefined)}
                            className={styles.sector}>
                            <img className={styles.bg} src={value.bgUrl} alt="img" />
                            <animated.div
                                style={{
                                    backgroundColor: params.bgColor,
                                }}
                                className={styles.info}>
                                <img
                                    className={styles.icon}
                                    src={value.iconUrl}
                                    alt={"아이콘 이미지"}
                                />
                                <div className={styles.title}>
                                    <I18nValue value={value.title} />
                                </div>
                                <div className={styles.description}>
                                    <I18nValue value={value.label} />
                                </div>
                                {value.linkType !== LinkType.None && (
                                    <div
                                        className={styles.btn}
                                        onClick={() => {
                                            if (value.linkType === LinkType.Outer) {
                                                return LinkUtils.openNewWindow(value.link);
                                            } else {
                                                return navigate(value.link);
                                            }
                                        }}>
                                        {textList.more}
                                    </div>
                                )}
                            </animated.div>
                        </div>
                    )}
                </Spring>
            ))}
        </div>
    );
};
