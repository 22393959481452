import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { LocalStorage } from "../localstorage/LocalStorage";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { GlobalVars } from "../../globalVars/globalVars";
import { BizErrors } from "../../graphql/bizErrors";
import { SwalUtils } from "../swal/SwalUtils";

const apolloLink = from([
    setContext((operation, prevContext) => {
        return {
            headers: {
                ...prevContext.headers,
                langCode: GlobalVars.i18n.langCode(),
                Authorization: LocalStorage.getSession(),
            },
        };
    }),
    new ApolloLink((operation, forward) => {
        if (operation.getContext().hasOwnProperty("loadingView")) {
            GlobalVars.layout.loadingView(operation.getContext().loadingView);
        } else {
            GlobalVars.layout.loadingView(true);
        }

        if (operation.getContext().hasOwnProperty("header")) {
            operation.setContext({
                header: {
                    ...operation.getContext().header,
                },
            });
        }

        return forward(operation).map(data => {
            GlobalVars.layout.loadingView(false);
            return data;
        });
    }),
    onError(error => {
        if (!error.graphQLErrors) {
            return error.forward(error.operation);
        }

        for (const err of error.graphQLErrors) {
            if (err.message === BizErrors.needLogin) {
                if (GlobalVars.user.isLogin()) {
                    SwalUtils.ok({
                        msg: "로그인이 필요한 서비스 입니다.",
                        icon: "error",
                        ok: () => {
                            window.location.href = "/";
                        },
                    });
                }
                GlobalVars.user.isLogin(false);
                LocalStorage.clearSession();
                break;
            }
        }

        return error.forward(error.operation);
    }),
    createUploadLink({
        uri: process.env.REACT_APP_API || "",
    }) as any,
]);

export class Apollo {
    static client = new ApolloClient({
        link: apolloLink,
        connectToDevTools: false,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "all",
                nextFetchPolicy: "network-only",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
            mutate: {
                fetchPolicy: "network-only",
            },
        },
    });
}
