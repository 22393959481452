import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Query, QueryNewsArgs } from "../../graphql/graphql";
import { useParams } from "react-router-dom";
import { ArticleContent } from "./content/ArticleContent";
import {NewsHeader} from "../news/header/NewsHeader";

export const ArticlePage = () => {
    const { id } = useParams();
    const { data } = useQuery<Query, QueryNewsArgs>(gqlData, {
        variables: {
            id: id || "",
        },
    });

    return (
        <>
            {data && (
                <>
                    <NewsHeader />

                    <ArticleContent value={data.news} />
                </>
            )}
        </>
    );
};

const gqlData = gql`
    query DATA($id: String!) {
        news(id: $id) {
            id
            newsType
            title
            badge
            contents
            createdAt
            imgSrc
            langCode
            newsLabel
        }
    }
`;
