import React from "react";
import styles from "./IndexCarouselList.module.scss";
import { IndexCarousel } from "../../../../../graphql/graphql";
import classNames from "classnames";
import { NoDataTable } from "../../../../../components/table/none/NoDataTable";
import { I18nValue } from "../../../../../components/system/i18nValue/I18nValue";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { OnChangeValue } from "../../../../../graphql/types";
import { InputUpdateIndexCarousel } from "../../DashboardMainPage";

export const IndexCarouselList = (props: {
    data: IndexCarousel[];
    onClickUpdateIndexCarousel: OnChangeValue<InputUpdateIndexCarousel>;
    onClickRemoveIndexCarousel: OnChangeValue<string>;
}) => {
    return (
        <>
            <div className={classNames(styles.table, styles.tableList)}>
                <div className={classNames(styles.col, styles.colOrder)}>순서</div>
                <div className={classNames(styles.col, styles.colImage)}>이미지</div>
                <div className={classNames(styles.col, styles.colContents)}>내용</div>
                <div className={classNames(styles.col, styles.colFunc)}>기능</div>
            </div>

            {props.data.length === 0 && <NoDataTable />}

            {props.data.map((value, index) => (
                <div key={index} className={classNames(styles.table, styles.tableList)}>
                    <div className={classNames(styles.col, styles.colOrder)}>{value.order}</div>
                    <div className={classNames(styles.col, styles.colImage)}>
                        <img src={value.imgSrc} alt="img" />
                    </div>
                    <div className={classNames(styles.colContents, "text-left")}>
                        <div>
                            <I18nValue value={value.preHeader} />
                        </div>
                        <div>
                            <I18nValue value={value.header} />
                        </div>
                        <div>
                            <I18nValue value={value.description} />
                        </div>
                        <div>{value.link}</div>
                    </div>
                    <div className={classNames(styles.col, styles.colFunc)}>
                        <IconButton
                            onClick={() =>
                                props.onClickUpdateIndexCarousel({
                                    id: value.id,
                                    input: {
                                        ...value,
                                    },
                                })
                            }>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => props.onClickRemoveIndexCarousel(value.id)}>
                            <Delete />
                        </IconButton>
                    </div>
                </div>
            ))}
        </>
    );
};
