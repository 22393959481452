import React from "react";
import styles from "./ProductDetailSector.module.scss";
import { Product } from "../../../../graphql/graphql";
import { I18nUtil } from "../../../../services/i18n/i18n";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../../globalVars/globalVars";

export const ProductDetailSector = (props: { value: Product }) => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList = {
        header: "",
    };

    if (langCode === "en") {
        textList = {
            header: "",
        };
    } else {
        textList = {
            header: "도입효과",
        };
    }

    return (
        <div className={styles.cont}>
            <div className={styles.boxCard}>
                <div className={styles.header}>{textList.header}</div>
                {props.value.imposeEffect.map((value, index) => (
                    <div key={index} className={styles.card}>
                        <div
                            className={styles.title}
                            ref={ref => {
                                if (ref) {
                                    ref.innerHTML = I18nUtil.value(langCode, value.title);
                                }
                            }}></div>
                        <div
                            className={styles.description}
                            ref={ref => {
                                if (ref) {
                                    ref.innerHTML = I18nUtil.value(langCode, value.content);
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
