import React, { useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../graphql/graphql";
import { GlobalVars, UserVarUtils } from "../../../globalVars/globalVars";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { I18nUtil } from "../../../services/i18n/i18n";

export const LoginCheck = () => {
    const [mutGetAdmin] = useMutation<Mutation>(gqlAdmin);
    const [getData] = useLazyQuery<Query>(gqlInitData);

    useEffect(() => {
        mutGetAdmin()
            .then(res => {
                const id = res.data?.getAdmin.id || "";
                UserVarUtils.login(id);
            })
            .catch(ApolloCatch({}));

        getData()
            .then(res => {
                GlobalVars.layout.navbar.list(res.data?.allNavBarList);
                GlobalVars.layout.recommendationFindWord(res.data?.recommendationFindWord);
                I18nUtil.initI18n(I18nUtil.parseWithI18nContents(res.data?.i18nContents || []));
            })
            .catch(ApolloCatch({}));
    }, [getData, mutGetAdmin]);

    return <></>;
};

const gqlInitData = gql`
    query Init {
        allNavBarList {
            id
            order
            label
            keyWord
            parentId
        }
        i18nContents {
            id
            mainKey
            subKey
            contents
        }
        recommendationFindWord
    }
`;

const gqlAdmin = gql`
    mutation admin {
        getAdmin {
            id
        }
    }
`;
