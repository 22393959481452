import { makeVar } from "@apollo/client";
import { NavBar } from "../graphql/graphql";
import { LocalStorage } from "../services/localstorage/LocalStorage";

export enum ViewMode {
    Web,
    Mobile,
}

export const GlobalVars = {
    window: {
        scrollY: makeVar(0),
        width: makeVar(0),
        height: makeVar(0),
        viewMode: makeVar(ViewMode.Web),
    },
    layout: {
        header: {
            isTransparent: makeVar(false),
        },
        navbar: {
            list: makeVar<NavBar[]>([]),
        },
        footer: makeVar(true),
        loadingView: makeVar(false),
        recommendationFindWord: makeVar<string[]>([]),
    },
    i18n: {
        langCode: makeVar(LocalStorage.getLangCode()),
        langCodeList: makeVar<string[]>(["en", "ko"]),
    },
    user: {
        isLogin: makeVar(false),
        id: makeVar(""),
    },
};

export const UserVarUtils = {
    login: (id: string) => {
        GlobalVars.user.isLogin(true);
        GlobalVars.user.id(id);
    },
    logout: () => {
        GlobalVars.user.isLogin(false);
        LocalStorage.clearSession();
    },
};

export const LayoutVarUtils = {
    resizeWidth: (width: number) => {
        if (width < 998) {
            GlobalVars.window.viewMode(ViewMode.Mobile);
        } else {
            GlobalVars.window.viewMode(ViewMode.Web);
        }
        GlobalVars.window.width(width);
    },
    resizeHeight: (height: number) => {
        GlobalVars.window.height(height);
    },
};
