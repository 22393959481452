import React, {ReactNode, useEffect, useRef, useState} from "react";
import styles from "./IndexPageFloatingButton.module.scss";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../globalVars/globalVars";
import {Spring, animated} from "react-spring";
import * as Scroll from "react-scroll";

export const IndexPageFloatingButton = (props: { children: ReactNode }) => {
    const [pageList, setPageList] = useState<PageData[]>([]);
    const [activatedIdx, setActivatedIdx] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [prevScrollY, setPrevScrollY] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isMoving, setIsMoving] = useState(false);
    const windowHeight = useReactiveVar(GlobalVars.window.height);
    const scrollY = useReactiveVar(GlobalVars.window.scrollY);

    const animatedParams = {
        activated: {
            color: "rgba(9,63,147,1)",
            width: 30,
        },
        deactivated: {
            color: "rgba(200,200,200,1)",
            width: 20,
        },
    };

    useEffect(() => {
        const elemList = document.getElementsByClassName("page");
        const nextPageList: PageData[] = [];

        for (let i = 0; i < elemList.length; i++) {
            const elem = elemList.item(i);
            if (elem) {
                nextPageList.push({
                    y: (elem as any).offsetTop,
                });
            }
        }
        setPageList(nextPageList);
    }, [windowHeight]);

    useEffect(() => {
        let idx = 0;
        for (let i = 0; i < pageList.length; i++) {
            const {y} = pageList[i];

            if (y - 100 <= scrollY) {
                idx = i;
            } else {
                break;
            }
        }

        setActivatedIdx(idx);
        setPrevScrollY(scrollY);
    }, [scrollY, pageList]);

    const handler = {
        onClick: (scrollY: number) => {
            Scroll.animateScroll.scrollTo(scrollY, {
                duration: 100,
                delay: 0,
                smooth: "easeInOutQuint",
            });
        },
    };

    return (
        <>
            <div className={styles.cont}>
                {pageList.map((value, index) => (
                    <Spring
                        key={index}
                        from={animatedParams.deactivated}
                        to={
                            index === activatedIdx
                                ? animatedParams.activated
                                : animatedParams.deactivated
                        }>
                        {params => (
                            <div key={index} className={styles.box}>
                                <animated.div
                                    style={{width: params.width, borderColor: params.color}}
                                    className={styles.bar}
                                    onClick={() => handler.onClick(value.y)}
                                />
                            </div>
                        )}
                    </Spring>
                ))}
            </div>

                {props.children}
        </>
    );
};

interface PageData {
    y: number;
}
