import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";
import "./styles/common.scss";
import { GlobalVars, LayoutVarUtils } from "./globalVars/globalVars";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Apollo } from "./services/apollo/apollo";
import PerfectScrollBar from "react-perfect-scrollbar";

window.addEventListener("scroll", () => {
    GlobalVars.window.scrollY(window.scrollY);
});

window.addEventListener("resize", () => {
    LayoutVarUtils.resizeWidth(window.innerWidth);
    LayoutVarUtils.resizeHeight(window.innerHeight);
});

LayoutVarUtils.resizeWidth(window.innerWidth);
GlobalVars.window.scrollY(window.scrollY);

ReactDOM.render(
    <PerfectScrollBar>
        <BrowserRouter>
            <ApolloProvider client={Apollo.client}>
                <App />
            </ApolloProvider>
        </BrowserRouter>
    </PerfectScrollBar>,

    document.getElementById("root"),
);
