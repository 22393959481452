import React, { useEffect, useState } from "react";
import styles from "./NewsTabBar.module.scss";
import { NewsType } from "../../../graphql/graphql";
import classNames from "classnames";
import { I18nValue } from "../../../components/system/i18nValue/I18nValue";
import { NewsTabData } from "../NewsPage";
import { MultiLang, OnChangeValue } from "../../../graphql/types";

export const NewsTabBar = (props: {
    value: NewsType;
    onChangeValue: OnChangeValue<NewsType>;
    data: NewsTabData[];
}) => {
    const [boxRef, setBoxRef] = useState<HTMLDivElement | null>();
    const [btnWidth, setBtnWidth] = useState(0);
    const [label, setLabel] = useState<MultiLang>({});

    useEffect(() => {
        if (boxRef) {
            setBtnWidth(boxRef.offsetWidth / props.data.length);
        }
    }, [boxRef, props.data.length]);

    useEffect(() => {
        const value = props.data.find(value => value.newsType === props.value);
        if (value) {
            setLabel(value.label);
        }
    }, [props.data, props.value]);

    return (
        <>
            <div className={classNames(styles.cont, styles.borderBottom)}>
                <div className={styles.boxBtn} ref={setBoxRef}>
                    {props.data.map((value, index) => (
                        <div
                            onClick={() => props.onChangeValue(value.newsType)}
                            className={classNames(
                                styles.btn,
                                props.value === value.newsType ? styles.bar : "",
                            )}
                            style={{ width: btnWidth }}
                            key={index}>
                            <I18nValue value={value.label} />
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.label}>
                <I18nValue value={label} />
            </div>
        </>
    );
};

