import React, {useState} from "react";
import styles from "./Footer.module.scss";
import classNamesBind from "classnames/bind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../../globalVars/globalVars";

const cx = classNamesBind.bind(styles);

export const Footer = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    const [hide, setHide] = useState(false);

    let textList = [];
    if (langCode === "en") {
        textList = [
            {
                title: "CEO",
                content: "Kyung-won Chong",
            },
            {
                title: "Company Registration Number",
                content: "107-87-73969",
            },
            {
                title: "Phone number",
                content: "070-4352-7333",
            },
            {
                title: "FAX",
                content: "02-6499-4123",
            },
            {
                title: "Address",
                content:
                    "Room 1331, Manhattan Building, 33, Gukjegeumyung-ro 6-gil, Yeongdeungpo-gu, Seoul",
            },
        ];
    } else {
        textList = [
            {
                title: "대표이사",
                content: "정경원",
            },
            {
                title: "사업자등록번호",
                content: "107-87-73969",
            },
            {
                title: "대표번호",
                content: "070-4352-7333",
            },
            {
                title: "FAX",
                content: "02-6499-4123",
            },
            {
                title: "주소",
                content: "서울시 영등포구 국제금융로6길 33 (여의도동, 맨하탄빌딩 1331호)",
            },
        ];
    }
    return (
        <>
            <div className={cx("footer")}>
                <div className={cx("footerBox")}>
                    <div className={cx("column-left")}>
                        {textList.map((value, index) => (
                            <div className={cx("row")} key={index}>
                                <div className={cx("title")}>{value.title}</div>
                                <div className={cx("content")}>{value.content}</div>
                            </div>
                        ))}
                    </div>
                    <div className={cx("column-right")}>
                        <div className={cx("icon")} onMouseEnter={() => setHide(true)}
                             onMouseLeave={() => setHide(false)}>
                            <a href={"https://blog.naver.com/escare1"} target={"_blank"}>
                                {!hide &&
                                    <img className={cx("blog")} src="/assets/images/index/blog_icon.png"
                                         alt={"Naver Blog Icon"}/>
                                }
                                {hide &&
                                    <img className={cx("blog")} src="/assets/images/index/blog_icon_hover.png"
                                         alt={"Naver Blog Icon"}/>}
                            </a>
                        </div>
                        <div className={cx("icon")}>
                            <a href={"https://www.linkedin.com/company/escare"} target={"_blank"}>
                                <FontAwesomeIcon icon={faLinkedin}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={cx("row", "copyright")}>
                    <div className={cx("content")}>Copyright © Escare. All rights reserved.</div>
                </div>
            </div>
        </>
    );
};
