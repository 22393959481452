import React from "react";
import { MultiLang, OnChangeValue } from "../../../graphql/types";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../globalVars/globalVars";
import { CopyUtils } from "../../../services/copyUtils/copyUtils";
import styles from "./InputMultiLang.module.scss";

export const InputMultiLang = (props: {
    value: MultiLang;
    onChangeValue: OnChangeValue<MultiLang>;
}) => {
    const langCodeList = useReactiveVar(GlobalVars.i18n.langCodeList);

    const handler = {
        onChangeValue: (langCode: string, value: string) => {
            const copy = CopyUtils.copyAll(props.value);
            copy[langCode] = value;
            props.onChangeValue(copy);
        },
    };

    return (
        <>
            {langCodeList.map((langCode, index) => (
                <div className={styles.cont} key={index}>
                    <div className={styles.colLabel}>{langCode}</div>
                    <div className={styles.colValue}>
                        <input
                            value={
                                props.value.hasOwnProperty(langCode) ? props.value[langCode] : ""
                            }
                            onChange={ev => handler.onChangeValue(langCode, ev.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
            ))}
        </>
    );
};
