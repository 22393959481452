import React, { ReactNode } from "react";
import styles from "./ImgPageHeader.module.scss";

export const ImgPageHeader = (props: { background: ReactNode; children?: ReactNode }) => {
    return (
        <div className={styles.cont}>
            <div className={styles.img}>{props.background}</div>
            <div className={styles.boxInfo}>{props.children}</div>
        </div>
    );
};
