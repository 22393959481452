import React from "react";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../globalVars/globalVars";
import styles from "./CustomersPage.module.scss";

export const CustomersPage = () => {
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    let textList;

    if (langCode === "en") {
        textList = {
            title: "ESCARE CUSTOMERS",
            description: "",
            finance: "Finance",
            enterprise: "Enterprise",
        };
    } else {
        textList = {
            title: "에스케어 고객사",
            description:
                "에스케어는 국내외를 포함한 총 300여개 고객사 업무 환경을 안전하게 보호하고 지켜드리고 있습니다.",
            finance: "금융",
            enterprise: "기업",
        };
    }
    return (
        <>
            <div className={styles.logoLine}>
                <div className={styles.headerText}>{textList.title}</div>
            </div>
            <div className={styles.description}>{textList.description}</div>
            <div className={styles.financeContainer}>
                <div className={styles.Container}>
                    <div className={styles.title}>{textList.finance}</div>
                    <div className={styles.itemList}>
                        {financeItemList.map((value, index) => (
                            <div key={index} className={styles.item}>
                                <img src={value} alt={value}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.companyContainer}>
                <div className={styles.Container}>
                    <div className={styles.title}>{textList.enterprise}</div>
                    <div className={styles.itemList}>
                        {enterpriseItemList.map((value, index) => (
                            <div key={index} className={styles.item}>
                                <img src={value} alt={value}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const financeItemList = [
    "/assets/images/logo/kbank.png",
    "/assets/images/logo/kakaobank.png",
    "/assets/images/logo/kdbindustrybank.png",
    "/assets/images/logo/hyundaicapital.png",
    "/assets/images/logo/hyundaicard.png",
    "/assets/images/logo/mortagagepartners.png",
    "/assets/images/logo/renaultcapital.png",
    "/assets/images/logo/miraeasset.png",
    "/assets/images/logo/bithumb.png",
    "/assets/images/logo/prudential.png",
    "/assets/images/logo/uribank.png",
    "/assets/images/logo/ibksecurities.png",
    "/assets/images/logo/eujinsecurities.png",
    "/assets/images/logo/ktbsecurites.png",
    "/assets/images/logo/exportimportbank.png",
    "/assets/images/logo/uricard.png",
    "/assets/images/logo/deutschfinancial.png",
    "/assets/images/logo/kcp.png",
    "/assets/images/logo/kbsecurities.png",
    "/assets/images/logo/kbinsurance.png",
    "/assets/images/logo/axainsurance.png",
    "/assets/images/logo/toss.png",
    "/assets/images/logo/banksalad.png",
    "/assets/images/logo/linainsurance.png",
    "/assets/images/logo/chinaconstructionbank.png",
    "/assets/images/logo/bankofchina.png",
    "/assets/images/logo/standardchartered.png",
    "/assets/images/logo/kgmobilians.png",
    "/assets/images/logo/kginicis.png",
    "/assets/images/logo/abl.png",
    "/assets/images/logo/settlebank.png",
    "/assets/images/logo/daishinsecurities.png",
    "/assets/images/logo/meritzsecurities.png",
    "/assets/images/logo/koreacreditdata.png",
    "/assets/images/logo/dongyanginsurance.png",
];

const enterpriseItemList = [
    "/assets/images/logo/amorepacific.png",
    "/assets/images/logo/hyosung.png",
    "/assets/images/logo/samsung.png",
    "/assets/images/logo/chongkundangholdings.png",
    "/assets/images/logo/samhwapaint.png",
    "/assets/images/logo/lguplus.png",
    "/assets/images/logo/lgbio.png",
    "/assets/images/logo/hyundai.png",
    "/assets/images/logo/koreaelectricalcontractorsassociation.png",
    "/assets/images/logo/hanon.png",
    "/assets/images/logo/tmon.png",
    "/assets/images/logo/11st.png",
    "/assets/images/logo/celltrion.png",
    "/assets/images/logo/shinjinsm.png",
    "/assets/images/logo/10minds.png",
    "/assets/images/logo/gyungborestriction.png",
    "/assets/images/logo/daehanfeed.png",
    "/assets/images/logo/ontinental.png",
    "/assets/images/logo/daeduck.png",
    "/assets/images/logo/daoutechnology.png",
    "/assets/images/logo/crucialtec.png",
    "/assets/images/logo/hyundaielevator.png",
    "/assets/images/logo/ground.png",
    "/assets/images/logo/jobkorea.png",
    "/assets/images/logo/kcp.png",
    "/assets/images/logo/samoo.png",
    "/assets/images/logo/saehan.png",
    "/assets/images/logo/settlebank.png",
    "/assets/images/logo/mandobrose.png",
    "/assets/images/logo/ktcommerce.png",
    "/assets/images/logo/skconstruction.png",
    "/assets/images/logo/skhynix.png",
    "/assets/images/logo/skplanet.png",
    "/assets/images/logo/skshildus.png",
    "/assets/images/logo/coupang.png",
    "/assets/images/logo/hwau.png",
    "/assets/images/logo/samsunghospital.png",
    "/assets/images/logo/mortagagepartners.png",
    "/assets/images/logo/ktsat.png",
    "/assets/images/logo/carverkorea.png",
    "/assets/images/logo/celltrionrestriction.png",
    "/assets/images/logo/busanhospital.png",
    "/assets/images/logo/publicshopping.png",
    "/assets/images/logo/kddi.png",
    "/assets/images/logo/sejong.png",
    "/assets/images/logo/samsungservice.png",
    "/assets/images/logo/korearailroadauthority.png",
    "/assets/images/logo/jejushinhwaworld.png",
    "/assets/images/logo/gscaltex.png",
    "/assets/images/logo/mitsubishielectric.png",
    "/assets/images/logo/kpmg.png",
    "/assets/images/logo/geneone.png",
    "/assets/images/logo/mediawillnetworks.png",
    "/assets/images/logo/hanatourbusiness.png",
    "/assets/images/logo/progen.png",
    "/assets/images/logo/koreainstituteofregisteredarchitects.png",
    "/assets/images/logo/visang.png",
    "/assets/images/logo/topas.png",
];
