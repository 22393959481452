import React, { useState } from "react";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
    InputIndexCarousel,
    InputIndexInfoBar,
    LinkType,
    Mutation,
    MutationAppendIndexCarouselArgs,
    MutationCreateIndexInfoBarArgs,
    MutationDeleteIndexInfoBarArgs,
    MutationRemoveIndexCarouselArgs,
    MutationUpdateIndexCarouselArgs,
    MutationUpdateIndexInfoBarArgs,
    MutationUpdateRecommendationFindWordArgs,
    Query,
} from "../../../graphql/graphql";
import { IndexCarouselList } from "./carousel/indexCarouselList/IndexCarouselList";
import { TableBtnView } from "../../../components/table/btn/TableBtnView";
import { DefaultModal } from "../../../components/modal/default/DefaultModal";
import { IndexCarouselCreateModal } from "./carousel/indexCarouselCreateModal/IndexCarouselCreateModal";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { BizValidator } from "../../../services/validator/BizValidator";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";
import { IndexCarouselUpdateModal } from "./carousel/indexCarouselUpdateModal/IndexCarouselUpdateModal";
import { DashboardMainInputFindWord } from "./inputFindWord/DashboardMainInputFindWord";
import { IndexInfoBarList } from "./infoBar/indexInfoBarList/IndexInfoBarList";
import { IndexInfoBarCreateModal } from "./infoBar/indexInfoBarCreateModal/IndexInfoBarCreateModal";
import {IndexInfoBarUpdateModal} from "./infoBar/indexInfoBarUpdateModal/IndexInfoBarUpdateModal";

export const DashboardMainPage = () => {
    const { data, refetch } = useQuery<Query>(gqlData);

    const [createIndexCarousel, setCreateIndexCarousel] = useState<
        InputIndexCarousel | undefined
    >();
    const [updateIndexCarousel, setUpdateIndexCarousel] = useState<
        InputUpdateIndexCarousel | undefined
    >();
    const [createIndexInfoBar, setCreateIndexInfoBar] = useState<InputIndexInfoBar>();
    const [updateIndexInfoBar, setUpdateIndexInfoBar] = useState<InputUpdateIndexInfoBar>();

    const [mutAppendIndexCarousel] = useMutation<Mutation, MutationAppendIndexCarouselArgs>(
        gqlAppendIndexCarousel,
    );
    const [mutUpdateIndexCarousel] = useMutation<Mutation, MutationUpdateIndexCarouselArgs>(
        gqlUpdateIndexCarousel,
    );
    const [mutRemoveIndexCarousel] = useMutation<Mutation, MutationRemoveIndexCarouselArgs>(
        gqlRemoveIndexCarousel,
    );
    const [mutCreateIndexInfoBar] = useMutation<Mutation, MutationCreateIndexInfoBarArgs>(
        gqlCreateIndexInfoBar,
    );
    const [mutUpdateIndexInfoBar] = useMutation<Mutation, MutationUpdateIndexInfoBarArgs>(
        gqlUpdateIndexInfoBar,
    );
    const [mutDeleteIndexInfoBar] = useMutation<Mutation, MutationDeleteIndexInfoBarArgs>(
        gqlDeleteIndexInfoBar,
    );
    const [mutUpdateRecommendationFindWord] = useMutation<
        Mutation,
        MutationUpdateRecommendationFindWordArgs
    >(gqlUpdateRecommendationFindWord);

    const handler = {
        onOpenCreateIndexCarousel: () => {
            setCreateIndexCarousel({
                description: {},
                preHeader: {},
                header: {},
                imgSrc: "",
                link: "https://",
                order: 0,
            });
        },
        onAppendIndexCarousel: (value: InputIndexCarousel) => {
            if (!handler.validateInputIndexCarousel(value)) {
                return;
            }

            mutAppendIndexCarousel({
                variables: { input: value },
            })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "추가 되었습니다.",
                        icon: "success",
                    });
                    setCreateIndexCarousel(undefined);
                })
                .catch(ApolloCatch({}));
        },
        onUpdateIndexCarousel: (value: InputUpdateIndexCarousel) => {
            if (!handler.validateInputIndexCarousel(value.input)) {
                return;
            }

            SwalUtils.ynWithPromise({
                msg: "수정 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdateIndexCarousel({
                        variables: {
                            id: value.id,
                            input: {
                                description: value.input.description,
                                header: value.input.header,
                                imgSrc: value.input.imgSrc,
                                link: value.input.link,
                                preHeader: value.input.preHeader,
                                order: value.input.order,
                            },
                        },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "수정 되었습니다.",
                        icon: "success",
                    });
                    setUpdateIndexCarousel(undefined);
                    return;
                })
                .catch(ApolloCatch({}));
        },
        onClickUpdateIndexCarousel: (value: InputUpdateIndexCarousel) => {
            setUpdateIndexCarousel(value);
        },
        onClickRemoveIndexCarousel: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return mutRemoveIndexCarousel({
                        variables: { id },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        validateInputIndexCarousel: (value: InputIndexCarousel): boolean => {
            if (!value.imgSrc) {
                SwalUtils.ok({
                    msg: "이미지를 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.preHeader)) {
                SwalUtils.ok({
                    msg: "헤더1 내용을 모두 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.header)) {
                SwalUtils.ok({
                    msg: "헤더2 내용을 모두 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.description)) {
                SwalUtils.ok({
                    msg: "설명 내용을 모두 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            return true;
        },
        onOpenCreateIndexInfoBar: () => {
            setCreateIndexInfoBar({
                bgUrl: "",
                iconUrl: "",
                isActivate: true,
                label: {},
                link: "",
                linkType: LinkType.None,
                order: 0,
                title: {},
            });
        },
        OnOpenUpdateIndexInfoBar: (value: InputUpdateIndexInfoBar) => {
            setUpdateIndexInfoBar(value);
        },
        onAppendIndexInfoBar: (value: InputIndexInfoBar) => {
            if (!handler.validateInputIndexInfoBar(value)) {
                return;
            }

            mutCreateIndexInfoBar({
                variables: { input: value },
            })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "추가 되었습니다.",
                        icon: "success",
                    });
                    setCreateIndexInfoBar(undefined);
                })
                .catch(ApolloCatch({}));
        },
        onUpdateIndexInfoBar: (value: InputUpdateIndexInfoBar) => {
            if (!handler.validateInputIndexInfoBar(value.input)) {
                return;
            }

            SwalUtils.ynWithPromise({
                msg: "수정 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdateIndexInfoBar({
                        variables: {
                            id: value.id,
                            input: {
                                bgUrl: value.input.bgUrl,
                                iconUrl: value.input.iconUrl,
                                isActivate: value.input.isActivate,
                                label: value.input.label,
                                link: value.input.link,
                                linkType: value.input.linkType,
                                order: value.input.order,
                                title: value.input.title,
                            },
                        },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "수정 되었습니다.",
                        icon: "success",
                    });
                    setUpdateIndexInfoBar(undefined);
                    return;
                })
                .catch(ApolloCatch({}));
        },
        onClickDeleteIndexInfoBar: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return mutDeleteIndexInfoBar({
                        variables: { id },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        validateInputIndexInfoBar: (value: InputIndexInfoBar): boolean => {
            if (!value.bgUrl) {
                SwalUtils.ok({
                    msg: "배경 이미지를 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!value.iconUrl) {
                SwalUtils.ok({
                    msg: "아이콘 이미지를 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.title)) {
                SwalUtils.ok({
                    msg: "타이틀 내용을 모두 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            if (!BizValidator.checkMultiLang(value.label)) {
                SwalUtils.ok({
                    msg: "헤더2 내용을 모두 입력하여 주십시오.",
                    icon: "error",
                });
                return false;
            }

            return true;
        },
        onUpdateFindWord: (value: string[]) => {
            SwalUtils.ynWithPromise({
                msg: "수정 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdateRecommendationFindWord({
                        variables: {
                            words: value,
                        },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "수정 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <DashboardLayout router="/dashboard/main">
            {data && (
                <>
                    <h4>메인페이지 캐러셀 섹션</h4>
                    <IndexCarouselList {...handler} data={data.indexCarousel} />

                    <TableBtnView>
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={handler.onOpenCreateIndexCarousel}>
                            추가
                        </button>
                    </TableBtnView>

                    <div style={{ height: 50 }} />

                    <h4>메인페이지 섹션2</h4>
                    <h6>메인페이지에는 활성화된 아이템 중 상위 3개만 출력됩니다.</h6>
                    <IndexInfoBarList
                        data={data.allIndexInfoBar}
                        OnOpenUpdateIndexInfoBar={handler.OnOpenUpdateIndexInfoBar}
                        onClickDeleteIndexInfoBar={handler.onClickDeleteIndexInfoBar}
                    />
                    <TableBtnView>
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={handler.onOpenCreateIndexInfoBar}>
                            추가
                        </button>
                    </TableBtnView>

                    <div style={{ height: 50 }} />

                    <DashboardMainInputFindWord
                        value={data.recommendationFindWord}
                        onUpdateFindWord={handler.onUpdateFindWord}
                    />
                </>
            )}

            <DefaultModal
                title={"메인 케러셀 아이템 추가"}
                onClose={() => setCreateIndexCarousel(undefined)}
                open={Boolean(createIndexCarousel)}>
                {createIndexCarousel && (
                    <IndexCarouselCreateModal
                        value={createIndexCarousel}
                        onChangeValue={setCreateIndexCarousel}
                        onAppendIndexCarousel={handler.onAppendIndexCarousel}
                    />
                )}
            </DefaultModal>

            <DefaultModal
                title={"메인 케러셀 아이템 수정"}
                onClose={() => setUpdateIndexCarousel(undefined)}
                open={Boolean(updateIndexCarousel)}>
                {updateIndexCarousel && (
                    <IndexCarouselUpdateModal
                        value={updateIndexCarousel}
                        onChangeValue={setUpdateIndexCarousel}
                        onUpdateIndexCarousel={handler.onUpdateIndexCarousel}
                    />
                )}
            </DefaultModal>

            <DefaultModal
                title={"메인 섹션2 아이템 추가"}
                onClose={() => setCreateIndexInfoBar(undefined)}
                open={Boolean(createIndexInfoBar)}>
                {createIndexInfoBar && (
                    <IndexInfoBarCreateModal
                        value={createIndexInfoBar}
                        onChangeValue={setCreateIndexInfoBar}
                        onAppendIndexInfoBar={handler.onAppendIndexInfoBar}
                    />
                )}
            </DefaultModal>

            <DefaultModal
                title={"메인 섹션2 아이템 수정"}
                onClose={() => setUpdateIndexInfoBar(undefined)}
                open={Boolean(updateIndexInfoBar)}>
                {updateIndexInfoBar && (
                    <IndexInfoBarUpdateModal
                        value={updateIndexInfoBar}
                        onChangeValue={setUpdateIndexInfoBar}
                        onUpdateIndexInfoBar={handler.onUpdateIndexInfoBar}
                    />
                )}
            </DefaultModal>
        </DashboardLayout>
    );
};

export type InputUpdateIndexCarousel = { id: string; input: InputIndexCarousel };
export type InputUpdateIndexInfoBar = { id: string; input: InputIndexInfoBar };

const gqlData = gql`
    query Main {
        indexCarousel {
            id
            order
            link
            header
            preHeader
            description
            imgSrc
        }
        recommendationFindWord
        allIndexInfoBar {
            bgUrl
            createdAt
            iconUrl
            id
            isActivate
            label
            link
            linkType
            order
            title
        }
    }
`;

const gqlAppendIndexCarousel = gql`
    mutation AppendIndexCarousel($input: InputIndexCarousel!) {
        appendIndexCarousel(input: $input)
    }
`;

const gqlUpdateIndexCarousel = gql`
    mutation UpdateIndexCarousel($id: String!, $input: InputIndexCarousel!) {
        updateIndexCarousel(id: $id, input: $input)
    }
`;

const gqlRemoveIndexCarousel = gql`
    mutation RemoveIndexCarousel($id: String!) {
        removeIndexCarousel(id: $id)
    }
`;

const gqlUpdateRecommendationFindWord = gql`
    mutation UpdateRecommendationFindWord($words: [String!]!) {
        updateRecommendationFindWord(words: $words)
    }
`;

const gqlCreateIndexInfoBar = gql`
    mutation CreateIndexInfoBar($input: InputIndexInfoBar!) {
        createIndexInfoBar(input: $input)
    }
`;

const gqlUpdateIndexInfoBar = gql`
    mutation UpdateIndexInfoBar($id: String!, $input: InputIndexInfoBar!) {
        updateIndexInfoBar(id: $id, input: $input)
    }
`;

const gqlDeleteIndexInfoBar = gql`
    mutation DeleteIndexInfoBar($id: String!) {
        deleteIndexInfoBar(id: $id)
    }
`;
