import React from "react";
import styles from "./ProductDetailDescContent.module.scss";
import { useReactiveVar } from "@apollo/client";
import { GlobalVars } from "../../../../globalVars/globalVars";
import { ContentUnit } from "../../../../graphql/types";
import { I18nUtil } from "../../../../services/i18n/i18n";

export const ProductDetailDescContent = (props: { value: ContentUnit }) => {
    const value = props.value;
    const langCode = useReactiveVar(GlobalVars.i18n.langCode);

    return (
        <>
            <div className={styles.cont}>
                <div className={styles.boxInfo}>
                    <div
                        ref={ref => {
                            if (ref) {
                                ref.innerHTML = I18nUtil.value(langCode, value.title);
                            }
                        }}
                        className={styles.header}></div>
                    <div
                        ref={ref => {
                            if (ref) {
                                ref.innerHTML = I18nUtil.value(langCode, value.content);
                            }
                        }}
                        className={styles.description}></div>
                </div>
            </div>
        </>
    );
};
