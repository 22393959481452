import React from "react";
import styles from "./NotFoundPage.module.scss";

export const NotFoundPage = () => {
    return (
        <div className={styles.cont}>
            <h1>Not found page</h1>
        </div>
    );
};
