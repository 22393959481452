import { NewsType } from "../../graphql/graphql";

export class Pipe {
    static toNewsType(value?: string): NewsType {
        switch (value) {
            case "ESCARE":
                return NewsType.Escare;
            case "PRODUCT":
                return NewsType.Product;
            case "LETTER":
                return NewsType.Letter;
            default:
                return NewsType.Any;
        }
    }
}
