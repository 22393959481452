import React from "react";
import { ProductDetailHeader } from "./header/ProductDetailHeader";
import { ProductDetailDescription } from "./description/ProductDetailDescription";
import { ProductDetailSector } from "./sector/ProductDetailSector";
import { ProductDetailReference } from "./reference/ProductDetailReference";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Query, QueryProductArgs } from "../../../graphql/graphql";
import { NotFoundPage } from "../../notFound/NotFoundPage";
import { ProductDetailDescContent } from "./descContent/ProductDetailDescContent";

export const ProductDetailPage = () => {
    const { id } = useParams();
    const { data } = useQuery<Query, QueryProductArgs>(gqlProduct, {
        variables: {
            id: id || "",
        },
    });

    return (
        <>
            {data && (
                <>
                    <ProductDetailHeader value={data.product} />
                    {data.productOrder.part1 && (
                        <ProductDetailDescription value={data.product.mainContent} />
                    )}

                    {data.productOrder.part2 && <ProductDetailSector value={data.product} />}

                    {data.productOrder.part3 && data.product.subContent && (
                        <ProductDetailDescription value={data.product.subContent} />
                    )}

                    {data.productOrder.part4 && data.product.descContent && (
                        <>
                            <ProductDetailDescContent value={data.product.descContent} />
                        </>
                    )}

                    {data.productOrder.part5 && <ProductDetailReference value={data.product} />}
                </>
            )}

            {!data && <NotFoundPage />}
        </>
    );
};

const gqlProduct = gql`
    query Product($id: String!) {
        product(id: $id) {
            id
            backgroundSrc
            brochureLink
            header
            imposeEffect
            isActivate
            mainContent
            referenceLogo
            subContent
            descContent
            subHeader
            homepage
            createdAt
            updatedAt
        }
        productOrder(id: $id) {
            part1
            part2
            part3
            part4
            part5
        }
    }
`;
