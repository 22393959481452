import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DashboardLayout } from "../../../components/dashboardLayout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
    Mutation,
    MutationRemoveProductArgs,
    MutationUpdateProductActivationArgs,
    Query,
    QueryProductWithFindWordForAdminArgs,
} from "../../../graphql/graphql";
import { Pager } from "../../../components/pagination/Pager";
import { ProductManageProductList } from "./list/ProductManageProductList";
import { SwalUtils } from "../../../services/swal/SwalUtils";
import { ApolloCatch } from "../../../services/apollo/apolloCatch";

export const DashboardManageProductPage = () => {
    const { page, size, findWord } = useParams();
    const { data, refetch } = useQuery<Query, QueryProductWithFindWordForAdminArgs>(gqlData, {
        variables: {
            page: Number(page || 0),
            size: Number(size || 20),
            findWord: findWord || "",
        },
    });

    const [mutRemove] = useMutation<Mutation, MutationRemoveProductArgs>(gqlRemove);
    const [mutUpdateActivate] = useMutation<Mutation, MutationUpdateProductActivationArgs>(
        gqlUpdateActivation,
    );

    const navigate = useNavigate();
    const res = data?.productWithFindWordForAdmin;
    const handler = {
        onChangePage: (p: number) => {
            if (!res) {
                return;
            }

            if (findWord) {
                navigate(`/dashboard/product/manage/${p}/${size || 20}/${findWord || ""}`);
            } else {
                navigate(`/dashboard/product/manage/${p}/${size || 20}`);
            }
        },
        onClickRemove: (id: string) => {
            SwalUtils.ynWithPromise({
                msg: "삭제 하시겠습니까?",
            })
                .then(() => {
                    return mutRemove({
                        variables: {
                            id,
                        },
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
        onClickProductCheckBox: (variables: MutationUpdateProductActivationArgs) => {
            SwalUtils.ynWithPromise({
                msg: variables.isActivate ? "활성화 하시겠습니까?" : "비활성화 하시겠습니까?",
            })
                .then(() => {
                    return mutUpdateActivate({
                        variables,
                    });
                })
                .then(() => {
                    return refetch();
                })
                .then(() => {
                    SwalUtils.ok({
                        msg: "변경 되었습니다.",
                        icon: "success",
                    });
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <DashboardLayout router="/dashboard/product/manage">
            {res && (
                <>
                    <h4>제품 관리</h4>

                    <ProductManageProductList
                        onClickProductCheckBox={handler.onClickProductCheckBox}
                        onClickRemove={handler.onClickRemove}
                        value={res.list}
                    />

                    <Pager
                        page={res.page}
                        size={res.size}
                        total={res.total}
                        onChange={handler.onChangePage}
                    />
                </>
            )}
        </DashboardLayout>
    );
};

const gqlRemove = gql`
    mutation Remove($id: String!) {
        removeProduct(id: $id)
    }
`;

const gqlData = gql`
    query DATA($findWord: String!, $page: Int!, $size: Int!) {
        productWithFindWordForAdmin(findWord: $findWord, page: $page, size: $size) {
            page
            size
            total
            list {
                id
                isActivate
                header
                subHeader
                backgroundSrc
                brochureLink
                imposeEffect
                mainContent
                referenceLogo
                descContent
                createdAt
                updatedAt
            }
        }
    }
`;

const gqlUpdateActivation = gql`
    mutation Update($id: String!, $isActivate: Boolean!) {
        updateProductActivation(id: $id, isActivate: $isActivate)
    }
`;
