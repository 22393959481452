import React, { ReactNode } from "react";
import styles from "./ActiveProductView.module.scss";
import { InputProductOrder } from "../../../graphql/graphql";
import { OnChangeValue } from "../../../graphql/types";
import { Switch } from "@material-ui/core";

export const ActiveProductView = (props: {
    orderValue: InputProductOrder;
    onChange: OnChangeValue<InputProductOrder>;
}) => {
    return (
        <>
            <div>
                <TableTitle>섹션별 활성화</TableTitle>
                <Table orderValue={props.orderValue} onChange={props.onChange} />
            </div>
        </>
    );
};

const TableTitle = (props: { children: ReactNode }) => {
    return (
        <>
            <h5>{props.children}</h5>
        </>
    );
};

const Table = (props: {
    orderValue: InputProductOrder;
    onChange: OnChangeValue<InputProductOrder>;
}) => {
    return (
        <>
            <div className={styles.table}>
                <div className={styles.header}>
                    <div className={styles.cell}>제품설명</div>
                    <div className={styles.cell}>도입효과</div>
                    <div className={styles.cell}>제품설명2</div>
                    <div className={styles.cell}>기술</div>
                    <div className={styles.cell}>레퍼런스</div>
                </div>
                <div className={styles.column}>
                    <div className={styles.cell}>
                        <Switch
                            inputProps={{ "aria-label": "제품설명" }}
                            checked={props.orderValue.part1}
                            color="primary"
                            onClick={() => {
                                props.onChange({
                                    ...props.orderValue,
                                    part1: !props.orderValue.part1,
                                });
                            }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <Switch
                            inputProps={{ "aria-label": "도입효과" }}
                            checked={props.orderValue.part2}
                            color="primary"
                            onClick={() => {
                                props.onChange({
                                    ...props.orderValue,
                                    part2: !props.orderValue.part2,
                                });
                            }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <Switch
                        inputProps={{ "aria-label": "제품설명2" }}
                        checked={props.orderValue.part3}
                        color="primary"
                        onClick={() => {
                            props.onChange({
                                ...props.orderValue,
                                part3: !props.orderValue.part3,
                            });
                        }}
                    /></div>
                    <div className={styles.cell}>
                        <Switch
                            inputProps={{ "aria-label": "기술" }}
                            checked={props.orderValue.part4}
                            color="primary"
                            onClick={() => {
                                props.onChange({
                                    ...props.orderValue,
                                    part4: !props.orderValue.part4,
                                });
                            }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <Switch
                            inputProps={{ "aria-label": "레퍼런스" }}
                            checked={props.orderValue.part5}
                            color="primary"
                            onClick={() => {
                                props.onChange({
                                    ...props.orderValue,
                                    part5: !props.orderValue.part5,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
