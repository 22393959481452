import React, {Fragment} from "react";
import styles from "./NewsListView.module.scss";
import {News} from "../../../graphql/graphql";
import {NoDataTable} from "../../../components/table/none/NoDataTable";
import classNames from "classnames";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";

export const NewsListView = (props: { value: News[] }) => {
    const navigate = useNavigate();
    const {newsType} = useParams();

    const handler = {
        onClickArticle: (id: string) => {
            navigate(`/article/${id}`);
        },
    };

    return (
        <Fragment>
            {props.value.length === 0 && <NoDataTable noBorder/>}

            {props.value.map((value, index) => (
                <div
                    key={index}
                    className={classNames(
                        styles.borderTop,
                        styles.cont,
                        props.value.length - 1 === index ? styles.borderBottom : "",
                    )}
                    onClick={() => handler.onClickArticle(value.id)}>
                    <div className={styles.boxInfo}>
                        <div className={styles.title}>{value.title}</div>
                        {newsType !== "LETTER" && (
                            <>
                                <div className={styles.content}>{value.contents}</div>
                                <div className={styles.createdAt}>
                                    {moment(value.createdAt).format("YY.MM.DD")} | {value.newsLabel}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.boxImg}>
                        <img src={value.imgSrc} alt="img"/>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};
